import { useQuery } from '@tanstack/react-query'

import optimusClient from '~/clients/optimus-client'
import { queryKeys } from '~/hooks/useWebSocketUpdates/handleWebSocketUpdates'

import { handleApiRequest } from './shared/apiWrapper'

type Request = { blockScheduleId: number; updatedAt: string }

export const serializeKeysForCache = (request: Request) => [queryKeys.blockScheduleDuration, ...Object.values(request)]
export const useDurationQuery = ({ request, isEnabled }: { request: Request; isEnabled?: boolean }) =>
    useQuery({
        queryKey: serializeKeysForCache(request),
        queryFn: async () => {
            return handleApiRequest(() => optimusClient.getBlockScheduleDuration(request.blockScheduleId))
        },
        enabled: isEnabled,
    })
