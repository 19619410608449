import { Slot } from '@radix-ui/react-slot'
import clsx from 'clsx'
import debounce from 'lodash/debounce'
import { Dispatch, forwardRef, MouseEvent, ReactNode, SetStateAction, TdHTMLAttributes } from 'react'

import { Id, toCellId } from './utils'

export type BodyProps<Row extends { id: Id }, HeaderRow extends { id: Id }> = {
    rows: Row[]
    rowRender: (row: Row) => ReactNode
    rowClassName?: (row: Row) => string
    cellAction?: (row: Row, headerRow: HeaderRow) => void
    cellRender: (row: Row, headerRow: HeaderRow) => ReactNode
    cellClassName?: (row: Row, headerRow: HeaderRow) => string
    isKeyboardAccessible?: boolean
}

type Props<Row extends { id: Id }, Cell extends { id: Id }> = BodyProps<Row, Cell> & {
    headerRow: Cell[]
    selectable: boolean

    selectedCells: Set<string>
    setSelectedCells: Dispatch<SetStateAction<Set<string>>>
    showModal: () => void
    hideModal: () => void
}

export type TableCellProps = {
    dataCell?: string
    onMouseUp?: () => void
    onMouseDown?: (event: MouseEvent) => void
    onMouseMove?: (event: MouseEvent) => void
    className: string
    children: ReactNode
    asChild?: boolean
} & TdHTMLAttributes<HTMLTableCellElement>

export const TableCell = forwardRef<HTMLTableCellElement, TableCellProps>(
    ({ dataCell, onMouseUp, onMouseDown, onMouseMove, className, asChild, ...cellProps }, ref) => {
        const Comp = asChild ? Slot : 'td'
        return (
            <Comp
                data-cell={dataCell}
                onMouseUp={onMouseUp}
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                className={className}
                ref={ref}
                {...cellProps}
            />
        )
    }
)

export function Body<Row extends { id: Id }, Column extends { id: Id }>({
    selectable,
    rows,
    rowRender,
    rowClassName,
    headerRow,
    cellRender,
    cellClassName,
    setSelectedCells,
    showModal,
    hideModal,
    selectedCells,
    isKeyboardAccessible = true,
    cellAction,
}: Props<Row, Column>) {
    function handleCellMouseDown(cell: string) {
        if (!selectable) return

        return (event: MouseEvent) => {
            const isPresented = selectedCells.has(cell)
            const isCombinedSelectionKey = event.metaKey || event.ctrlKey

            setSelectedCells(prev => {
                const newSet = !isCombinedSelectionKey ? new Set<string>() : new Set<string>(prev)

                if (!isPresented) {
                    newSet.add(cell)
                } else if (isCombinedSelectionKey) {
                    newSet.delete(cell)
                }

                return newSet
            })

            hideModal()
        }
    }

    function handleMouseUp() {
        if (selectedCells.size > 0) {
            showModal()
        }
    }

    function handleCellMouseMove(cell: string) {
        if (!selectable) return

        return debounce((event: MouseEvent) => {
            const isMouseDown = event.buttons === 1
            const isPresented = selectedCells.has(cell)

            if (isMouseDown && !isPresented) {
                setSelectedCells(prev => {
                    const newSet = new Set<string>(prev)
                    newSet.add(cell)
                    return newSet
                })
            }
        }, 10)
    }

    const handleKeyboardInteraction = (e: React.KeyboardEvent<HTMLTableCellElement>, row: Row, cell: Column) => {
        const nextElement = e.currentTarget?.nextSibling as HTMLElement
        const previousElement = e.currentTarget?.previousSibling as HTMLElement
        const currentIndex = (e.currentTarget as HTMLTableCellElement).cellIndex
        if (e.key === 'ArrowRight') {
            nextElement?.focus()
        }
        if (e.key === 'ArrowLeft') {
            previousElement?.focus()
        }
        if (e.key === 'ArrowDown') {
            const nextRow = e.currentTarget?.parentElement?.nextSibling as HTMLElement
            const newCell = nextRow?.children[currentIndex] as HTMLElement
            newCell?.focus()
        }
        if (e.key === 'ArrowUp') {
            const previousRow = e.currentTarget?.parentElement?.previousSibling as HTMLElement
            const newCell = previousRow?.children[currentIndex] as HTMLElement
            newCell.focus()
        }
        if (e.key === 'Enter') {
            cellAction && cellAction(row, cell)
        }
    }

    return (
        <>
            {rows.map(row => (
                <tr key={row.id}>
                    <td
                        data-test={`${row.id}`}
                        className={clsx(rowClassName?.(row), 'sticky left-0 z-elevated border-b border-r border-strong-rest bg-fill-rest p-2')}
                    >
                        {rowRender(row)}
                    </td>

                    {headerRow.map(cell => {
                        const dataCell = toCellId(cell.id, row.id)

                        return (
                            <TableCell
                                tabIndex={-1}
                                onKeyDown={isKeyboardAccessible ? e => handleKeyboardInteraction(e, row, cell) : undefined}
                                key={dataCell}
                                dataCell={dataCell}
                                onMouseUp={handleMouseUp}
                                onMouseDown={handleCellMouseDown(dataCell)}
                                onMouseMove={handleCellMouseMove(dataCell)}
                                className={clsx(
                                    cellClassName?.(row, cell),
                                    'group/cell relative border-b border-r transition-all last:border-r-0 hover:bg-fill-hover',
                                    {
                                        'fake-border bg-fill-hover': selectedCells.has(dataCell),
                                        'cursor-pointer': selectable,
                                    }
                                )}
                            >
                                {cellRender(row, cell)}
                            </TableCell>
                        )
                    })}
                </tr>
            ))}
        </>
    )
}
