import { Button, FilterDropdown, HeroSwitch, LocationFilter, PractitionerFilter, Show, StatusFilter } from '~/components'
import env from '~/env'
import { SidebarRightCollapseIcon, SidebarRightExpandIcon } from '~/icons'
import { selectSurgeonSchedulerFilters } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'

import { MagicButton } from './MagicButton'

type Props = {
    isPractitionerView: boolean
}

const showMagicButton = env.VITE_FF_SURGEON_SCHEDULER_MAGIC_WAND_BUTTON

export const Filters = ({ isPractitionerView }: Props) => {
    const isDrawerOpen = useStore(state => state.surgeonScheduler.isDrawerOpen)
    const { emptyFilters, removeSingleFilter } = useStore(state => state.appFilters.actions)
    const { toggleLocations, toggleDrawer } = useStore(state => state.surgeonScheduler.actions)

    const filters = useStore(selectSurgeonSchedulerFilters)
    const showLocations = useStore(state => state.surgeonScheduler.showLocations)

    return (
        <div className="flex items-center gap-2">
            {isPractitionerView && (
                <label data-test="locations-toggle" className="flex items-center gap-x-2">
                    <HeroSwitch checked={showLocations} onCheckedChange={toggleLocations} />
                    <span>Vis stuer</span>
                </label>
            )}
            {isPractitionerView && showMagicButton && <MagicButton />}
            <FilterDropdown clearFilters={emptyFilters} onDisplayItemRemove={removeSingleFilter} displayItems={filters}>
                <Show
                    condition={isPractitionerView}
                    fallback={
                        <>
                            <PractitionerFilter />
                            <LocationFilter />
                        </>
                    }
                >
                    <PractitionerFilter />
                    <StatusFilter />
                </Show>
            </FilterDropdown>

            <Show condition={isPractitionerView}>
                <Button color="white" size="icon" onClick={toggleDrawer} className="flex items-center gap-x-1">
                    {isDrawerOpen ? <SidebarRightCollapseIcon width={20} height={20} /> : <SidebarRightExpandIcon width={20} height={20} />}
                </Button>
            </Show>
        </div>
    )
}
