import { ViewSwitcher } from '~/app/MainLayout/ViewSwitcher'
import { Bar, DIDatePicker, Show } from '~/components'
import { HeroSideNavTrigger } from '~/hero-ui'
import {
    importLocationSchedules,
    importPractitionerScheduleLocations,
    importPractitionerSchedules,
    importPractitionerScheduleStatuses,
    importPractitionerServicePeriods,
    importStatusDefinitionDepartmentAssignments,
} from '~/store/di-entity.api'
import { selectDateArgs } from '~/store/selectors'
import { useStore } from '~/store/store'
import { useImportEntities } from '~/store/useImportEntities'

import { Filters } from './Filters/Filters'
import { LocationTable } from './LocationTable/LocationTable'
import { PractitionerView } from './PractitionerView/PractitionerView'

const SurgeonSchedulerPage = () => {
    const pageKey = 'SURGEON_SCHEDULER'
    const activeView = useStore(state => state.app.activeViews[pageKey])

    const isPractitionerView = activeView === '/surgeon-scheduler/practitioners'
    const isLocationView = activeView === '/surgeon-scheduler/locations'

    const dateArgs = useStore(selectDateArgs)

    const { isLoading } = useImportEntities(
        () => [
            importPractitionerScheduleStatuses({
                'practitioner_schedule.scheduled_date:gte': dateArgs['scheduled_date:gte'],
                'practitioner_schedule.scheduled_date:lte': dateArgs['scheduled_date:lte'],
            }),
            importPractitionerScheduleLocations({
                'practitioner_schedule.scheduled_date:gte': dateArgs['scheduled_date:gte'],
                'practitioner_schedule.scheduled_date:lte': dateArgs['scheduled_date:lte'],
            }),
            importPractitionerServicePeriods({}),
            importStatusDefinitionDepartmentAssignments({}),
            importLocationSchedules({ ...dateArgs, exclude_location: true }),
            importPractitionerSchedules({ ...dateArgs, exclude_practitioner: true }), // include practitioner's status + practitioner's locations
        ],
        [dateArgs]
    )

    return (
        <div className="flex h-full flex-col gap-2">
            <div className="px-2 pt-2">
                <Bar
                    left={
                        <div className="flex gap-1">
                            <HeroSideNavTrigger />
                            <ViewSwitcher pageKey={pageKey} />
                        </div>
                    }
                    center={<DIDatePicker unit="month" />}
                    right={<Filters isPractitionerView={isPractitionerView} />}
                />
            </div>

            <div className="overflow-hidden" data-test="surgeon-scheduler-page">
                <Show condition={isPractitionerView}>
                    <PractitionerView isLoading={isLoading} />
                </Show>
                <Show condition={isLocationView}>
                    <LocationTable isLoading={isLoading} />
                </Show>
            </div>
        </div>
    )
}

export default SurgeonSchedulerPage
