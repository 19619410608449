import { Duration } from 'dayjs/plugin/duration'

import { isCountBasedRuleEvaluation, isRoomDurationBasedRuleEvaluation, OccupancyData, RuleEvaluation, ScheduledSurgery } from '~/store/selectors'
import { getKnifeTime, getRoomDuration } from '~/utils/dips'
import { accumulateDuration, emptyDuration, formatDuration } from '~/utils/extendedDayjs'

export function formatCapacity(evaluation: RuleEvaluation): string | undefined {
    if (evaluation.remaining === null) return undefined

    if (isCountBasedRuleEvaluation(evaluation)) {
        if (evaluation.remaining === 1) {
            return '1 operasjon'
        }
        return `${evaluation.remaining} operasjoner`
    } else if (isRoomDurationBasedRuleEvaluation(evaluation)) {
        return `${formatDuration(evaluation.remaining)} stuetid`
    } else {
        console.error('Unhandled details type')
        return undefined
    }
}

export function getUsedRoomDuration(data: OccupancyData): Duration {
    const surgeries = [...data.bookedSurgeries, ...data.mismatchedSurgeries]
    return surgeries.map(getRoomDuration).reduce(accumulateDuration, emptyDuration)
}
export function formatUsedRoomDuration(data: OccupancyData): string {
    return formatDuration(getUsedRoomDuration(data))
}

export function formatTotalKnifeTime(surgeries: ScheduledSurgery[]): string {
    const totalKnifeTime = surgeries.map(getKnifeTime).reduce(accumulateDuration, emptyDuration)

    return formatDuration(totalKnifeTime)
}
