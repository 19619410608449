import PageLayout from '~/components/PageLayout'
import { useStore } from '~/store/store'

import { AvailableTime } from './AvailableTime/AvailableTime'
import { BookingDrawer } from './BookingDrawer/BookingDrawer'
import { Calendar } from './Calendar/Calendar'
import { Header } from './Header/Header'

const Content = () => {
    const isCalendarView = useStore(state => state.app.activeViews.OPERATIONAL_PLANNER) === '/or-schedule/calendar'
    return isCalendarView ? <Calendar /> : <AvailableTime />
}

const OperationalPlannerPage = () => {
    const isSidebarOpen = useStore(state => state.operationalPlanner.isDrawerOpen)

    return (
        <div className="flex h-full flex-col">
            <PageLayout header={<Header />} content={<Content />} sidebar={<BookingDrawer />} isSidebarOpen={isSidebarOpen} />
        </div>
    )
}

export default OperationalPlannerPage
