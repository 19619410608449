import { ViewSwitcher } from '~/app/MainLayout/ViewSwitcher'
import { Bar, Button } from '~/components'
import { HeroSideNavTrigger } from '~/hero-ui'
import { SidebarRightCollapseIcon, SidebarRightExpandIcon } from '~/icons'
import { useStore } from '~/store/store'

import { DatePicker } from './DatePicker'
import { Filters } from './Filters'

export const Header = () => {
    const drawerProps = useStore(state => state.operationalPlanner.drawerProps)
    const isDrawerOpen = useStore(state => state.operationalPlanner.isDrawerOpen)

    const { set } = useStore(state => state.operationalPlanner.actions)

    const toggleSidebar = () => {
        set({
            isDrawerOpen: !isDrawerOpen,
        })
    }
    return (
        <Bar
            left={
                <div className="flex gap-1">
                    <HeroSideNavTrigger />
                    <ViewSwitcher pageKey="OPERATIONAL_PLANNER" />
                </div>
            }
            center={<DatePicker />}
            right={
                <div className="flex gap-2">
                    <Filters />
                    <Button size="sm" color="white" onClick={() => toggleSidebar()} className="flex items-center gap-1" disabled={!drawerProps}>
                        {isDrawerOpen ? <SidebarRightCollapseIcon width={16} /> : <SidebarRightExpandIcon width={16} />}
                        {`${isDrawerOpen ? 'Lukk' : 'Åpne'} sidepanel`}
                    </Button>
                </div>
            }
        />
    )
}
