import api from '~/clients/api-client'
import { isNumber } from '~/utils/utils'

import { getErrorMessageByStatusCode } from './errorMessages'
import { dispatchErrorToast } from './slices/toasterSlice'
import { DiEntityKey, useStore } from './store'

/**
 * Middleware that adds the fetched entities to the store
 */

type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AsyncFn = (...args: any[]) => Promise<any>

const onGoingFetches = new Map<string, Promise<void>>()

export function withImport<T extends AsyncFn>(call: T): (...args: Parameters<T>) => Promise<Awaited<ReturnType<T>>> {
    return async (...args: Parameters<T>) => {
        const [query] = args
        const key = `${call.name}__${JSON.stringify(query)}`

        if (onGoingFetches.has(key)) {
            return onGoingFetches.get(key)
        }

        const promise = call(...args)

        onGoingFetches.set(key, promise)

        // Error on fetch should be handled by the caller, so that multiple
        // fetch errors can be handled with a single user-facing error message
        let result
        try {
            result = await promise
        } catch (error) {
            throw error
        } finally {
            onGoingFetches.delete(key)
        }

        if (result?.response?.status === 200) {
            useStore.getState().di.actions.addEntities(result.data)
        }

        return result
    }
}

export const importAgeGroups = withImport(api.fetchAgeGroups)
export const importBlockLocks = withImport(api.fetchBlockLocks)
export const importBlockSchedules = withImport(api.fetchBlockSchedules)
export const importComments = withImport(api.fetchComments)
export const importDepartmentLocationAssignments = withImport(api.fetchDepartmentLocationAssignments)
export const importDepartmentPractitionerAssignments = withImport(api.fetchDepartmentPractitionerAssignments)
export const importDepartments = withImport(api.fetchDepartments)
export const importHospitalSurgeryTypeGroupAssociations = withImport(api.fetchHospitalSurgeryTypeGroupAssociations)
export const importHospitalSurgeryTypes = withImport(api.fetchHospitalSurgeryTypes)
export const importLocations = withImport(api.fetchLocations)
export const importLocationSchedules = withImport(api.fetchLocationSchedules)
export const importPlanningPeriods = withImport(api.fetchPlanningPeriods)
export const importPractitioners = withImport(api.fetchPractitioners)
export const importPractitionerScheduleLocations = withImport(api.fetchPractitionerScheduleLocations)
export const importPractitionerSchedules = withImport(api.fetchPractitionerSchedules)
export const importPractitionerScheduleStatuses = withImport(api.fetchPractitionerScheduleStatuses)
export const importPractitionerServicePeriods = withImport(api.fetchPractitionerServicePeriods)
export const importPractitionerStatusDefinitions = withImport(api.fetchPractitionerStatusDefinitions)
export const importRuleDefinitions = withImport(api.fetchRuleDefinitions)
export const importSections = withImport(api.fetchSections)
export const importSpecialities = withImport(api.fetchSpecialities)
export const importSurgeryMetadata = withImport(api.fetchSurgeryMetadata)
export const importSurgeryTypeGroupAgeRestrictions = withImport(api.fetchSurgeryTypeGroupAgeRestrictions)
export const importSurgeryTypeGroupHierarchies = withImport(api.fetchSurgeryTypeGroupHierarchies)
export const importSurgeryTypeGroups = withImport(api.fetchSurgeryTypeGroups)
export const importSurgeryTypeGroupSpecialties = withImport(api.fetchSurgeryTypeGroupSpecialities)
export const importWorkloadExecutions = withImport(api.fetchWorkloadExecutions)
export const importStatusDefinitionDepartmentAssignments = withImport(api.fetchStatusDefinitionDepartmentAssignments)

/**
 * Middleware that deletes entities from the store
 * Removes the entity from the store before the call, and adding it back if the call fails.
 * This is to provide a better user experience by showing the entity as deleted immediately.
 */
const onGoingDeletions = new Map<string, Promise<void>>()

async function runDeleteCall<T extends AsyncFn>(call: T, currentKey: string, handleFailure: () => void, ...args: Parameters<T>) {
    const deletion = call(...args)

    onGoingDeletions.set(currentKey, deletion)

    let result
    try {
        result = await deletion
    } catch (error) {
        handleFailure()
        throw error
    } finally {
        onGoingDeletions.delete(currentKey)
    }

    return result
}

function withOptimisticDeletion<T extends AsyncFn>(call: T, key: DiEntityKey): (...args: Parameters<T>) => Promise<void> {
    return async (...args: Parameters<T>) => {
        if (args.length > 1) {
            console.error('withOptimisticDeletion does not support batch deletions, use withOptimisticBatchDeletion instead')
            return
        }

        const [id] = args
        const currentEntity = useStore.getState().di.entities[key].byId[id]

        // If entity is not in the store, we don't do anything and return a warning
        // This is in order to prevent accidental deletions
        if (!currentEntity) {
            console.warn(`Trying to delete entity with id ${id} from ${key}, but it's not in the store`)
            return
        }

        // On error, undo the deletion and show an error
        const onFailure = (statusCode?: number) => {
            useStore.getState().di.actions.addEntities([currentEntity])
            dispatchErrorToast(getErrorMessageByStatusCode(statusCode))
        }

        // Optimistically remove the entity from the store
        useStore.getState().di.actions.removeEntity(key, currentEntity.id)

        const currentKey = `${call.name}__${id}`
        if (onGoingDeletions.has(currentKey)) return onGoingDeletions.get(currentKey)
        const result = await runDeleteCall(call, currentKey, onFailure, ...args)

        // The backend API returns 404 when the entity is already deleted, but this
        // is actually incorrect: it should return a 204. We handle this case here.
        // We should remove this workaround when the backend is fixed.
        const alreadyDeleted = result?.error?.message === 'the resource cannot be found'

        if ([200, 204].includes(result?.response?.status) || alreadyDeleted) {
            return result
        } else {
            onFailure()
            throw new Error(`Failed to delete entity with id ${id} from ${key}`)
        }
    }
}

function withOptimisticBatchDeletion<T extends AsyncFn>(call: T, key: DiEntityKey): (...args: Parameters<T>) => Promise<void> {
    return async (...args: Parameters<T>) => {
        const any_ids = args[0]
        if (!Array.isArray(any_ids)) {
            console.error('withOptimisticBatchDeletion expects an array of ids as the first argument')
            return
        }

        const ids = any_ids.filter(isNumber)

        if (ids.length === 0) {
            return
        }

        const currentEntities = ids.map(id => useStore.getState().di.entities[key].byId[id])

        // If entity is not in the store, we don't do anything and return a warning
        // This is in order to prevent accidental deletions
        if (currentEntities.some(entity => !entity)) {
            console.warn(`Trying to delete entities with ids ${ids} from ${key}, but some are not in the store`)
            return
        }

        // On error, undo the deletion and show an error
        const onFailure = (statusCode?: number) => {
            useStore.getState().di.actions.addEntities(currentEntities.filter(Boolean))
            dispatchErrorToast(getErrorMessageByStatusCode(statusCode))
        }

        // Optimistically remove the entity from the store
        useStore.getState().di.actions.removeEntities(key, ids)

        const currentKey = `${call.name}__${JSON.stringify(ids)}`
        if (onGoingDeletions.has(currentKey)) return onGoingDeletions.get(currentKey)
        const result = await runDeleteCall(call, currentKey, onFailure, ...args)
        const alreadyDeleted = result?.error?.message === 'the resource cannot be found'

        if ([200, 204].includes(result?.response?.status) || alreadyDeleted) {
            return result
        } else {
            onFailure(result?.response?.status)
            throw new Error(`Failed to delete entities with ids ${ids} from ${key}`)
        }
    }
}

export const deleteBatchPractitionerScheduleLocation = withOptimisticBatchDeletion(api.deleteBatchPractitionerScheduleLocation, 'practitionerScheduleLocations')
export const deleteBatchPractitionerScheduleStatus = withOptimisticBatchDeletion(api.deleteBatchPractitionerScheduleStatus, 'practitionerScheduleStatuses')
export const deleteBlockLock = withOptimisticDeletion(api.deleteBlockLock, 'blockLocks')
export const deleteBlockSchedule = withOptimisticDeletion(api.deleteBlockSchedule, 'blockSchedules')
export const deleteComment = withOptimisticDeletion(api.deleteComment, 'comments')
export const deleteDepartment = withOptimisticDeletion(api.deleteDepartment, 'departments')
export const deleteDepartmentLocationAssignment = withOptimisticDeletion(api.deleteDepartmentLocationAssignment, 'departmentLocationAssignments')
export const deleteDepartmentPractitionerAssignment = withOptimisticDeletion(api.deleteDepartmentPractitionerAssignment, 'departmentPractitionerAssignments')
export const deleteLocation = withOptimisticDeletion(api.deleteLocation, 'locations')
export const deleteLocationSchedule = withOptimisticDeletion(api.deleteLocationSchedule, 'locationSchedules')
export const deletePlanningPeriod = withOptimisticDeletion(api.deletePlanningPeriod, 'planningPeriods')
export const deletePractitioner = withOptimisticDeletion(api.deletePractitioner, 'practitioners')
export const deletePractitionerSchedule = withOptimisticDeletion(api.deletePractitionerSchedule, 'practitionerSchedules')
export const deletePractitionerScheduleLocation = withOptimisticDeletion(api.deletePractitionerScheduleLocation, 'practitionerScheduleLocations')
export const deletePractitionerScheduleStatus = withOptimisticDeletion(api.deletePractitionerScheduleStatus, 'practitionerScheduleStatuses')
export const deleteSection = withOptimisticDeletion(api.deleteSection, 'sections')
export const deleteSurgeryMetadata = withOptimisticDeletion(api.deleteSurgeryMetadata, 'surgeryMetadata')

/**
 * Middleware that creates entities
 */
const onGoingCreations = new Map<string, Promise<void>>()

function withCreate<T extends AsyncFn>(call: T): (...args: Parameters<T>) => Promise<Awaited<ReturnType<T>>> {
    return async (...args: Parameters<T>) => {
        const [entity] = args

        // in case if we passed empty data into batch creation
        if (entity.length === 0) {
            return
        }

        const currentKey = `${call.name}__${JSON.stringify(entity)}`
        if (onGoingCreations.has(currentKey)) return onGoingCreations.get(currentKey)

        function onFailure(statusCode?: number) {
            dispatchErrorToast(getErrorMessageByStatusCode(statusCode))
        }

        const promise = call(...args)
        onGoingCreations.set(currentKey, promise)

        let response
        try {
            response = await promise
        } catch (e) {
            onFailure()
            throw e
        } finally {
            onGoingCreations.delete(currentKey)
        }

        if (response?.response?.status === 200) {
            const data = Array.isArray(response.data) ? response.data : [response.data]
            useStore.getState().di.actions.addEntities(data)
        } else {
            onFailure(response?.response?.status)
        }

        return response
    }
}

export const createBatchBlockSchedule = withCreate(api.createBatchBlockSchedule)
export const createBatchPractitionerSchedule = withCreate(api.createBatchPractitionerSchedule)
export const createBatchPractitionerScheduleLocation = withCreate(api.createBatchPractitionerScheduleLocation)
export const createBatchPractitionerScheduleStatus = withCreate(api.createBatchPractitionerScheduleStatus)
export const createBlockLock = withCreate(api.createBlockLock)
export const createBlockSchedule = withCreate(api.createBlockSchedule)
export const createComment = withCreate(api.createComment)
export const createDepartment = withCreate(api.createDepartment)
export const createDepartmentLocationAssignment = withCreate(api.createDepartmentLocationAssignment)
export const createDepartmentPractitionerAssignment = withCreate(api.createDepartmentPractitionerAssignment)
export const createLocation = withCreate(api.createLocation)
export const createLocationSchedule = withCreate(api.createLocationSchedule)
export const createPlanningPeriod = withCreate(api.createPlanningPeriod)
export const createPractitioner = withCreate(api.createPractitioner)
export const createPractitionerSchedule = withCreate(api.createPractitionerSchedule)
export const createPractitionerScheduleLocation = withCreate(api.createPractitionerScheduleLocation)
export const createPractitionerScheduleStatus = withCreate(api.createPractitionerScheduleStatus)
export const createSection = withCreate(api.createSection)
export const createSurgeryMetadata = withCreate(api.createSurgeryMetadata)

/**
 * Middleware that updates entities
 * Works by updating the entity in the store before the call, and adding it back if the call fails.
 * This is to provide a better user experience by showing the updated entity immediately.
 */
const onGoingUpdates = new Map<string, Promise<void>>()

function withOptimisticUpdate<T extends AsyncFn>(call: T, key: DiEntityKey): (...args: Parameters<T>) => Promise<Awaited<ReturnType<T>>> {
    return async (...args: Parameters<T>) => {
        if (args.length > 2) {
            console.error('withOptimisticUpdate does not support batch updates, use withOptimisticBatchUpdate instead')
            return
        }

        const [id, userUpdatedEntity] = args
        const storedEntity = useStore.getState().di.entities[key].byId[id]

        // If entity is not in the store, we don't do anything and return a warning
        // This is in order to prevent accidental updates
        if (!storedEntity) {
            console.warn(`Trying to update entity with id ${id} from ${key}, but it's not in the store`)
            return
        }

        const currentKey = `${call.name}__${id}`
        if (onGoingUpdates.has(currentKey)) return onGoingUpdates.get(currentKey)

        function onFailure(statusCode?: number) {
            dispatchErrorToast(getErrorMessageByStatusCode(statusCode))
            useStore.getState().di.actions.removeEntity(key, id)
            if (storedEntity) {
                useStore.getState().di.actions.addEntities([storedEntity])
            }
        }

        // Optimistically update the entity in the store
        const optimisticEntity = { ...storedEntity, ...userUpdatedEntity }
        useStore.getState().di.actions.removeEntity(key, id)
        useStore.getState().di.actions.addEntities([optimisticEntity])

        const promise = call(...args)
        onGoingUpdates.set(currentKey, promise)

        let result

        try {
            result = await promise
        } catch (error) {
            onFailure()
            throw error
        } finally {
            onGoingUpdates.delete(currentKey)
        }

        if (result?.response?.status === 200) {
            useStore.getState().di.actions.removeEntity(key, id)
            useStore.getState().di.actions.addEntities([result.data])
        } else {
            onFailure(result?.response?.status)
        }

        return result
    }
}

type BatchUpdateSchema = {
    id: number
    body: Record<string, unknown>
}

function isBatchUpdateSchema(arg: unknown): arg is BatchUpdateSchema {
    return typeof arg === 'object' && arg !== null && 'id' in arg && 'body' in arg
}

function withOptimisticBatchUpdate<T extends AsyncFn>(call: T, key: DiEntityKey): (...args: Parameters<T>) => Promise<Awaited<ReturnType<T>>> {
    return async (...args: Parameters<T>) => {
        const batch = args[0]
        if (!Array.isArray(batch) || !batch.every(isBatchUpdateSchema)) {
            console.error('withOptimisticBatchUpdate expects an array of { id: number, body: Record<string, unknown> } as the arguments')
            return
        }

        if (batch.length === 0) {
            return
        }

        const ids = batch.map(({ id }) => id)

        const currentEntities = ids.map(id => useStore.getState().di.entities[key].byId[id])

        const currentKey = `${call.name}__${JSON.stringify(batch)}`
        if (onGoingUpdates.has(currentKey)) return onGoingUpdates.get(currentKey)

        // Optimistically update the entity in the store
        const optimisticEntities = batch.map(({ id, body }) => {
            const storedEntity = useStore.getState().di.entities[key].byId[id]
            if (!storedEntity) {
                console.warn(`Trying to update entity with id ${id} from ${key}, but it's not in the store`)
                return null
            }

            const optimisticEntity = { ...storedEntity, ...body }
            useStore.getState().di.actions.removeEntity(key, id)
            return optimisticEntity
        })

        function onFailure(statusCode?: number) {
            // Optimistically added entities are removed on failure
            useStore.getState().di.actions.removeEntities(key, ids)

            // The entities that were in the store before the update are added back
            useStore.getState().di.actions.addEntities(currentEntities.filter(Boolean))

            dispatchErrorToast(getErrorMessageByStatusCode(statusCode))
        }

        // Optimistically add the entities to the store
        useStore.getState().di.actions.addEntities(optimisticEntities.filter(Boolean))

        const promise = call(batch)
        onGoingUpdates.set(currentKey, promise)

        let result
        try {
            result = await promise
        } catch (error) {
            onFailure()
            throw error
        } finally {
            onGoingUpdates.delete(currentKey)
        }

        if (result?.response?.status === 200) {
            const data = Array.isArray(result.data) ? result.data : [result.data]
            useStore.getState().di.actions.addEntities(data)
        } else {
            onFailure(result?.response?.status)
            throw new Error(`Failed to update entities with ids ${ids} from ${key}`)
        }

        return result
    }
}

// PUT operations
export const updateBatchBlockSchedule = withOptimisticBatchUpdate(api.updateBatchBlockSchedule, 'blockSchedules')
export const updateBlockLock = withOptimisticUpdate(api.updateBlockLock, 'blockLocks')
export const updateBlockSchedule = withOptimisticUpdate(api.updateBlockSchedule, 'blockSchedules')
export const updateComment = withOptimisticUpdate(api.updateComment, 'comments')
export const updateDepartment = withOptimisticUpdate(api.updateDepartment, 'departments')
export const updateDepartmentLocationAssignment = withOptimisticUpdate(api.updateDepartmentLocationAssignment, 'departmentLocationAssignments')
export const updateDepartmentPractitionerAssignment = withOptimisticUpdate(api.updateDepartmentPractitionerAssignment, 'departmentPractitionerAssignments')
export const updateLocation = withOptimisticUpdate(api.updateLocation, 'locations')
export const updateLocationSchedule = withOptimisticUpdate(api.updateLocationSchedule, 'locationSchedules')
export const updatePlanningPeriod = withOptimisticUpdate(api.updatePlanningPeriod, 'planningPeriods')
export const updatePractitioner = withOptimisticUpdate(api.updatePractitioner, 'practitioners')
export const updatePractitionerSchedule = withOptimisticUpdate(api.updatePractitionerSchedule, 'practitionerSchedules')
export const updatePractitionerScheduleLocation = withOptimisticUpdate(api.updatePractitionerScheduleLocation, 'practitionerScheduleLocations')
export const updatePractitionerScheduleStatus = withOptimisticUpdate(api.updatePractitionerScheduleStatus, 'practitionerScheduleStatuses')
export const updateSection = withOptimisticUpdate(api.updateSection, 'sections')

// PATCH operations
export const partialUpdateSurgeryMetadata = withOptimisticUpdate(api.partialUpdateSurgeryMetadata, 'surgeryMetadata')
