import { Dayjs } from 'dayjs'
import keyBy from 'lodash/keyBy'
import { createSelector } from 'reselect'

import { LocationOutPopulated } from '~/clients/derived-api-types'

import { LocationSchedule, selectEntities } from './entities'
import { LocationId } from './locations'
import { getDateAndLocationIdKey } from './utils'

/**
 * This selector returns a getter that allows O(1) access to PractitionerSchedule objects by
 * date and location after building an index for it.
 */
export const selectGetLocationSchedules = createSelector(selectEntities, ({ locationSchedules }) => {
    const byDateAndLocationId = keyBy(locationSchedules, schedule => getDateAndLocationIdKey(schedule.scheduled_date, schedule.location_id))

    return {
        byDateAndLocation: (date: Dayjs, location: LocationOutPopulated): LocationSchedule | null => {
            return byDateAndLocationId[getDateAndLocationIdKey(date, location.id)] ?? null
        },
        byCell: (args: { date: Dayjs; locationId: LocationId }): LocationSchedule | null => {
            return byDateAndLocationId[getDateAndLocationIdKey(args.date, args.locationId)] ?? null
        },
    }
})
