import { CopyToClipboard, Show } from '~/components'
import { CopyIcon } from '~/icons'
import { useStore } from '~/store/store'
import { formatAgeAndNationalId, formatFullNameAndMunicipality, formatNationalIdCopyText } from '~/utils/dips'

import { useGetWaitingListSurgery } from '../hooks/useGetWaitingListSurgery'

export const Header = () => {
    const drawerProps = useStore(state => state.waitingList.drawerProps)
    const surgery = useGetWaitingListSurgery(drawerProps?.item)

    const fullNameAndMunicipality = formatFullNameAndMunicipality(surgery?.patient)
    const ageAndNationalId = formatAgeAndNationalId(surgery?.patient)
    const { hasNationalId, nationalIdCopyText } = formatNationalIdCopyText(surgery?.patient)

    if (drawerProps === null) return null

    return (
        <header>
            <div className="flex flex-col">
                <div className="text-base font-bold">{fullNameAndMunicipality}</div>
                <div className="flex items-center gap-1">
                    {ageAndNationalId}
                    <Show condition={hasNationalId}>
                        <CopyToClipboard copyText={nationalIdCopyText}>
                            <CopyIcon className="h-4 w-4" />
                        </CopyToClipboard>
                    </Show>
                </div>
            </div>
        </header>
    )
}
