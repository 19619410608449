import { ComponentProps, FC } from 'react'

export const ScalesIcon: FC<ComponentProps<'svg'>> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="currentColor" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 1.75C8.35898 1.75 8.65 2.04101 8.65 2.4V3.18062L12.1718 3.75771C12.3761 3.79119 12.5522 3.91998 12.646 4.10452L14.6794 8.10284C14.7258 8.19412 14.75 8.29507 14.75 8.39748C14.75 9.10367 14.4646 9.77866 13.9602 10.2746C13.4561 10.7702 12.7748 11.0466 12.0667 11.0466C11.3585 11.0466 10.6772 10.7702 10.1732 10.2746C9.66876 9.77866 9.38333 9.10367 9.38333 8.39748C9.38333 8.29507 9.40753 8.19412 9.45395 8.10284L11.0843 4.89686L8.65 4.49795V13.0786H11.3889C11.7479 13.0786 12.0389 13.3696 12.0389 13.7286C12.0389 14.0876 11.7479 14.3786 11.3889 14.3786H4.61111C4.25213 14.3786 3.96111 14.0876 3.96111 13.7286C3.96111 13.3696 4.25213 13.0786 4.61111 13.0786H7.35V4.49795L4.91566 4.89686L6.54605 8.10284C6.59247 8.19412 6.61667 8.29507 6.61667 8.39748C6.61667 9.10367 6.33123 9.77866 5.82683 10.2746C5.32279 10.7702 4.64148 11.0466 3.93333 11.0466C3.22519 11.0466 2.54388 10.7702 2.03984 10.2746C1.53543 9.77866 1.25 9.10367 1.25 8.39748C1.25 8.29507 1.2742 8.19412 1.32062 8.10284L3.35395 4.10452C3.44779 3.91998 3.62392 3.79119 3.82822 3.75771L7.35 3.18062V2.4C7.35 2.04101 7.64102 1.75 8 1.75ZM3.93333 5.8331L5.3091 8.53838C5.27647 8.84137 5.1392 9.12757 4.91541 9.3476C4.6568 9.60187 4.30374 9.74664 3.93333 9.74664C3.56293 9.74664 3.20987 9.60187 2.95126 9.3476C2.72746 9.12757 2.5902 8.84137 2.55757 8.53838L3.93333 5.8331ZM12.0667 5.8331L13.4424 8.53838C13.4098 8.84137 13.2725 9.12757 13.0487 9.3476C12.7901 9.60187 12.4371 9.74664 12.0667 9.74664C11.6963 9.74664 11.3432 9.60187 11.0846 9.3476C10.8608 9.12757 10.7235 8.84137 10.6909 8.53839L12.0667 5.8331Z"
            fill="currentColor"
        />
    </svg>
)
