import { memo } from 'react'

type Props = {
    operation: string
    bookingDate: string
    room: string
    plannedProcedureDuration: string
}

export const FormOverview = memo(({ operation, bookingDate, room, plannedProcedureDuration }: Props) => (
    <div className="grid grid-cols-[auto_1fr_auto] gap-3 rounded border border-gray-200 bg-gray-50 p-2 text-xs">
        <div className="text-gray-600">Hva:</div>
        <div>{operation}</div>
        <div className="text-right">
            <span className="text-gray-600">Knivtid:</span> {plannedProcedureDuration}
        </div>
        <div className="text-gray-600">Hvor:</div>
        <div>
            <span className="capitalize">{bookingDate}</span> på {room}
        </div>
    </div>
))
