import { useState } from 'react'

import { PractitionerScheduleCreate } from '~/clients/derived-api-types'
import { createPractitionerSchedule, updatePractitionerSchedule } from '~/store/di-entity.api'
import { PractitionerSchedule, selectGetPractitionerSchedules } from '~/store/selectors'
import { useStore } from '~/store/store'
import { trackEvent } from '~/utils/analytics'
import { getOnlyDate } from '~/utils/extendedDayjs'
import { getNonEmptyString } from '~/utils/utils'

import { DatePractitionerCell, Note } from '../../shared'

async function upsertNote(selectedCell: DatePractitionerCell, practitionerSchedule: PractitionerSchedule | null, newNote?: string) {
    const scheduleData: PractitionerScheduleCreate = practitionerSchedule
        ? {
              practitioner_id: practitionerSchedule.practitioner_id,
              location_id: practitionerSchedule.location_id,
              comment: newNote,
              scheduled_date: practitionerSchedule.scheduled_date,
          }
        : {
              practitioner_id: selectedCell.practitionerId,
              scheduled_date: getOnlyDate(selectedCell.date),
              comment: newNote,
          }
    if (practitionerSchedule && practitionerSchedule.comment === newNote) return
    return practitionerSchedule ? updatePractitionerSchedule(practitionerSchedule.id, scheduleData) : createPractitionerSchedule(scheduleData)
}

type Props = {
    cell: DatePractitionerCell
}

export const PractitionerNote = ({ cell }: Props) => {
    const practitionerSchedule = useStore(selectGetPractitionerSchedules).byDateAndPractitionerId(cell.date, cell.practitionerId)

    const [note, setNote] = useState(practitionerSchedule?.comment ?? '')
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    async function updateNote() {
        const newNote = getNonEmptyString(note)

        setIsLoading(true)
        setIsSuccess(false)

        try {
            await upsertNote(cell, practitionerSchedule, newNote)
        } catch (error) {
            console.error(error)
            setIsLoading(false)
            return
        }

        setIsLoading(false)
        setIsSuccess(true)

        setNote(newNote ?? '')
        void trackEvent('SS_BUTTON_REMOVE_SURGEON_COMMENT__CLICK')
    }

    function deleteNote() {
        if (!practitionerSchedule) return
        const prompt = window.confirm('Er du sikker på at du vil fjerne notaten?')

        if (!prompt) return

        setNote('')
        void upsertNote(cell, practitionerSchedule, undefined)

        void trackEvent('SS_BUTTON_REMOVE_SURGEON_COMMENT__CLICK')
    }

    return <Note note={note} setNote={setNote} updateNote={updateNote} deleteNote={deleteNote} isLoading={isLoading} isSuccess={isSuccess} />
}
