import { Dayjs } from 'dayjs'
import { immer } from 'zustand/middleware/immer'

import { Location } from '../selectors'
import { Slice } from '../store'

type SelectedLocation = {
    unixDate: number
    locationId: number
}
type OpenDrawerArgs = {
    date?: Dayjs
    location?: Location
}

type State = {
    isDrawerOpen: boolean
    selectedLocationDay: SelectedLocation | null
}

const initialState: State = {
    isDrawerOpen: true,
    selectedLocationDay: null,
}

type Actions = {
    actions: {
        set: (args: Partial<State>) => void
        openDrawer: (args: OpenDrawerArgs) => void
        closeDrawer: () => void
    }
}

export type SurgerySchedulerSlice = {
    surgeryScheduler: State & Actions
}

export const createSurgerySchedulerSlice: Slice<SurgerySchedulerSlice> = immer(set => ({
    surgeryScheduler: {
        ...initialState,
        actions: {
            set: args => {
                set(state => {
                    Object.assign(state.surgeryScheduler, args)
                })
            },
            openDrawer: ({ location, date }: OpenDrawerArgs) => {
                if (!location || !date) return

                set(state => {
                    state.surgeryScheduler.selectedLocationDay = {
                        unixDate: date.unix(),
                        locationId: location.id,
                    }
                    state.surgeryScheduler.isDrawerOpen = true
                })
            },
            closeDrawer: () => {
                set(state => {
                    state.surgeryScheduler.isDrawerOpen = false
                    state.surgeryScheduler.selectedLocationDay = null
                })
            },
        },
    },
}))
