import clsx from 'clsx'
import { Dayjs } from 'dayjs'
import { memo, ReactNode } from 'react'

import { DayFormatForColumn } from '~/app/pages/shared/table/DayColumn/DayColumn'
import { DataGrid, HolidayTag, Show } from '~/components'
import PageLayout from '~/components/PageLayout'
import { IdDate, Practitioner, PractitionerScheduleLocation, PractitionerScheduleStatus } from '~/store/selectors'
import { useStore } from '~/store/store'
import { getPractitionerFullname } from '~/utils/dips/practitioner'
import { day, getToday, isSameDate } from '~/utils/extendedDayjs'
import { isHoliday } from '~/utils/holidays'
import { getHolidayStyles } from '~/utils/utils'

import { CreateCountable, CreatePractitionersSelect } from '../../PractitionerView/hooks'
import { DatePractitionerCell, getFridayStyles } from '../utils'
import { CellScheduleLocations } from './Cells/CellScheduleLocations'
import { CellScheduleStatuses } from './Cells/CellScheduleStatuses'

type Props = {
    isLoading: boolean
    selection?: 'multiple'

    monthIdDates: IdDate[]
    activePractitioners: Practitioner[]

    getOrderedStatuses: (date: Dayjs, practitionerId: number) => PractitionerScheduleStatus[]
    getLocationSchedules: (date: Dayjs, practitionerId: number) => PractitionerScheduleLocation[]
    createEditableRow: CreatePractitionersSelect
    createCountableRow: CreateCountable

    // props to hide in review/commit mode
    sidebar?: ReactNode
    getCommentStyles?: (practitioner: Practitioner, date: Dayjs) => string
    modal?: (cells: DatePractitionerCell[]) => ReactNode
    keyboardInteractionComponent?: (cells: DatePractitionerCell[], isModalOpen: boolean) => ReactNode
}

const toDatePractitionerCell = (cell: { rowId: string; colId: string }): DatePractitionerCell => ({
    date: day(cell.colId),
    practitionerId: Number(cell.rowId),
})

const PractitionerTableComponent = (props: Props) => {
    const isSidebarOpen = useStore(state => state.surgeonScheduler.isDrawerOpen)
    const showLocations = useStore(state => state.surgeonScheduler.showLocations)

    return (
        <PageLayout
            header={null}
            isSidebarOpen={isSidebarOpen}
            sidebar={props.sidebar}
            content={
                <DataGrid
                    data-test={props.isLoading ? '' : 'practitioner-table'}
                    isLoading={props.isLoading}
                    selection={props.selection}
                    header={{
                        cells: props.monthIdDates,
                        rowHeader: () => (
                            <div className="w-full truncate">
                                {props.monthIdDates.at(0)?.date.isoWeek()}-{props.monthIdDates.at(-1)?.date.isoWeek()}
                            </div>
                        ),
                        cellClassName: ({ date }) => clsx(getFridayStyles(date), getHolidayStyles(date), { 'calendar-today': isSameDate(date, getToday()) }),
                        cellRender: ({ date }) => {
                            const dateIsHoliday = isHoliday(date)
                            return (
                                <div className="flex h-full flex-col gap-1">
                                    <DayFormatForColumn date={date} />
                                    <Show condition={dateIsHoliday}>
                                        <HolidayTag />
                                    </Show>
                                </div>
                            )
                        },
                        extraHeaders: [props.createEditableRow('Vakt', 'ON_DUTY'), props.createEditableRow('Ox', 'ON-CALL')],
                    }}
                    body={{
                        rows: props.activePractitioners,
                        rowRender: practitioner => (
                            <p
                                className="m-0.5 w-full rounded px-1 py-2 transition group-hover:bg-fill-hover"
                                data-tooltip={getPractitionerFullname(practitioner)}
                            >
                                {practitioner.short_name}
                            </p>
                        ),
                        cellClassName: (practitioner, { date }) =>
                            clsx(props.getCommentStyles?.(practitioner, date), getFridayStyles(date), getHolidayStyles(date)),
                        cellHeight: showLocations ? 'tall' : 'short',
                        cellRender: (practitioner, { date }) => {
                            const statuses = props.getOrderedStatuses(date, practitioner.id)
                            const locations = props.getLocationSchedules(date, practitioner.id)

                            const gridRows = showLocations ? 'grid-rows-2' : ''

                            return (
                                <div className={`grid w-full grid-cols-1 gap-y-1 ${gridRows}`}>
                                    <CellScheduleStatuses statuses={statuses} />
                                    {showLocations && <CellScheduleLocations locations={locations} />}
                                </div>
                            )
                        },
                    }}
                    footers={[
                        props.createCountableRow('Operasjon', ({ definition }) => definition?.assignment === 'surgery' && definition?.care === 'inpatient'),
                        props.createCountableRow('Dagkirurgi', ({ definition }) => definition?.assignment === 'surgery' && definition?.care === 'outpatient'),
                        props.createCountableRow(
                            'Poliklinikk',
                            ({ definition }) => definition?.assignment === 'policlinic' && definition?.time_of_day !== 'evening'
                        ),
                        props.createCountableRow(
                            'Kveldspoliklinikk',
                            ({ definition }) => definition?.assignment === 'policlinic' && definition?.time_of_day === 'evening'
                        ),
                    ]}
                    modal={cells => props.modal?.(cells.map(toDatePractitionerCell))}
                    keyboardInteractionComponent={(cells, isModalOpen) => props.keyboardInteractionComponent?.(cells.map(toDatePractitionerCell), isModalOpen)}
                />
            }
        />
    )
}

export const PractitionerTable = memo(PractitionerTableComponent)
