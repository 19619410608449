import clsx from 'clsx'
import { Dayjs } from 'dayjs'

import { Show } from '~/components'
import { ExtraRow } from '~/components/DataGrid/utils'
import {
    IdDate,
    Practitioner,
    PractitionerSchedule,
    PractitionerScheduleStatus,
    selectGetPractitioners,
    selectGetPractitionerSchedules,
    selectGetPractitionerScheduleStatuses,
    selectGetPractitionerStatusDefinitions,
} from '~/store/selectors'
import { selectPractitionerValues, StatusCode } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'
import { getHolidayStyles } from '~/utils/utils'

import { getFridayStyles, isDepartmentIncluded } from '../../../shared'
import { PractitionersSelect } from './PractitionersSelect'

export type DatePractitionerCell = {
    date: Dayjs
    practitionerId: number
}

const isActivePractitioner = (filteredPractitioner: Practitioner[], schedule?: PractitionerSchedule) => {
    return filteredPractitioner.map(practitioner => practitioner.id).includes(Number(schedule?.practitioner?.id))
}

export function useCreateInteractiveRows() {
    const getPractitionerScheduleStatuses = useStore(selectGetPractitionerScheduleStatuses)
    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const filteredPractitioners = useStore(selectPractitionerValues)
    const getPractitioners = useStore(selectGetPractitioners)
    const getPractitionerSchedules = useStore(selectGetPractitionerSchedules)
    const getPractitionerStatusDefinitions = useStore(selectGetPractitionerStatusDefinitions)

    const practitionersByDepartment = getPractitioners.byDepartmentKey(departmentKey)
    const activePractitioners = practitionersByDepartment.filter(
        practitioner => filteredPractitioners.length === 0 || filteredPractitioners.includes(practitioner.short_name)
    )

    function getMatchingPractitioners(fn: (practitionerScheduleStatus: PractitionerScheduleStatus) => boolean, date: Dayjs) {
        const practitionerSchedulesByDate = getPractitionerSchedules.byDate(date)
        const practitionerScheduleStatuses = practitionerSchedulesByDate.flatMap(schedule =>
            getPractitionerScheduleStatuses.byPractitionerScheduleId(schedule.id)
        )

        const matching = practitionerScheduleStatuses.filter(({ schedule }) => isActivePractitioner(activePractitioners, schedule)).filter(fn)

        const names = matching.map(({ schedule }) => schedule?.practitioner?.short_name).join(', ')
        return { total: matching.length, names: names }
    }

    function createCountable(name: string, fn: (practitionerScheduleStatus: PractitionerScheduleStatus) => boolean): ExtraRow<IdDate> {
        return {
            key: name,
            rowHeader: () => <span className="text-xs">{name}</span>,
            cellClassName: ({ date }) => clsx(getFridayStyles(date), getHolidayStyles(date)),
            cellRender: ({ date }) => {
                const practitionersWithSurgeryStatus = getMatchingPractitioners(fn, date)
                return (
                    <Show condition={practitionersWithSurgeryStatus.total > 0}>
                        <span className="text-xs" data-tooltip={practitionersWithSurgeryStatus.names}>
                            {practitionersWithSurgeryStatus.total}
                        </span>
                    </Show>
                )
            },
        }
    }

    function createPractitionersSelect(name: string, status: StatusCode): ExtraRow<IdDate> | null {
        const practitionerStatusDefinitions = getPractitionerStatusDefinitions.byCode(status)
        if (!practitionerStatusDefinitions) return null

        const departmentIds = practitionerStatusDefinitions.departments.map(department => department.id)
        if (!isDepartmentIncluded(departmentKey, departmentIds)) return null

        return {
            key: name,
            rowHeader: () => <p className="truncate p-1">{name}</p>,
            cellClassName: ({ date }) => clsx(getFridayStyles(date), getHolidayStyles(date)),
            cellRender: ({ date }) => {
                const schedulesByStatus = getPractitionerSchedules
                    .byDate(date)
                    .filter(schedule => isActivePractitioner(activePractitioners, schedule))
                    .filter(schedule => getPractitionerScheduleStatuses.byPractitionerScheduleId(schedule.id).some(s => s.status_code === status))

                return <PractitionersSelect name={name} date={date} status={status} practitionerScheduleByStatus={schedulesByStatus} />
            },
        }
    }

    return { createCountable, createPractitionersSelect }
}

export type CreateCountable = ReturnType<typeof useCreateInteractiveRows>['createCountable']
export type CreatePractitionersSelect = ReturnType<typeof useCreateInteractiveRows>['createPractitionersSelect']
