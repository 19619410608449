import { useState } from 'react'

import { createBatchBlockSchedule, updateBatchBlockSchedule } from '~/store/di-entity.api'
import { selectGetBlockSchedules, selectRuleDefinitionOptions, selectSurgeryTypeGroup } from '~/store/selectors'
import { useStore } from '~/store/store'
import { createRuleInstance, RuleDefinitionId, RuleInstanceParameters } from '~/store/utils/blockEvaluation'
import { getUpdateBlockScheduleEventData, trackUserEvent } from '~/utils/analytics'
import { getOnlyDate } from '~/utils/extendedDayjs'

import { BookedDay } from './MultipleDatePicker'

export const useCreateMultipleBlockSchedules = () => {
    const [isLoading, setIsLoading] = useState(false)
    const addToast = useStore(state => state.toaster.actions.addToast)
    const getBlockSchedules = useStore(selectGetBlockSchedules)
    const getRuleDefinitions = useStore(selectRuleDefinitionOptions)
    const getSurgeryTypeGroup = useStore(selectSurgeryTypeGroup)

    async function createMultipleBlockSchedules(
        ruleId: RuleDefinitionId,
        parameters: RuleInstanceParameters,
        dates: BookedDay[],
        locationId: number | null,
        closeModal: () => void
    ) {
        if (!parameters) return

        if (dates.length === 0) {
            addToast({ type: 'error', message: 'Du må velge minst en dato' })
            return
        }

        try {
            setIsLoading(true)
            const ruleInstance = createRuleInstance(ruleId, parameters)
            if (!ruleInstance) return
            if (dates.length === 0) return

            const bookedDates = dates.filter(({ isBooked }) => isBooked).map(({ date }) => date)
            const freeDates = dates.filter(({ isBooked }) => !isBooked).map(({ date }) => date)

            if (bookedDates.length > 0) {
                const allBookedBlockSchedules = bookedDates.map(date => getBlockSchedules.byDateAndLocationId(date, Number(locationId))).filter(Boolean)

                await updateBatchBlockSchedule(
                    allBookedBlockSchedules.map(blockSchedule => ({
                        id: blockSchedule.id,
                        body: {
                            scheduled_date: blockSchedule.scheduled_date,
                            location_id: locationId,
                            rule_instance: ruleInstance,
                        },
                    }))
                )

                allBookedBlockSchedules.forEach(
                    blockSchedule =>
                        void trackUserEvent(
                            'SS_BLOCK_UPDATED',
                            getUpdateBlockScheduleEventData(blockSchedule, ruleInstance, getSurgeryTypeGroup, getRuleDefinitions)
                        )
                )
            }

            if (freeDates.length > 0) {
                await createBatchBlockSchedule(
                    freeDates.map(date => ({
                        scheduled_date: getOnlyDate(date),
                        location_id: locationId,
                        rule_instance: ruleInstance,
                    }))
                )
                void trackUserEvent('SS_BLOCK_ADDED', { numberOfEdits: freeDates.length, ruleInstance })
            }

            // Modal is closed on success
            closeModal()
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    return { createMultipleBlockSchedules, isLoading }
}
