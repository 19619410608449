import { AnimatePresence, motion } from 'framer-motion'
import { type ReactNode } from 'react'

const sidebarVariants = {
    open: { width: 'fit-content', opacity: 1, display: 'flex', marginRight: '.5rem' },
    closed: { width: 0, opacity: 0, marginRight: '0', transitionEnd: { display: 'hidden' } },
}

type PageLayoutProps = { content: ReactNode; header: ReactNode; sidebar?: ReactNode; isSidebarOpen?: boolean }

function PageLayout({ content, header, sidebar, isSidebarOpen }: PageLayoutProps) {
    return (
        <div className="flex h-full w-full flex-col">
            {header && <div className="shrink p-2">{header}</div>}
            <div className="flex grow overflow-hidden pt-0">
                <div className="transition-margin mx-2 my-3 w-full overflow-y-auto">{content}</div>
                {sidebar && (
                    <AnimatePresence>
                        <motion.div
                            initial={false /** Reduces motion when switching between pages. */}
                            animate={isSidebarOpen ? 'open' : 'closed'}
                            variants={sidebarVariants}
                            exit="closed"
                            transition={{ duration: 0.2 }}
                            className="relative mb-4 mt-2"
                        >
                            {sidebar}
                        </motion.div>
                    </AnimatePresence>
                )}
            </div>
        </div>
    )
}

export default PageLayout
