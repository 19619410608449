import { Dayjs } from 'dayjs'
import { useMemo } from 'react'

import { DISelect, Tag } from '~/components'
import { HeroPopover } from '~/hero-ui'
import { PractitionerSchedule, selectGetPractitioners } from '~/store/selectors'
import { StatusCode } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'
import { getPractitionerFullname } from '~/utils/dips/practitioner'
import { day } from '~/utils/extendedDayjs'

import { usePractitionerScheduleActions } from './usePractitionerScheduleActions'

type Props = {
    date: Dayjs
    name: string
    status: StatusCode
    practitionerScheduleByStatus: PractitionerSchedule[]
}

function sortSchedules(status: StatusCode, schedules: PractitionerSchedule[]) {
    return schedules.toSorted((a, b) => {
        const firstStatus = a.statuses.find(({ status_code }) => status_code === status)
        const secondStatus = b.statuses.find(({ status_code }) => status_code === status)

        if (!firstStatus || !secondStatus) return 0

        return day(firstStatus?.created_at).diff(day(secondStatus?.created_at))
    })
}

export const PractitionersSelect = ({ name, date, status, practitionerScheduleByStatus }: Props) => {
    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const getPractitioners = useStore(selectGetPractitioners)

    const practitionerOptions = getPractitioners.byDepartmentKey(departmentKey).map(({ short_name, id }) => ({ label: short_name, value: id }))

    const sortedSchedules = useMemo(() => sortSchedules(status, practitionerScheduleByStatus), [status, practitionerScheduleByStatus])
    const values = useMemo(
        () => sortedSchedules.map(({ practitioner }) => practitioner && { label: practitioner.short_name, value: practitioner.id }),
        [sortedSchedules]
    )
    const { addPractitioner, removePractitioner, removePractitionerScheduleOnDate } = usePractitionerScheduleActions(status, date)

    return (
        <HeroPopover
            className="min-w-[290px]"
            align="start"
            side="right"
            noPadding
            popoverContent={
                <div className="flex max-w-[280px] select-text flex-col text-left">
                    <p className="border-b px-2 py-1 text-base font-normal text-gray-400">{name}</p>
                    <div className="max-h-72 p-2 text-sm">
                        <DISelect
                            isMulti
                            autoFocus
                            options={practitionerOptions}
                            value={values}
                            placeholder="Velg..."
                            onChange={(_, meta) => {
                                if (meta.action === 'clear') {
                                    void removePractitionerScheduleOnDate()
                                } else if (meta.action === 'select-option') {
                                    if (!meta.option) return
                                    void addPractitioner(getPractitioners.byId(meta.option.value))
                                } else if (meta.action === 'pop-value' || meta.action === 'remove-value') {
                                    if (!meta.removedValue) return
                                    void removePractitioner(getPractitioners.byId(meta.removedValue.value))
                                }
                            }}
                        />
                    </div>
                </div>
            }
        >
            <div data-tooltip={sortedSchedules.map(({ practitioner }) => getPractitionerFullname(practitioner)).join(', ')} className="h-full">
                <div className="flex h-full cursor-pointer flex-nowrap items-center justify-center gap-x-2 overflow-hidden transition-all hover:bg-gray-100">
                    {sortedSchedules.length > 0 && (
                        <>
                            {/* Display on 2xl screens */}
                            <span className="hidden items-center gap-x-2 2xl:flex">
                                {sortedSchedules.at(0)?.practitioner?.short_name}
                                {sortedSchedules.length > 1 && <Tag color="on-call">+{sortedSchedules.length - 1}</Tag>}
                            </span>
                            {/* Display on smaller screens */}
                            <span className="flex 2xl:hidden">
                                <Tag color="on-call">+{sortedSchedules.length}</Tag>
                            </span>
                        </>
                    )}
                </div>
            </div>
        </HeroPopover>
    )
}
