import { Show } from '~/components'
import {
    PractitionerScheduleLocation,
    PractitionerScheduleStatus,
    selectEntities,
    selectGetLocations,
    selectGetPractitionerScheduleLocations,
    selectGetPractitionerSchedules,
    selectGetPractitionerScheduleStatuses,
} from '~/store/selectors'
import { useStore } from '~/store/store'
import { day, humanizeDate } from '~/utils/extendedDayjs'

import { DatePractitionerCell, isDepartmentIncluded } from '../../shared'
import { LocationTagsWithStaticState } from './LocationTagsWithStaticState'
import { ModalElement } from './ModalElement'
import { PractitionerNote } from './PractitionerNote'
import { StatusTag } from './StatusTag'

type Props = {
    selectedCells: DatePractitionerCell[]
}

function getLatestUpdatedAtAndBy(allScheduleStatusesByCell: PractitionerScheduleStatus[], allScheduleLocationsByCell: PractitionerScheduleLocation[]) {
    return allScheduleStatusesByCell
        .map(({ updated_at, updated_by }) => ({ updated_at, updated_by }))
        .concat(allScheduleLocationsByCell.map(({ updated_at, updated_by }) => ({ updated_at, updated_by })))
        .toSorted((a, b) => day(b.updated_at).diff(day(a.updated_at)))
        .at(0)
}

export const PractitionerScheduleModal = ({ selectedCells }: Props) => {
    const departmentKey = useStore(state => state.appFilters.departmentKey)

    const getLocations = useStore(selectGetLocations)
    const { practitionerStatusDefinitions } = useStore(selectEntities)
    const getPractitionerSchedules = useStore(selectGetPractitionerSchedules)
    const getPractitionerScheduleStatuses = useStore(selectGetPractitionerScheduleStatuses)
    const getPractitionerScheduleLocations = useStore(selectGetPractitionerScheduleLocations)

    const firstCell = selectedCells.at(0)

    const practitionerSchedules = selectedCells.map(({ date, practitionerId }) => getPractitionerSchedules.byDateAndPractitionerId(date, practitionerId))

    const activeLocations = getLocations.byDepartment(departmentKey)
    const orderedPractitionerStatusDefinitions = practitionerStatusDefinitions.toSorted((a, b) => a.order - b.order)
    const allScheduleStatusesByCell = practitionerSchedules.flatMap(schedule => getPractitionerScheduleStatuses.byPractitionerScheduleId(schedule?.id ?? 0))
    const allScheduleLocationsByCell = practitionerSchedules.flatMap(schedule => getPractitionerScheduleLocations.byPractitionerScheduleId(schedule?.id ?? 0))
    const allPractitionerScheduleLocationsByDate = selectedCells.flatMap(({ date }) => getPractitionerScheduleLocations.byDate(date))

    const availableLocations = activeLocations.filter(
        location => !allPractitionerScheduleLocationsByDate.some(({ location_id }) => location_id === location.id)
    )
    const unavailableLocations = activeLocations.filter(location =>
        allPractitionerScheduleLocationsByDate.some(({ location_id }) => location_id === location.id)
    )

    const latestUpdatedAtAndBy = getLatestUpdatedAtAndBy(allScheduleStatusesByCell, allScheduleLocationsByCell)

    return (
        <div className="pointer-events-none relative flex w-72 flex-col py-3">
            <div className="flex items-center justify-between px-3">
                Aktivitet
                <div className="pointer-events-auto">
                    {latestUpdatedAtAndBy && (
                        <p
                            className="cursor-pointer text-xs text-gray-500 hover:underline"
                            data-tooltip={`Sist endret av ${latestUpdatedAtAndBy.updated_by} ${humanizeDate(day(latestUpdatedAtAndBy.updated_at))}`}
                        >
                            {humanizeDate(day(latestUpdatedAtAndBy.updated_at))}
                        </p>
                    )}
                </div>
            </div>

            <div className="pointer-events-auto border-b px-3 pb-3">
                <div className="mt-3 grid grid-cols-3 gap-2">
                    {orderedPractitionerStatusDefinitions.map(({ code, departments }, index) => {
                        const departmentIds = departments.map(department => department.id)
                        if (!isDepartmentIncluded(departmentKey, departmentIds)) return null

                        return (
                            <StatusTag
                                key={code}
                                selectedCells={selectedCells}
                                scheduleStatuses={allScheduleStatusesByCell}
                                statusCode={code}
                                autoFocus={index === 0}
                            />
                        )
                    })}
                </div>
            </div>

            <ModalElement label="Ledige stuer" count={availableLocations.length} maxCount={activeLocations.length}>
                <LocationTagsWithStaticState
                    selectedCells={selectedCells}
                    availableLocations={availableLocations}
                    scheduleLocations={allScheduleLocationsByCell}
                />
            </ModalElement>

            <ModalElement label="Stuer med kirurg" count={unavailableLocations.length} maxCount={activeLocations.length}>
                <LocationTagsWithStaticState
                    selectedCells={selectedCells}
                    availableLocations={unavailableLocations}
                    scheduleLocations={allScheduleLocationsByCell}
                />
            </ModalElement>

            <div className="pointer-events-auto px-3 pt-3">
                <Show
                    condition={selectedCells.length === 1}
                    fallback={<small className="text-gray-500">Du kan ikke skrive kommentar på flere dager samtidig. Velg kun én dag.</small>}
                >
                    {firstCell && <PractitionerNote cell={firstCell} />}
                </Show>
            </div>
        </div>
    )
}
