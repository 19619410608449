import { Dayjs } from 'dayjs'

import { Spinner } from '~/components'
import { HeroBadge } from '~/hero-ui'
import { importSurgeryMetadata } from '~/store/di-entity.api'
import { Location, selectGetScheduledSurgeries } from '~/store/selectors'
import { useStore } from '~/store/store'
import { useImportEntities } from '~/store/useImportEntities'
import { day, isBefore } from '~/utils/extendedDayjs'

import { EmptyItem } from '../shared/EmptyItem'
import { SurgeryRow } from './SurgeryRow'

type Props = {
    location: Location
    date: Dayjs
}

export const BookedSurgeries = ({ location, date }: Props) => {
    const { setSelectedSurgery } = useStore(state => state.operationalPlanner.actions)
    const { isLoading } = useImportEntities(() => [importSurgeryMetadata({})], [])
    const getScheduledSurgeries = useStore(selectGetScheduledSurgeries)

    const bookedSurgeries = getScheduledSurgeries.byDateAndRoomCode(date, location.room_code).toSorted((surgery1, surgery2) => {
        const timeA = surgery1.contact?.checkInTime
        const timeB = surgery2.contact?.checkInTime

        // Sort nullish last
        if (!timeA && !timeB) return 0
        if (!timeA) return 1
        if (!timeB) return -1

        return isBefore(day(timeA), day(timeB)) ? -1 : 1
    })

    if (isLoading) return <Spinner />

    return (
        <div className="flex flex-col gap-2" data-test="booked-surgeries">
            <div className="flex items-center gap-2">
                <span className="text-base">Operasjoner på programmet</span>
                <HeroBadge text={bookedSurgeries.length} />
            </div>
            <div className="flex flex-col gap-2">
                {bookedSurgeries.length > 0 ? (
                    <div className="border-t border-gray-200">
                        {bookedSurgeries.map((surgery, index) => (
                            <div
                                data-test="booked-surgery"
                                key={index}
                                className="cursor-pointer border-b border-gray-200 py-1 hover:bg-gray-50"
                                onClick={() => setSelectedSurgery(surgery)}
                            >
                                <SurgeryRow surgery={surgery} />
                            </div>
                        ))}
                    </div>
                ) : (
                    <EmptyItem>
                        <p>Det er ingen pasienter på programmet.</p>
                    </EmptyItem>
                )}
                <div data-test="booking-drawer-loaded" className="hidden" />
            </div>
        </div>
    )
}
