import { Dayjs } from 'dayjs'
import { useCallback, useState } from 'react'

import { Button, Show } from '~/components'
import env from '~/env'
import { HeroBadge } from '~/hero-ui'
import { useUnScheduledSuggestions } from '~/hooks/booking/useUnScheduledSuggestions'
import { HelpIcon } from '~/icons'
import { Location, OccupancyData, selectGetScheduledPractitioners, selectPlanningPeriods } from '~/store/selectors'
import { useStore } from '~/store/store'
import { trackEvent } from '~/utils/analytics'

import { EmptyItem } from '../shared/EmptyItem'
import { AvailableCard } from './AvailableCard'
import { LoadingCard } from './LoadingCard'
import { SearchButton } from './Search/SearchButton'
import { SearchDialog } from './Search/SearchDialog'
import { SuggestionsInfoCard } from './SuggestionsInfoCard'
import { SurgeryCard } from './SurgeryCard'

const MAX_LIST_LENGTH = 5

type Props = {
    location: Location
    date: Dayjs
    occupancyData: OccupancyData
}

export const PatientSuggestions = ({ location, date, occupancyData }: Props) => {
    const { setSelectedSurgery } = useStore(state => state.operationalPlanner.actions)

    const [showSearchModal, setShowSearchModal] = useState<boolean>(false)
    const [showSuggestionsInfo, setShowSuggestionsInfo] = useState<boolean>(false)

    const getScheduledPractitioners = useStore(selectGetScheduledPractitioners)
    const scheduledPractitioners = getScheduledPractitioners.byDateAndLocationId(date, location.id)

    const [displayedSuggestionsCount, setDisplayedSuggestionsCount] = useState<number>(MAX_LIST_LENGTH)
    const { unScheduledSuggestions, suggestionCount, isLoading } = useUnScheduledSuggestions({
        scheduledPractitioners,
        occupancyData,
    })
    const hasSuggestions = suggestionCount > 0
    const displayedSuggestions = unScheduledSuggestions.slice(0, displayedSuggestionsCount)

    const sensorRef = useCallback((node: HTMLDivElement | null) => {
        if (node !== null) {
            const observer = new IntersectionObserver(
                entries => {
                    if (entries[0]?.isIntersecting) {
                        setDisplayedSuggestionsCount(prev => prev + MAX_LIST_LENGTH)
                    }
                },
                { threshold: 0 }
            )

            observer.observe(node)
        }
    }, [])

    const getPlanningPeriods = useStore(selectPlanningPeriods)
    const { is_published: periodIsPublished } = getPlanningPeriods.byDate(date) ?? { is_published: true }
    const hideAvailability = !periodIsPublished

    const handleCloseSearchDialog = () => setShowSearchModal(false)

    return (
        <div className="flex flex-col gap-2">
            <div className="flex items-center justify-between">
                <p className="flex items-center gap-2" data-test="suggestion-count" data-count={suggestionCount} data-is-loading={isLoading}>
                    <span className="text-base">Mulige pasienter</span>
                    <HeroBadge text={suggestionCount} />
                    <Show condition={hasSuggestions}>
                        <Button size="icon" color="custom" onClick={() => setShowSuggestionsInfo(prev => !prev)}>
                            <HelpIcon />
                        </Button>
                    </Show>
                </p>
                <Show condition={env.VITE_FF_OPERATIONAL_PLANNER_BOOKING_SEARCH_SURGERIES}>
                    <SearchButton onClick={() => setShowSearchModal(true)} />
                </Show>
            </div>
            <Show condition={hasSuggestions && showSuggestionsInfo}>
                <SuggestionsInfoCard onClose={() => setShowSuggestionsInfo(false)} />
            </Show>
            <div className="flex flex-col" data-test="BookingDrawerAvailability">
                <Show condition={occupancyData && !hideAvailability}>
                    <AvailableCard occupancyData={occupancyData} />
                    <div data-test="booking-drawer-available-loaded" className="hidden" />
                </Show>
            </div>
            <Show condition={!isLoading} fallback={<LoadingCard />}>
                {hasSuggestions ? (
                    <>
                        {displayedSuggestions.map(surgery => (
                            <SurgeryCard
                                surgery={surgery}
                                key={surgery.bookingId}
                                onClick={surg => {
                                    void trackEvent('OP_SUGGESTION__CLICK', {
                                        bookingId: surg.bookingId,
                                        index: unScheduledSuggestions.findIndex(s => s.bookingId === surg.bookingId),
                                    })
                                    setSelectedSurgery(surg)
                                }}
                            />
                        ))}
                        {displayedSuggestionsCount < suggestionCount && <div className="h-48" ref={sensorRef} />}
                    </>
                ) : (
                    <EmptyItem>
                        <p>
                            Vi fant ingen pasienter som passer disse operasjonstypene på ventelisten
                            {scheduledPractitioners.length > 0 && ' til '}
                            {scheduledPractitioners.length > 0 && (
                                <span className="font-semibold">{scheduledPractitioners.map(prac => prac.short_name).join(', ')}</span>
                            )}{' '}
                            eller med uspesifisert kirurg.
                        </p>
                    </EmptyItem>
                )}
                <div data-test="booking-drawer-waiting-list-loaded" className="hidden" />
            </Show>
            <SearchDialog
                isOpen={showSearchModal}
                onClose={handleCloseSearchDialog}
                onClickSurgery={surgery => {
                    setSelectedSurgery(surgery)
                    handleCloseSearchDialog()
                }}
            />
        </div>
    )
}
