import { ComponentProps, FC } from 'react'

export const AsteriskIcon: FC<ComponentProps<'svg'>> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="currentColor" {...props}>
        <rect width="16" height="16" fill="white" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.33658 3.71522C9.33658 3.04407 8.7779 2.5 8.08872 2.5C7.39955 2.5 6.84087 3.04407 6.84087 3.71522V6.59686L4.31566 5.34099C3.7019 5.03575 2.95024 5.27284 2.6368 5.87055C2.32335 6.46827 2.56681 7.20026 3.18058 7.50551L6.29796 9.05587L3.93534 11.4406C3.45683 11.9236 3.47097 12.6929 3.96693 13.1589C4.4629 13.6249 5.25287 13.6111 5.73138 13.1281L8.07235 10.7653L10.3173 13.1129C10.7869 13.6041 11.5765 13.6315 12.0809 13.1741C12.5852 12.7167 12.6134 11.9478 12.1437 11.4566L9.80098 9.00667L12.8194 7.50551C13.4332 7.20026 13.6766 6.46827 13.3632 5.87055C13.0498 5.27284 12.2981 5.03575 11.6843 5.34099L9.33658 6.50861L9.33658 3.71522Z"
            fill="currentColor"
        />
    </svg>
)
