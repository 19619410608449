import * as Sentry from '@sentry/browser'
import { immer } from 'zustand/middleware/immer'

import api, { Schemas } from '~/clients/api-client'
import { SomeEntity } from '~/clients/api-guards'
import { day } from '~/utils/extendedDayjs'

import { Slice } from '../store'
import { walkEntities } from '../utils/walkEntities'

export const ENTITY_TYPE = 'entity_type' as const

type EntityId = number

type EntityTable<T> = {
    byId: Record<EntityId, T>
    allIds: EntityId[]
} & Record<string, unknown>

function createEntityState<T>(): EntityTable<T> {
    return {
        byId: {},
        allIds: [],
    }
}

export const entityKeys = [
    'ageGroups',
    'blockLocks',
    'blockSchedules',
    'comments',
    'departmentLocationAssignments',
    'departmentPractitionerAssignments',
    'departments',
    'hospitalSurgeryTypeGroupAssociations',
    'hospitalSurgeryTypes',
    'locations',
    'locationSchedules',
    'planningPeriods',
    'practitioners',
    'practitionerScheduleLocations',
    'practitionerSchedules',
    'practitionerScheduleStatuses',
    'practitionerServicePeriods',
    'practitionerStatusDefinitions',
    'ruleDefinitions',
    'sections',
    'specialities',
    'surgeryMetadata',
    'surgeryTypeGroupAgeRestrictions',
    'surgeryTypeGroupHierarchies',
    'surgeryTypeGroups',
    'surgeryTypeGroupSpecialities',
    'statusDefinitionDepartmentAssignments',
] as const
export type DiEntityKeys = typeof entityKeys
export type DiEntityKey = DiEntityKeys[number]

const entityStates = {
    ageGroups: createEntityState<Schemas['AgeGroupOut']>(),
    blockLocks: createEntityState<Schemas['BlockLockOut']>(),
    blockSchedules: createEntityState<Schemas['BlockScheduleOut']>(),
    comments: createEntityState<Schemas['CommentOut']>(),
    departmentLocationAssignments: createEntityState<Schemas['DepartmentLocationAssignmentOut']>(),
    departmentPractitionerAssignments: createEntityState<Schemas['DepartmentPractitionerAssignmentOut']>(),
    departments: createEntityState<Schemas['DepartmentOut']>(),
    hospitalSurgeryTypeGroupAssociations: createEntityState<Schemas['HospitalSurgeryTypeGroupAssociationOut']>(),
    hospitalSurgeryTypes: createEntityState<Schemas['HospitalSurgeryTypeOut']>(),
    locations: createEntityState<Schemas['LocationOut']>(),
    locationSchedules: createEntityState<Schemas['LocationScheduleOut']>(),
    planningPeriods: createEntityState<Schemas['PlanningPeriodOut']>(),
    practitioners: createEntityState<Schemas['PractitionerOut']>(),
    practitionerScheduleLocations: createEntityState<Schemas['PractitionerScheduleLocationOut']>(),
    practitionerSchedules: createEntityState<Schemas['PractitionerScheduleOut']>(),
    practitionerScheduleStatuses: createEntityState<Schemas['PractitionerScheduleStatusOut']>(),
    practitionerServicePeriods: createEntityState<Schemas['PractitionerServicePeriodOut']>(),
    practitionerStatusDefinitions: createEntityState<Schemas['PractitionerStatusDefinitionOut']>(),
    statusDefinitionDepartmentAssignments: createEntityState<Schemas['StatusDefinitionDepartmentAssignmentOut']>(),
    ruleDefinitions: createEntityState<Schemas['RuleDefinitionOut']>(),
    sections: createEntityState<Schemas['SectionOut']>(),
    specialities: createEntityState<Schemas['SpecialityOut']>(),
    surgeryMetadata: createEntityState<Schemas['SurgeryMetadatumOut']>(),
    surgeryTypeGroupAgeRestrictions: createEntityState<Schemas['SurgeryTypeGroupAgeRestrictionOut']>(),
    surgeryTypeGroupHierarchies: createEntityState<Schemas['SurgeryTypeGroupHierarchyOut']>(),
    surgeryTypeGroups: createEntityState<Schemas['SurgeryTypeGroupOut']>(),
    surgeryTypeGroupSpecialities: createEntityState<Schemas['SurgeryTypeGroupSpecialityOut']>(),
} satisfies Record<DiEntityKey, EntityTable<SomeEntity>>

const entityApiGuards = {
    ageGroups: api.isAgeGroupOut,
    blockLocks: api.isBlockLockOut,
    blockSchedules: api.isBlockScheduleOut,
    comments: api.isCommentOut,
    departmentLocationAssignments: api.isDepartmentLocationAssignmentOut,
    departmentPractitionerAssignments: api.isDepartmentPractitionerAssignmentOut,
    departments: api.isDepartmentOut,
    hospitalSurgeryTypeGroupAssociations: api.isHospitalSurgeryTypeGroupAssociationOut,
    hospitalSurgeryTypes: api.isHospitalSurgeryTypeOut,
    locations: api.isLocationOut,
    locationSchedules: api.isLocationScheduleOut,
    planningPeriods: api.isPlanningPeriodOut,
    practitioners: api.isPractitionerOut,
    practitionerScheduleLocations: api.isPractitionerScheduleLocationOut,
    practitionerSchedules: api.isPractitionerScheduleOut,
    practitionerScheduleStatuses: api.isPractitionerScheduleStatusOut,
    practitionerServicePeriods: api.isPractitionerServicePeriodOut,
    practitionerStatusDefinitions: api.isPractitionerStatusDefinitionOut,
    statusDefinitionDepartmentAssignments: api.isStatusDefinitionDepartmentAssignmentOut,
    ruleDefinitions: api.isRuleDefinitionOut,
    sections: api.isSectionOut,
    specialities: api.isSpecialityOut,
    surgeryMetadata: api.isSurgeryMetadatumOut,
    surgeryTypeGroupAgeRestrictions: api.isSurgeryTypeGroupAgeRestrictionOut,
    surgeryTypeGroupHierarchies: api.isSurgeryTypeGroupHierarchyOut,
    surgeryTypeGroups: api.isSurgeryTypeGroupOut,
    surgeryTypeGroupSpecialities: api.isSurgeryTypeGroupSpecialityOut,
} satisfies Record<DiEntityKey, (entity: SomeEntity) => boolean>

type State = {
    entities: typeof entityStates
}

type Actions = {
    actions: {
        addEntities: (entities: SomeEntity[]) => void
        removeEntity: (key: DiEntityKey, id: EntityId) => void
        removeEntities: (key: DiEntityKey, ids: EntityId[]) => void
        removeEntitiesByWebsocketEvent: (minimalEntities: SomeEntity[]) => void
    }
}

export type DiSlice = {
    di: State & Actions
}

export const createDiSlice: Slice<DiSlice> = immer(set => ({
    di: {
        entities: entityStates,
        actions: {
            addEntities: entities => {
                Sentry.startSpan({ name: 'addEntities' }, () => {
                    set(state => {
                        const affectedKeys = new Set<DiEntityKey>()

                        walkEntities(entities, entity => {
                            for (const key of entityKeys) {
                                if (entityApiGuards[key](entity)) {
                                    const current = state.di.entities[key].byId[entity.id]

                                    if (current && (current.updated_at === entity.updated_at || day(current?.updated_at).isAfter(day(entity.updated_at)))) {
                                        return
                                    }

                                    affectedKeys.add(key)
                                    state.di.entities[key].byId[entity.id] = entity
                                }
                            }
                        })

                        for (const key of affectedKeys) {
                            state.di.entities[key].allIds = Object.keys(state.di.entities[key].byId).map(Number)
                        }
                    })
                })
            },
            removeEntity: (key, id) => {
                set(state => {
                    if (state.di.entities[key].byId[id]) {
                        delete state.di.entities[key].byId[id]
                    }

                    state.di.entities[key].allIds = Object.keys(state.di.entities[key].byId).map(Number)
                })
            },
            removeEntities: (key, ids) => {
                set(state => {
                    for (const id of ids) {
                        if (state.di.entities[key].byId[id]) {
                            delete state.di.entities[key].byId[id]
                        }
                    }

                    state.di.entities[key].allIds = Object.keys(state.di.entities[key].byId).map(Number)
                })
            },
            removeEntitiesByWebsocketEvent: (minimalEntities: SomeEntity[]) => {
                set(state => {
                    for (const entity of minimalEntities) {
                        for (const key of entityKeys) {
                            if (entityApiGuards[key](entity)) {
                                if (state.di.entities[key].byId[entity.id]) {
                                    delete state.di.entities[key].byId[entity.id]
                                }

                                state.di.entities[key].allIds = Object.keys(state.di.entities[key].byId).map(Number)
                            }
                        }
                    }
                })
            },
        },
    },
}))
