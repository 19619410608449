import { Dayjs } from 'dayjs'

import { useStore } from '~/store/store'
import { format } from '~/utils/extendedDayjs'

import { Note } from './Note'

type InfoTextProps = {
    label: string
    value: string
}

const InfoText = ({ label, value }: InfoTextProps) => (
    <div>
        {label}: <span className="font-semibold">{value}</span>
    </div>
)

type HeaderProps = {
    roomCode: string
    date: Dayjs
}

export const Header = ({ roomCode, date }: HeaderProps) => {
    const { availableRoomDuration, usedRoomDuration, roomOpeningHours } = useStore(state => state.operationalPlanner.drawerProps) ?? {}
    const formattedDate = format(date, 'DD.MM.YYYY')

    return (
        <header className="flex flex-col gap-3">
            <p className="text-base font-semibold">
                Stue {roomCode} - {formattedDate}
            </p>
            <div className="flex flex-col gap-1">
                {availableRoomDuration && <InfoText label="Ledig stuetid" value={availableRoomDuration} />}
                {usedRoomDuration && <InfoText label="Brukt stuetid" value={usedRoomDuration + ' av ' + (roomOpeningHours ?? '?')} />}
            </div>
            <Note />
        </header>
    )
}
