import { ComponentProps, FC } from 'react'

export const BarChartIcon: FC<ComponentProps<'svg'>> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="currentColor" {...props}>
        <path
            d="M4.05039 7.2001C4.68552 7.2001 5.20039 7.71497 5.20039 8.3501L5.20039 12.3502C5.20039 12.9853 4.68552 13.5002 4.05039 13.5002C3.41526 13.5002 2.90039 12.9853 2.90039 12.3502L2.90039 8.3501C2.90039 7.71497 3.41526 7.2001 4.05039 7.2001Z"
            fill="currentColor"
        />
        <path
            d="M8.05039 4.7001C8.68552 4.7001 9.20039 5.21497 9.20039 5.8501L9.20039 12.3501C9.20039 12.9852 8.68552 13.5001 8.05039 13.5001C7.41526 13.5001 6.90039 12.9852 6.90039 12.3501L6.90039 5.8501C6.90039 5.21497 7.41526 4.7001 8.05039 4.7001Z"
            fill="currentColor"
        />
        <path
            d="M12.0504 2.2002C12.6855 2.2002 13.2004 2.71507 13.2004 3.3502V12.3502C13.2004 12.9853 12.6855 13.5002 12.0504 13.5002C11.4153 13.5002 10.9004 12.9853 10.9004 12.3502V3.3502C10.9004 2.71507 11.4153 2.2002 12.0504 2.2002Z"
            fill="currentColor"
        />
    </svg>
)
