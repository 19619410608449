import { HeroBadge, HeroSkeletonLoader, HeroTooltip } from '~/hero-ui'
import { usePlanningPeriodMetrics } from '~/hooks/tanstack-query/optimus/usePlanningPeriodMetrics'
import { StethoscopeIcon } from '~/icons/StethescopeIcon'

type Props = { planningPeriodId: number }

export const PlanningPeriodMetrics = ({ planningPeriodId }: Props) => {
    const { data, isLoading, error } = usePlanningPeriodMetrics({ request: { planningPeriodId: planningPeriodId }, isEnabled: true })

    return data ? (
        <>
            <HeroTooltip tooltipContent={data?.location_staffing.description}>
                {!error && (
                    <div>
                        {isLoading ? (
                            <div className="h-7 w-24">
                                <HeroSkeletonLoader />
                            </div>
                        ) : (
                            <HeroBadge
                                color="green"
                                icon={<StethoscopeIcon />}
                                text={`${(data?.location_staffing.available_location_days ?? 0) - (data?.location_staffing?.staffed_location_days ?? 0)} Ubemannede
                            stuedager`}
                                size="large"
                                data-test="metrics-loaded"
                            />
                        )}
                    </div>
                )}
            </HeroTooltip>
        </>
    ) : (
        <div className="h-6 w-32">
            <HeroSkeletonLoader />
        </div>
    )
}
