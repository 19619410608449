import { HeroSkeletonLoader } from '~/hero-ui'

type Props = { columnCount: number; rowsToShow: number }

export const LoadingRows = ({ columnCount, rowsToShow }: Props) => {
    const columns = Array.from({ length: columnCount }, (_, i) => i)
    const rows = Array.from({ length: rowsToShow }, (_, i) => i)

    return (
        <>
            {rows.map(row => (
                <tr className="border-b" key={`row-${row}`}>
                    {columns.map((column, i) => (
                        <td key={`loader-${i}-${row}`} className="">
                            <div className="mx-6 my-4 h-[24px]">
                                <HeroSkeletonLoader />
                            </div>
                        </td>
                    ))}
                </tr>
            ))}
        </>
    )
}
