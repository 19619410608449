import { Dayjs } from 'dayjs'
import keyBy from 'lodash/keyBy'
import { createSelector } from 'reselect'

import { groupByMany } from '~/utils/array'

import { BlockSchedule, selectEntities } from './entities'
import { LocationId } from './locations'
import { getDateAndLocationIdKey, getDateKey } from './utils'

type BlockScheduleId = BlockSchedule['id']

export const selectGetBlockSchedules = createSelector(selectEntities, ({ blockSchedules }) => {
    const byId = keyBy(blockSchedules, 'id')
    const byDate = groupByMany(blockSchedules, schedule => getDateKey(schedule.scheduled_date))
    const byDateAndLocationId = keyBy(blockSchedules, schedule => getDateAndLocationIdKey(schedule.scheduled_date, Number(schedule.location_id)))

    return {
        byId: (id: BlockScheduleId): BlockSchedule | null => byId[id] || null,
        byDate: (date: Dayjs): BlockSchedule[] => byDate[getDateKey(date)] ?? [],
        byDateAndLocationId: (date: Dayjs, locationId: LocationId): BlockSchedule | null =>
            byDateAndLocationId[getDateAndLocationIdKey(date, locationId)] ?? null,
    }
})
