import { AgeGroup, Tag } from '~/components'
import { CountRuleEvaluation } from '~/store/selectors'
import { formatSurgeryTypeGroup } from '~/store/utils/patientHelpers'
import { largestFirstSurgeryCountForWeight, maxSurgeryCountForWeight } from '~/utils/blockEvaluation'

type Props = {
    'data-test'?: string
    evaluation: CountRuleEvaluation
}

// this function is ugly, but `evaluation.remaining` will not be able to be null anymore in a next PR, so bear with me
function remainingIsNotNull(evaluation: CountRuleEvaluation): evaluation is CountRuleEvaluation & { remaining: number } {
    return evaluation.remaining !== null
}

function formatRemaining(evaluation: CountRuleEvaluation & { remaining: number }) {
    const resolvedSurgeryTypeGroup = evaluation?.filteredByPatientGroup?.surgeryTypeGroup

    if (resolvedSurgeryTypeGroup) {
        const remainingWeight: number = evaluation.remaining
        const sortedWeights = resolvedSurgeryTypeGroup.metadata.sortedUniqueSurgeryWeights
        const maxCount = maxSurgeryCountForWeight(remainingWeight, sortedWeights)
        const minCount = largestFirstSurgeryCountForWeight(remainingWeight, sortedWeights)
        return maxCount > minCount ? `${minCount}-${maxCount}` : `${maxCount}`
    } else {
        return String(evaluation.remaining)
    }
}

export const AvailabilityByPatientGroup = ({ evaluation, 'data-test': dataTest }: Props) => {
    const surgeryTypeGroupName = formatSurgeryTypeGroup(evaluation.filteredByPatientGroup)
    if (!remainingIsNotNull(evaluation)) {
        return <Tag>{surgeryTypeGroupName}</Tag>
    }

    const patientGroup = evaluation.filteredByPatientGroup
    const ageGroupCode = patientGroup?.ageGroup?.age_group_code
    const formattedRemaining = formatRemaining(evaluation)

    return (
        <Tag data-test={`${dataTest}-available-slots-count'}`}>
            {`Ledig: ${formattedRemaining} ${surgeryTypeGroupName}`} <AgeGroup ageGroupCode={ageGroupCode} />
        </Tag>
    )
}
