import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group'
import { ToggleGroupSingleProps } from '@radix-ui/react-toggle-group'
import { useControllableState } from '@radix-ui/react-use-controllable-state'
import { useReducedMotion } from 'framer-motion'
import { forwardRef } from 'react'

import env from '~/env'

import { SizeOptions } from '../../types/types'

const noEffects = env.VITE_NO_UNSTABLE_EFFECTS

type SegmentedControlRootProps = {
    value?: string
    defaultValue?: string
    onValueChange?: (value: string) => void
    size?: SizeOptions
} & Omit<ToggleGroupSingleProps, 'type'>

const segmentedControlRootStyles =
    'group/root relative isolate inline-grid min-w-max auto-cols-fr grid-flow-col items-stretch rounded-md bg-fill-tertiary-rest p-0.5 text-center align-top overflow-hidden'
const indicatorStyles =
    "pointer-events-none absolute left-0.5 top-[1px] translate-x-[-100%] opacity-0 before:absolute before:inset-[1px] before:rounded-md before:bg-fill-rest before:shadow before:content-[''] peer-data-[state=on]:opacity-100"
const indicatorWidth =
    '[&:nth-child(2)]:w-[calc(100%/1)] [&:nth-child(3)]:w-[calc(100%/2)] [&:nth-child(4)]:w-[calc(100%/3)] [&:nth-child(5)]:w-[calc(100%/4)] [&:nth-child(6)]:w-[calc(100%/5)] [&:nth-child(7)]:w-[calc(100%/6)]'
const indicatorAnimationStyles =
    'ease-in transition-all duration-150 peer-data-[state=on]:peer-[:nth-child(1)]:translate-x-0 peer-data-[state=on]:peer-[:nth-child(2)]:translate-x-[calc(100%-1px)] peer-data-[state=on]:peer-[:nth-child(3)]:translate-x-[calc(200%-2px)] peer-data-[state=on]:peer-[:nth-child(4)]:translate-x-[calc(300%-3px)] peer-data-[state=on]:peer-[:nth-child(5)]:translate-x-[calc(400%-4px)] peer-data-[state=on]:peer-[:nth-child(6)]:translate-x-[calc(500%-5px)] peer-data-[state=on]:peer-[:nth-child(7)]:translate-x-[calc(600%-6px)]'

const indicatorSizeStyles: Record<SizeOptions, string> = {
    small: 'before:h-5',
    medium: 'before:h-7',
    large: 'before:h-9',
}

const HeroSegmentedControlRoot = forwardRef<HTMLDivElement, SegmentedControlRootProps>((props, forwardedRef) => {
    const { children, value: valueProp, defaultValue: defaultValueProp, onValueChange: onValueChangeProp, size = 'medium', ...rootProps } = props
    // avoid animation if user has reduced motion
    const shouldReduceMotion = useReducedMotion()
    const [value, setValue] = useControllableState({
        prop: valueProp,
        onChange: onValueChangeProp,
        defaultProp: defaultValueProp,
    })

    return (
        <ToggleGroupPrimitive.Root
            data-disabled={props.disabled || undefined}
            ref={forwardedRef}
            className={segmentedControlRootStyles}
            onValueChange={value => {
                if (value) {
                    setValue(value)
                }
            }}
            {...rootProps}
            type="single"
            value={value}
            asChild={false}
            disabled={!!props.disabled}
        >
            {children}
            <div
                className={`${indicatorStyles} ${indicatorWidth} ${indicatorSizeStyles[size]} ${shouldReduceMotion || noEffects ? '' : indicatorAnimationStyles}`}
            />
        </ToggleGroupPrimitive.Root>
    )
})

type SegmentedControlItemOwnProps = {
    value: string
    size?: SizeOptions
} & ToggleGroupPrimitive.ToggleGroupItemProps

const labelSizeStyles = {
    small: 'h-5 text-xs ',
    medium: 'h-7 text-sm',
    large: 'h-9 text-sm',
}

const itemStyles = 'group peer flex select-none items-stretch'
// will need to update this color
const seperatorStyles = 'my-[6px] w-[1px] bg-neutral-200 group-first:opacity-0 group-data-[state=on]:opacity-0 mx-[-0.5px] group-data-[sibling=true]:opacity-0 '
const labelStyles = 'flex grow px-4  items-center justify-center rounded-md transition-all hover:bg-black hover:bg-opacity-5'
const labelActiveStyles =
    'whitespace-nowrap z-elevated  rounded-md flex items-center overflow-hidden text-center opacity-0 group-data-[state=on]:opacity-100 transition-all delay-75 font-semibold'
const labelActiveBackgroundStyles = 'bg-fill-rest shadow'
const labelInactiveStyles =
    'whitespace-nowrap opacity-1 absolute transition-opacity group-data-[state=on]:opacity-0 delay-75 text-medium-rest px-4 overflow-hidden flex items-center'

const HeroSegmentedControlItem = forwardRef<HTMLButtonElement, SegmentedControlItemOwnProps>(({ children, size = 'medium', ...props }, forwardedRef) => {
    const shouldReduceMotion = useReducedMotion()

    return (
        <ToggleGroupPrimitive.Item
            ref={forwardedRef}
            className={itemStyles}
            {...props}
            disabled={false}
            asChild={false}
            onClick={event => {
                event.currentTarget.parentElement?.querySelectorAll('button').forEach((el: HTMLElement) => {
                    el.setAttribute('data-sibling', 'false')
                    // @ts-expect-error this prop does exist
                    event.currentTarget.nextSibling?.setAttribute('data-sibling', 'true')
                })
            }}
        >
            <span className={seperatorStyles} />
            <span className={labelStyles}>
                <span className={`${labelActiveStyles} ${labelSizeStyles[size]} ${shouldReduceMotion || noEffects ? labelActiveBackgroundStyles : ''}`}>
                    {children}
                </span>
                <span className={`${labelInactiveStyles} ${labelSizeStyles[size]} `} aria-hidden>
                    {children}
                </span>
            </span>
        </ToggleGroupPrimitive.Item>
    )
})

type SegmentedControlProps = {
    controlOptions: {
        value: string
        label: string
    }[]
} & SegmentedControlRootProps

/**
 *
 * A reusable segmented control component designed for use within the Hero section of an application.
 * This component allows users to select a single option from multiple choices.
 *
 * @param {SegmentedControlProps} props - The props for the HeroSegmentedControl component.
 *
 * @param {React.Ref<HTMLDivElement>} forwardedRef - A forwarded ref for the root container element.
 *
 * Props:
 *
 * @property {string} size - Determines the size of the segmented control (e.g., small, medium, large).
 *
 * @property {Array<{ value: string; label: string }>} controlOptions - An array of options for the segmented control,
 *   where each option includes a `value` (unique identifier) and a `label` (display text).
 *
 * @property {...any} rootProps - Additional props passed to the root container of the component.
 *
 * @example
 * ```
 * <HeroSegmentedControl
 * defaultValue="option1"
 *  onValueChange={(value) => console.info(value)}
 *   size="medium"
 *   controlOptions={[
 *     { value: 'option1', label: 'Option 1' },
 *     { value: 'option2', label: 'Option 2' },
 *   ]}
 * />
 * ```
 *
 * This component is wrapped with `forwardRef` to allow ref forwarding to the root HTML element.
 */
export const HeroSegmentedControl = forwardRef<HTMLDivElement, SegmentedControlProps>((props, forwardedRef) => {
    const { size, controlOptions, ...rootProps } = props

    return (
        <HeroSegmentedControlRoot ref={forwardedRef} size={size} {...rootProps}>
            {controlOptions.map(({ value, label }) => (
                <HeroSegmentedControlItem size={size} value={value} key={label}>
                    {label}
                </HeroSegmentedControlItem>
            ))}
        </HeroSegmentedControlRoot>
    )
})

HeroSegmentedControl.displayName = 'HeroSegmentedControl' // Necessary for better debugging
