import { Dayjs } from 'dayjs'
import { useMemo, useState } from 'react'

import { Show } from '~/components'
import { HeroSegmentedControl } from '~/hero-ui'
import { EvaluationOutput, RequestResult } from '~/hooks/tanstack-query/optimus/useEvaluationQuery'
import { format } from '~/utils/extendedDayjs'

import { ViewLayout } from '../shared/ViewLayout'
import { WishInfo } from './WishInfo'

type Props = {
    evaluation: EvaluationOutput
    departmentName: string
    date: Dayjs
}

const filters = ['all', 'fulfilled', 'not_fulfilled'] as const
type Filter = (typeof filters)[number]

function isFilter(value: string): value is Filter {
    return filters.includes(value)
}

function isHidden(wish: RequestResult) {
    if (wish.activity_request?.hidden) return true
    if (wish.resource_request?.hidden) return true
    return false
}

export const WishesView = ({ evaluation, departmentName, date }: Props) => {
    const [evaluationView, setEvaluationView] = useState<Filter>('all')

    const filteredWishes = useMemo(
        () =>
            evaluationView === 'all'
                ? evaluation.filter(wish => !isHidden(wish))
                : evaluation.filter(wish => wish.fulfilled === (evaluationView === 'fulfilled') && !isHidden(wish)),
        [evaluation, evaluationView]
    )

    function onValueChange(value: string) {
        if (isFilter(value)) {
            setEvaluationView(value)
        }
    }

    return (
        <ViewLayout title={`Ønsker ${departmentName} ${format(date, 'MMMM YYYY')}`}>
            <div className="flex h-full flex-col gap-y-3 overflow-auto">
                <Show condition={filteredWishes.length !== 0} fallback="Ønsker for denne måneden er ikke satt opp.">
                    <div className="w-full py-1">
                        <HeroSegmentedControl
                            controlOptions={[
                                { label: 'Alle', value: 'all' },
                                { label: 'Ikke innfridd', value: 'not_fulfilled' },
                                { label: 'Innfridd', value: 'fulfilled' },
                            ]}
                            value={evaluationView}
                            onValueChange={onValueChange}
                        />
                    </div>

                    <div className="flex flex-col gap-y-2 overflow-auto">
                        {filteredWishes.map(wish => (
                            <WishInfo key={wish.activity_request?.name ?? wish.resource_request?.name} wish={wish} />
                        ))}
                    </div>
                </Show>
            </div>
        </ViewLayout>
    )
}
