import { Dayjs } from 'dayjs'

import { selectGetOrderedPractitionerScheduleStatuses, selectGetPractitionerScheduleLocations, selectGetPractitionerSchedules } from '~/store/selectors'
import { useStore } from '~/store/store'

export const useGetSchedules = () => {
    const getPractitionerSchedules = useStore(selectGetPractitionerSchedules)
    const getOrderedPractitionerScheduleStatuses = useStore(selectGetOrderedPractitionerScheduleStatuses)
    const getPractitionerScheduleLocations = useStore(selectGetPractitionerScheduleLocations)

    function getOrderedStatuses(date: Dayjs, practitionerId: number) {
        const schedule = getPractitionerSchedules.byDateAndPractitionerId(date, practitionerId)
        if (!schedule) return []
        return getOrderedPractitionerScheduleStatuses.byPractitionerScheduleId(schedule.id)
    }

    function getLocationSchedules(date: Dayjs, practitionerId: number) {
        const schedule = getPractitionerSchedules.byDateAndPractitionerId(date, practitionerId)
        if (!schedule) return []
        return getPractitionerScheduleLocations.byPractitionerScheduleId(schedule.id)
    }

    return { getOrderedStatuses, getLocationSchedules }
}
