import { useCallback, useEffect } from 'react'

import { useStore } from '~/store/store'

import { FormattedWaitingListItem } from '../shared/columns'

function useHandleUpDownArrowsKeys(handleArrowUpKey: () => void, handleArrowDownKey: () => void) {
    useEffect(() => {
        function handle(event: KeyboardEvent) {
            if (event.key === 'ArrowUp') {
                event.preventDefault()
                event.stopPropagation()

                handleArrowUpKey()
            }
            if (event.key === 'ArrowDown') {
                event.preventDefault()
                event.stopPropagation()

                handleArrowDownKey()
            }
        }

        window.addEventListener('keydown', handle)
        return () => window.removeEventListener('keydown', handle)
    }, [handleArrowUpKey, handleArrowDownKey])
}

function scrollIntoView(
    item: FormattedWaitingListItem,
    tableRef: React.RefObject<HTMLDivElement>,
    paginationRef: React.RefObject<HTMLDivElement>,
    headerRef: React.RefObject<HTMLTableSectionElement>
) {
    if (item.BookingId && tableRef.current && paginationRef.current && headerRef.current) {
        const rowRef = document.getElementById(item.BookingId)
        if (rowRef) {
            const rowRect = rowRef.getBoundingClientRect()
            const tableRect = tableRef.current.getBoundingClientRect()
            const paginationRect = paginationRef.current.getBoundingClientRect()
            const headerBottom = tableRect.top + headerRef.current.clientHeight

            if (headerBottom > rowRect.top) {
                tableRef.current.scrollTop += rowRect.top - headerBottom
            } else if (tableRect.bottom - paginationRect.height < rowRect.bottom) {
                tableRef.current.scrollTop += rowRect.bottom - paginationRect.top
            }
        }
    }
}

export function useKeyboardNavigation(
    pageItems: FormattedWaitingListItem[],
    tableRef: React.RefObject<HTMLDivElement>,
    paginationRef: React.RefObject<HTMLDivElement>,
    headerRef: React.RefObject<HTMLTableSectionElement>
) {
    const drawerProps = useStore(state => state.waitingList.drawerProps)
    const setDrawerProps = useStore(state => state.waitingList.actions.setDrawerProps)
    const setNavigatedByKeyboard = useStore(state => state.waitingList.actions.setNavigatedByKeyboard)

    const handleKey = useCallback(
        (direction: 'up' | 'down') => {
            if (pageItems.length === 0) {
                return
            }

            setNavigatedByKeyboard(true)

            const selectedIndex = pageItems.findIndex(item => item.BookingId === drawerProps?.item.BookingId)

            let item: FormattedWaitingListItem | undefined = undefined
            const lastIndex = pageItems.length - 1
            if (selectedIndex === -1) {
                item = pageItems[direction === 'up' ? lastIndex : 0]!
            } else if (selectedIndex !== (direction === 'up' ? 0 : lastIndex)) {
                item = pageItems[selectedIndex + (direction === 'up' ? -1 : 1)]
            }

            if (item !== undefined) {
                setDrawerProps({ item, isDrawerOpen: true })
                scrollIntoView(item, tableRef, paginationRef, headerRef)
            }
        },
        [drawerProps?.item.BookingId, headerRef, pageItems, paginationRef, setDrawerProps, setNavigatedByKeyboard, tableRef]
    )

    const handleArrowUpKey = useCallback(() => handleKey('up'), [handleKey])
    const handleArrowDownKey = useCallback(() => handleKey('down'), [handleKey])
    useHandleUpDownArrowsKeys(handleArrowUpKey, handleArrowDownKey)
}
