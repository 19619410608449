import clsx from 'clsx'
import { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'

import { HeroPopover } from '~/hero-ui'
import { format, getToday, isBefore, isSameDate } from '~/utils/extendedDayjs'

import { DatePickerBottom } from './helpers/DatePickerBottom'
import { DatePickerInput } from './helpers/DatePickerInput'
import { MonthSwitch } from './helpers/DateSwitches'
import { DefaultDatePickerProps } from './helpers/DefaultDatePickerProps'
import { GridMonth } from './helpers/GridMonth'

type DayProps = {
    day: Dayjs
    value?: Dayjs
    today?: Dayjs
    onClickDay: (day: Dayjs) => void
}

const Day = ({ day, value, today, onClickDay }: DayProps) => (
    <div
        onClick={() => onClickDay(day)}
        className={clsx('mx-1 rounded p-1', {
            'pointer-events-none text-gray-400': isBefore(day, today, 'day'),
            'pointer-events-none bg-diBlue-500 font-medium text-white': isSameDate(day, value),
            'cursor-pointer hover:bg-gray-100': !isSameDate(day, value),
        })}
    >
        {format(day, 'D')}
    </div>
)

export const DayPicker = ({ value, onChange, size, placement, allowClear = false }: DefaultDatePickerProps) => {
    const today = getToday()
    const [isOpen, setIsOpen] = useState(false)
    const [relativeDate, setRelativeDate] = useState(today)

    useEffect(() => {
        if (value) setRelativeDate(value)
    }, [value])

    function closeModal() {
        setIsOpen(false)
        setRelativeDate(value ?? today)
    }

    function setToToday(today: Dayjs) {
        setRelativeDate(today)
        onChange(today)
        closeModal()
    }

    function clearDate() {
        onChange(undefined)
        closeModal()
    }

    function handleClickDay(day: Dayjs) {
        onChange(day)
        closeModal()
    }

    return (
        <HeroPopover
            isOpen={isOpen}
            noPadding
            onOpenChange={open => (open === false ? closeModal() : setIsOpen(true))}
            side={placement}
            popoverContent={
                <>
                    <MonthSwitch monthBack={setRelativeDate} month={relativeDate} monthForward={setRelativeDate} />
                    <hr className="my-1 h-px bg-gray-200" />
                    <GridMonth includeWeekNumber relativeDate={relativeDate}>
                        {week => (
                            <div key={week.weekNumber} className={'grid grid-cols-6 text-center'}>
                                <span className="flex items-center justify-center text-gray-400">{week.weekNumber}</span>

                                {week.weekdays.map((day, i) => (
                                    <Day key={i} day={day} value={value} today={today} onClickDay={handleClickDay} />
                                ))}
                            </div>
                        )}
                    </GridMonth>
                    <hr className="my-1 h-px bg-gray-200" />
                    <DatePickerBottom allowClear={allowClear} clearDate={clearDate} setDateToToday={setToToday} />
                </>
            }
        >
            <DatePickerInput size={size} formattedDate={value && format(value, 'DD.MM.YYYY')} width="full" data-test="day-picker-input" />
        </HeroPopover>
    )
}
