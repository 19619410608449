import { HeroBadge, HeroSkeletonLoader, HeroTooltip } from '~/hero-ui'
import { useDurationQuery } from '~/hooks/tanstack-query/optimus/useDurationQuery'
import { ClockHour5Icon } from '~/icons'
import { BlockSchedule } from '~/store/selectors'
import { formatDurationFromIso } from '~/utils/extendedDayjs'

type Props = {
    blockSchedule: BlockSchedule | null
}

export const Duration = ({ blockSchedule }: Props) => {
    // 'can' refers to the current limitation of Optimus API to only provide duration data for block schedules
    // with the rule_definition_id of 'max_count_of_surgeries_per_patient_group'
    const canGenerateData = !!blockSchedule?.id && blockSchedule.rule_instance?.rule_definition_id === 'max_count_of_surgeries_per_patient_group'

    const { data, isPending, isLoading, error } = useDurationQuery({
        request: { blockScheduleId: blockSchedule?.id ?? 0, updatedAt: blockSchedule?.updated_at ?? '' },
        isEnabled: canGenerateData,
    })

    return (
        <>
            {isPending && <div data-test="duration-chart-isloading" hidden />}
            <HeroTooltip side="top" tooltipContent={<p>{data?.description ?? 'loading'}</p>} className="w-48">
                {!error && (
                    <div className="mb-2">
                        {isLoading ? (
                            <div className="h-6 w-14">
                                <HeroSkeletonLoader />
                            </div>
                        ) : (
                            <HeroBadge
                                variant="outlined"
                                icon={<ClockHour5Icon />}
                                data-test="duration-chart-loaded"
                                text={data && formatDurationFromIso(data?.duration)}
                            />
                        )}
                    </div>
                )}
            </HeroTooltip>
        </>
    )
}
