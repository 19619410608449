import { HeroDrawer } from '~/hero-ui'
import { useStore } from '~/store/store'

import { Content } from './Content'
import { Header } from './Header'

export const Drawer = () => {
    const bookingId = useStore(state => state.waitingList.drawerProps?.item.BookingId)

    return (
        <HeroDrawer key={bookingId} dataTest="Waitinglist Sidebar">
            <div className="flex">
                <div className="grow">
                    <Header />
                </div>
            </div>

            <div className="flex flex-col gap-4 overflow-auto">
                <Content />
            </div>
        </HeroDrawer>
    )
}
