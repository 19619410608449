import { useLocationNotes } from '~/hooks/useLocationNotes'
import { trackEvent } from '~/utils/analytics'

import { Note } from '../../shared'
import { DateLocationCell } from '../utils'

type Props = {
    selectedCell: DateLocationCell
}

export function LocationNote({ selectedCell }: Props) {
    const { isLoading, isSuccess, updateNote, deleteNote, note, setNote } = useLocationNotes({ location: selectedCell })

    const handleUpdateNote = async () => {
        await updateNote()
        void trackEvent('SS_BUTTON_SAVE_LOCATION_COMMENT__CLICK')
    }

    const handleDeleteNote = async () => {
        await deleteNote()
        void trackEvent('SS_BUTTON_REMOVE_LOCATION_COMMENT__CLICK')
    }

    return <Note note={note} setNote={setNote} updateNote={handleUpdateNote} deleteNote={handleDeleteNote} isLoading={isLoading} isSuccess={isSuccess} />
}
