import { ReactNode } from 'react'

type Props = {
    label: ReactNode
    children: ReactNode
}

export function Fieldset({ label, children }: Props) {
    return (
        <fieldset className="flex flex-col gap-2">
            <label className="text-base text-gray-800">{label}</label>
            {children}
        </fieldset>
    )
}

export function Field({ label, children }: Props) {
    return (
        <div className="flex flex-col gap-1">
            <header className="cursor-default overflow-hidden overflow-ellipsis text-nowrap text-xs text-gray-800">{label}</header>
            {children}
        </div>
    )
}
