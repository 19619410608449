import { motion } from 'framer-motion'
import { useState } from 'react'

import { HeroBadge } from '~/hero-ui'
import { RequestResult } from '~/hooks/tanstack-query/optimus/useEvaluationQuery'
import { CalendarIcon, ChevronDownIcon } from '~/icons'
import { day, format } from '~/utils/extendedDayjs'

import { RequestParserTooltip } from './RequestParserTooltip'

type Props = {
    wish: RequestResult
}

const getColor = (fulfilled?: boolean) => (fulfilled ? 'bg-fill-success-subtle text-success-rest' : 'bg-fill-caution-subtle text-caution-rest')

export const WishInfo = ({ wish }: Props) => {
    const [show, setShow] = useState(false)

    return (
        <div className="flex flex-col rounded-lg border border-gray-200 bg-white">
            <div className="flex w-full cursor-pointer items-center gap-x-2 px-4 py-2" onClick={() => setShow(prev => !prev)}>
                <p className="flex-1">{wish.activity_request?.name ?? wish.resource_request?.name}</p>
                <p className={`rounded-full px-2.5 py-1 text-xs ${getColor(wish.fulfilled)}`}>{wish.events?.length}</p>
                <ChevronDownIcon className={`transform transition-transform ${show ? 'rotate-180' : ''}`} />
            </div>

            <motion.div initial={false} className="overflow-hidden" transition={{ duration: 0.2 }} animate={{ height: show ? 'auto' : 0 }}>
                <hr className="-mx-4 mb-2" />
                <div className="flex flex-col gap-y-2 px-4 pb-2">
                    <RequestParserTooltip wish={wish.resource_request ?? wish.activity_request ?? null} />
                    {wish.events?.map(event => (
                        <div key={event.event_date + event.room} className="flex flex-wrap items-center gap-2">
                            <CalendarIcon className="min-w-max text-gray-400" />
                            {event.room && <HeroBadge text={event.room} />}
                            {event.event_date && <HeroBadge color="green" text={format(day(event.event_date), 'ddd DD MMMM')} />}
                        </div>
                    ))}
                </div>
            </motion.div>
        </div>
    )
}
