import clsx from 'clsx'
import { ReactNode } from 'react'

import { Id } from './utils'

export type HeaderProps<HeaderRows extends { id: Id }> = {
    label?: string

    row: HeaderRows[]
    rowRender: (column: HeaderRows) => ReactNode
    rowClassName?: (column: HeaderRows) => string
}

export function Header<T extends { id: Id }>({ label, row, rowRender, rowClassName }: HeaderProps<T>) {
    return (
        <tr>
            <th className="last:border-r-none sticky left-0 top-0 border-b border-r border-inherit bg-fill-rest first:rounded-tl-md">
                <span className="flex-start flex bg-fill-rest font-medium">{label}</span>
            </th>
            {row.map(cell => (
                <th
                    key={cell.id}
                    data-column={cell.id}
                    className={clsx(
                        rowClassName?.(cell),
                        'sticky left-0 top-0 border-b border-r border-inherit bg-fill-rest py-2.5 font-medium last:rounded-tr-md last:border-r-0'
                    )}
                >
                    {rowRender(cell)}
                </th>
            ))}
        </tr>
    )
}
