import {
    hasAvailability,
    isCountBasedRuleEvaluation,
    isDurationRuleEvaluation,
    isFilled,
    isOverbooked,
    OccupancyStatus,
    OccupancyStatusType,
    RuleEvaluation,
} from '~/store/selectors'
import { isNotNullish } from '~/utils/guards'

/**
 * summarizeCapacityEvaluations returns the occupancy status of a block based on the occupancy status of its rules.
 * @param evaluations a list of capacity evaluations
 * @returns the status of the block
 * @see https://deepinsight.atlassian.net/wiki/spaces/CapPlan/pages/2638151709/Planning+rules#Evaluation-of-a-block
 */
export function summarizeCapacityEvaluations(evaluations: RuleEvaluation[]): OccupancyStatusType {
    if (evaluations.length === 0) {
        // there are no rules in this block
        return OccupancyStatus.Filled
    }

    const countBasedEvaluations = evaluations.filter(isCountBasedRuleEvaluation)
    const durationBasedEvaluations = evaluations.filter(isDurationRuleEvaluation)
    if (durationBasedEvaluations.length > 1) {
        console.error('Unexpected multiple duration-based rules.')
    }

    const durationBasedEvaluation = durationBasedEvaluations.length > 0 ? durationBasedEvaluations[0] : undefined
    if (isNotNullish(durationBasedEvaluation)) {
        if (countBasedEvaluations.length === 0) {
            return durationBasedEvaluation.status
        }

        switch (durationBasedEvaluation.status) {
            case OccupancyStatus.Available:
                if (countBasedEvaluations.some(hasAvailability)) {
                    return OccupancyStatus.Available
                }
                break

            case OccupancyStatus.Filled:
                return OccupancyStatus.Filled

            case OccupancyStatus.Overbooked:
                return OccupancyStatus.Overbooked
        }
    }

    // the entire block is filled if all count-based rules evaluate as filled
    if (countBasedEvaluations.every(isFilled)) {
        return OccupancyStatus.Filled
    }

    // the entire block has availability if at least one of the count-based rules evaluates as empty/available
    if (countBasedEvaluations.some(hasAvailability)) {
        return OccupancyStatus.Available
    }

    // the entire block is overbooked if at least one of the count-based rules evaluates as overbooked
    if (countBasedEvaluations.some(isOverbooked)) {
        return OccupancyStatus.Overbooked
    }

    // This code should be unreachable, i.e. some error has occurred.
    // At least it's certain that there is no available slot, nor overbooked one. Whatever happened, there is nothing to book, so it's filled:
    return OccupancyStatus.Filled
}
