import { Dayjs } from 'dayjs'

import { selectActiveFilteredLocations, selectGetBlockSchedules, selectSurgeryTypeGroup } from '~/store/selectors'
import { useStore } from '~/store/store'

import { calculatePatientCounts, formatTotalCounts } from '../../shared/table/DayColumn/utils'

type Props = { date: Dayjs }

export const PatientSumCell = ({ date }: Props) => {
    const getSurgeryTypeGroup = useStore(selectSurgeryTypeGroup)
    const getBlockSchedules = useStore(selectGetBlockSchedules)
    const activeFilteredLocationIds = useStore(selectActiveFilteredLocations).map(location => location.id)

    const blockSchedules = getBlockSchedules.byDate(date)
    const patientCounts = calculatePatientCounts(blockSchedules, activeFilteredLocationIds, getSurgeryTypeGroup)

    return <div className="whitespace-nowrap text-left text-xs">{formatTotalCounts(patientCounts)}</div>
}
