import { BlockSchedule, GetResolvedSurgeryTypeGroup, GetSelectRuleDefinitionOptions } from '~/store/selectors'
import { RuleInstanceCreate } from '~/store/utils/blockEvaluation'

import { rulesResolver } from '../../blockEvaluation'
import { day, getToday } from '../../extendedDayjs'
import { BlockUpdatedData } from '../analytics'

export function getUpdateBlockScheduleEventData(
    blockSchedule: BlockSchedule,
    ruleInstance: RuleInstanceCreate,
    getSurgeryTypeGroup: GetResolvedSurgeryTypeGroup,
    getRuleDefinitions: GetSelectRuleDefinitionOptions
): BlockUpdatedData {
    // Previous rule instances and type is sourced from the rule definition of the block
    const previous_rule_type = getRuleDefinitions.find(rule => rule.value === blockSchedule.rule_instance?.rule_definition_id)?.label ?? ''
    const previous_rule_instances = rulesResolver({ rules: blockSchedule?.rule_instance, getSurgeryTypeGroup })

    // Updated rule type is sourced from the updated rule instance
    const updated_rule_type = getRuleDefinitions.find(rule => rule.value === ruleInstance?.rule_definition_id)?.label ?? ''
    const updated_rule_instances = rulesResolver({ rules: ruleInstance, getSurgeryTypeGroup })

    return {
        date: blockSchedule?.date ?? '',
        room_code: blockSchedule.location?.room_code ?? '',
        number_of_days_before_start: day(blockSchedule?.scheduled_date).diff(getToday(), 'day'),
        prevous_block: {
            rule_type: previous_rule_type,
            rule_instances: previous_rule_instances,
        },
        updated_block: {
            rule_type: updated_rule_type,
            rule_instances: updated_rule_instances,
        },
    }
}
