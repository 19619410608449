import * as SliderPrimitive from '@radix-ui/react-slider'
import { forwardRef } from 'react'

export const HeroSlider = forwardRef<HTMLSpanElement, SliderPrimitive.SliderProps>((props, forwardedRef) => {
    const value = props.value || props.defaultValue

    return (
        <SliderPrimitive.Slider {...props} ref={forwardedRef} className="relative flex h-5 w-[200px] touch-none select-none items-center">
            <SliderPrimitive.Track className="relative h-1 grow rounded-full bg-gray-400">
                <SliderPrimitive.Range className="absolute h-full rounded-full bg-indigo-600" />
            </SliderPrimitive.Track>
            {value?.map((_, i) => (
                <SliderPrimitive.Thumb
                    key={i}
                    className="block size-5 rounded-full bg-indigo-600 shadow-md hover:cursor-pointer hover:bg-indigo-400 focus:shadow-lg focus:outline-none"
                />
            ))}
        </SliderPrimitive.Slider>
    )
})
