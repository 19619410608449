import { useState } from 'react'

import { DateLocationCell } from '~/app/pages/SurgeonScheduler/LocationTable/utils'
import { createLocationSchedule, updateLocationSchedule } from '~/store/di-entity.api'
import { LocationSchedule, selectGetLocationSchedules } from '~/store/selectors'
import { useStore } from '~/store/store'
import { getOnlyDate } from '~/utils/extendedDayjs'
import { getNonEmptyString } from '~/utils/utils'

type Props = { location: DateLocationCell }

const upsertNote = (selectedCell: DateLocationCell, locationSchedule?: LocationSchedule | null, newComment?: string) => {
    if (locationSchedule) {
        if (locationSchedule.comment === newComment) return

        return updateLocationSchedule(locationSchedule.id, {
            ...locationSchedule,
            comment: newComment,
        })
    }
    return createLocationSchedule({
        comment: newComment,
        location_id: selectedCell.locationId,
        scheduled_date: getOnlyDate(selectedCell.date),
    })
}

export const useLocationNotes = ({ location }: Props) => {
    const locationSchedule = useStore(selectGetLocationSchedules).byCell(location)
    const [note, setNote] = useState(locationSchedule?.comment ?? '')

    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    // provides and override to allow explicit saving
    const updateNote = async (noteToSave?: string) => {
        const newNote = getNonEmptyString(noteToSave ?? note)

        setIsLoading(true)
        setIsSuccess(false)

        try {
            await upsertNote(location, locationSchedule, newNote)
            setNote(newNote ?? '')
            setIsLoading(false)
            setIsSuccess(true)
        } catch (error) {
            console.error(error)
            setIsLoading(false)
            return
        }
    }

    const deleteNote = async () => {
        if (!locationSchedule) return
        const prompt = window.confirm('Er du sikker på at du vil fjerne notaten?')

        if (!prompt) return
        setIsLoading(true)
        setIsSuccess(false)

        try {
            setNote('')

            await updateLocationSchedule(locationSchedule.id, {
                ...locationSchedule,
                comment: undefined,
            })
            setIsLoading(false)
            setIsSuccess(true)
        } catch (error) {
            console.error(error)
            setIsLoading(false)
            return
        }
    }

    return { isLoading, isSuccess, updateNote, deleteNote, note, setNote }
}
