import dayjs from 'dayjs'
import { NorwegianId } from 'norwegian-national-id-validator'

import { DipsSchemas } from '~/clients/dips-client'
import { day, getToday } from '~/utils/extendedDayjs'
import { isNotNullish, isNullish } from '~/utils/guards'

type Patient = DipsSchemas['Patient']

export function getBirthDate(patient: Patient | undefined): dayjs.Dayjs | undefined {
    if (isNullish(patient)) {
        return undefined
    }
    if (isNotNullish(patient.birthDate)) {
        return day(patient.birthDate)
    }
    if (isNotNullish(patient.nationalId)) {
        const fromNorwegianId = NorwegianId(patient?.nationalId).birthDate()
        return isNotNullish(fromNorwegianId) ? day(fromNorwegianId) : undefined
    }
    return undefined
}

export function getAge(patient: Patient | undefined): number | undefined {
    const birthDate = getBirthDate(patient)
    if (isNotNullish(birthDate)) {
        return getToday().diff(birthDate, 'year')
    }
    return undefined
}

export const formatNationalId = (nationalId: string) => {
    return `${nationalId.slice(0, 6)} ${nationalId.slice(6)}`
}

export function formatNationalIdWithLastName(patient?: Patient | undefined) {
    const formattedNationalId = `${formatNationalId(patient?.nationalId ?? '')}`
    const lastName = patient?.lastName ?? ''

    const values = [formattedNationalId, lastName]

    if (values.every(value => value === '')) return '??'

    return values.filter(Boolean).join(', ')
}

export function formatNationalIdWithFirstAndLastName(patient?: Patient | undefined) {
    const formattedNationalId = `${formatNationalId(patient?.nationalId ?? '')}`
    const firstName = patient?.firstName ?? ''
    const lastName = patient?.lastName ?? ''

    const values = [formattedNationalId, firstName, lastName]

    if (values.every(value => value === '')) return '??'

    return values.filter(Boolean).join(', ')
}

export function formatLastNameAndBirthYear(patient?: Patient | undefined) {
    const lastName = patient?.lastName ?? ''
    const firstName = patient?.firstName ?? ''
    const birthYear = getBirthDate(patient)?.year()

    const values = [lastName, firstName]

    if (values.every(value => value === '')) return '??'

    return `${lastName}, ${firstName?.charAt(0)} (f. ${birthYear})`
}

export function formatFullNameAndMunicipality(patient?: Patient | undefined) {
    const fullName = `${patient?.firstName} ${patient?.lastName}`
    const municipality = patient?.municipality ?? ''

    const values = [fullName, municipality]

    if (values.every(value => value === '')) return '??'

    return values.filter(Boolean).join(', ')
}

export function formatAgeAndNationalId(patient?: Patient | undefined) {
    const age = getAge(patient)
    const formattedAge = age ? `${age} år` : ''
    const nationalId = formatNationalId(patient?.nationalId ?? '')

    return [formattedAge, nationalId].filter(Boolean).join(', ') || '??'
}

export function formatNationalIdCopyText(patient?: Patient | undefined) {
    const nationalId = formatNationalId(patient?.nationalId ?? '')
    const hasNationalId = nationalId !== ''
    const nationalIdCopyText = nationalId.replace(/\s+/g, '')

    return { hasNationalId, nationalIdCopyText }
}
