import { MaxCount, MaxDuration, MaxWeight, PatientGroupContainer } from '~/clients/derived-api-types'
import {
    BlockRules,
    GetResolvedSurgeryTypeGroup,
    isMaxCountBlockRules,
    isMaxDurationBlockRules,
    isMaxWeightBlockRules,
    ResolvedSurgeryTypeGroup,
} from '~/store/selectors'
import { formatDurationFromIso } from '~/utils/extendedDayjs'

import { ResolvedConstraintObject } from './helpers/constraintResolver'
import { largestFirstSurgeryCountForWeight, maxSurgeryCountForWeight } from './helpers/weightMapper'

type RulesResolverArgs = {
    getSurgeryTypeGroup: GetResolvedSurgeryTypeGroup
    rules?: BlockRules
}

export function rulesResolver({ getSurgeryTypeGroup, rules }: RulesResolverArgs): ResolvedConstraintObject[] {
    if (!rules) return []

    function getPatientGroupInfo(constraint: PatientGroupContainer): ResolvedSurgeryTypeGroup | null {
        return getSurgeryTypeGroup.byCode(constraint.patient_group.patient_group_id)
    }

    function resolveMaxDuration(constraints: MaxDuration): ResolvedConstraintObject {
        return {
            value: formatDurationFromIso(constraints.max_duration),
            surgeryTypeGroupLabel: '',
            groupLevel: 'none',
            specialities: [],
            minCount: 0,
            maxCount: 0,
        }
    }

    function resolveMaxCount(constraint: MaxCount): ResolvedConstraintObject {
        const surgeryTypeGroup = getPatientGroupInfo(constraint)
        const age_restrictions = constraint.patient_group.age_group
        return {
            value: String(constraint.max_count),
            surgeryTypeGroupLabel: surgeryTypeGroup?.display_name || '',
            ageCategory: age_restrictions,
            groupLevel: surgeryTypeGroup?.group_type || 'none',
            specialities: surgeryTypeGroup?.specialities.map(s => s.name) || [],
            minCount: constraint.max_count,
            maxCount: constraint.max_count,
        }
    }

    function resolveMaxWeight(constraint: MaxWeight): ResolvedConstraintObject {
        const surgeryTypeGroup = getPatientGroupInfo(constraint)
        const maxCount = maxSurgeryCountForWeight(constraint.max_weight, surgeryTypeGroup?.metadata.sortedUniqueSurgeryWeights ?? [])
        const minCount = largestFirstSurgeryCountForWeight(constraint.max_weight, surgeryTypeGroup?.metadata.sortedUniqueSurgeryWeights ?? [])
        return {
            value: maxCount > minCount ? `${minCount}-${maxCount}` : `${maxCount}`,
            surgeryTypeGroupLabel: surgeryTypeGroup?.display_name || '',
            ageCategory: constraint.patient_group.age_group,
            groupLevel: surgeryTypeGroup?.group_type || 'none',
            specialities: surgeryTypeGroup?.specialities.map(s => s.name) || [],
            minCount: minCount,
            maxCount: maxCount,
        }
    }

    function resolvePatientGroup(constraint: PatientGroupContainer): ResolvedConstraintObject {
        const surgeryTypeGroup = getPatientGroupInfo(constraint)
        return {
            value: '',
            surgeryTypeGroupLabel: surgeryTypeGroup?.display_name || '',
            ageCategory: constraint.patient_group.age_group || 'unspecified',
            groupLevel: surgeryTypeGroup?.group_type || 'none',
            specialities: surgeryTypeGroup?.specialities.map(s => s.name) || [],
            minCount: 0,
            maxCount: 0,
        }
    }

    if (isMaxCountBlockRules(rules)) {
        return rules.parameters.constraints.map(resolveMaxCount)
    }

    if (isMaxWeightBlockRules(rules)) {
        return rules.parameters.constraints.map(resolveMaxWeight)
    }

    if (isMaxDurationBlockRules(rules)) {
        const constraints = rules.parameters.constraints
        // max duration is always resolved first
        const values = [resolveMaxDuration(constraints)]
        values.push(...constraints.patient_groups.map(resolvePatientGroup))
        return values
    }
    return []
}
