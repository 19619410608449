import { ReactNode } from 'react'

type Props = {
    title: string
    children: ReactNode
}

export const ViewLayout = ({ children, title }: Props) => (
    <>
        <h2 className="-ml-4 -mr-2 px-4 py-2 text-lg font-semibold text-slate-900">{title}</h2>
        <div className="-ml-4 -mr-2 h-full overflow-auto px-4 pb-1">
            <div className="flex h-full flex-col gap-y-3 overflow-auto">{children}</div>
        </div>
    </>
)
