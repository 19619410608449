import { Merge, SetOptional, SimplifyDeep } from 'type-fest'

import { DipsSchemas } from '~/clients/dips-client'

export type UnifiedScheduledSurgery = SetOptional<
    Merge<SimplifyDeep<DipsSchemas['FullScheduledSurgeryResult']>, SimplifyDeep<DipsSchemas['FullScheduledSurgeryResultV2']>>,
    'letterStatus'
>
export type UnifiedScheduledSurgeryWithId = SimplifyDeep<{ id: string } & Omit<UnifiedScheduledSurgery, 'id'>>

export function isFullScheduledSurgeryWithId(entity: UnifiedScheduledSurgery): entity is UnifiedScheduledSurgeryWithId {
    return typeof entity.id === 'string'
}

function unifyFullScheduledSurgery(entity: UnifiedScheduledSurgeryWithId) {
    const newEntity = {
        plannedTheatreInTime: entity.plannedTheatreInTime ?? entity.plannedStartTime, // v2
        ...entity,
    } satisfies Omit<UnifiedScheduledSurgeryWithId, 'plannedStartTime'>

    delete newEntity.plannedStartTime

    return newEntity
}

export function transformScheduledSurgeries(
    surgeries: (DipsSchemas['FullScheduledSurgeryResult'] | DipsSchemas['FullScheduledSurgeryResultV2'])[]
): UnifiedScheduledSurgeryWithId[] {
    const validEntities = surgeries.filter(isFullScheduledSurgeryWithId)

    if (validEntities.length !== surgeries.length) {
        console.warn('Some full scheduled surgeries are missing ids')
    }

    const compatibleEntities = validEntities.map(unifyFullScheduledSurgery)

    return compatibleEntities
}
