import { immer } from 'zustand/middleware/immer'

import { Slice } from '../store'

type State = {
    isDrawerOpen: boolean
    showLocations: boolean
}

const initialState: State = {
    isDrawerOpen: true,
    showLocations: true,
}

type Actions = {
    actions: {
        set: (args: Partial<State>) => void
        toggleLocations: () => void
        openDrawer: () => void
        closeDrawer: () => void
        toggleDrawer: () => void
    }
}

export type SurgeonSchedulerSlice = {
    surgeonScheduler: State & Actions
}

export const createSurgeonSchedulerSlice: Slice<SurgeonSchedulerSlice> = immer(set => ({
    surgeonScheduler: {
        ...initialState,
        actions: {
            set: args => {
                set(state => {
                    Object.assign(state.surgeonScheduler, args)
                })
            },
            toggleDrawer: () => {
                set(state => {
                    state.surgeonScheduler.isDrawerOpen = !state.surgeonScheduler.isDrawerOpen
                })
            },
            openDrawer: () => {
                set(state => {
                    state.surgeonScheduler.isDrawerOpen = true
                })
            },
            closeDrawer: () => {
                set(state => {
                    state.surgeonScheduler.isDrawerOpen = false
                })
            },
            toggleLocations: () => {
                set(state => {
                    state.surgeonScheduler.showLocations = !state.surgeonScheduler.showLocations
                })
            },
        },
    },
}))
