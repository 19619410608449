import { useState } from 'react'

import { Location, PractitionerScheduleLocation } from '~/store/selectors'

import { DatePractitionerCell } from '../../shared'
import { LocationTag } from './LocationTag'

type Props = {
    selectedCells: DatePractitionerCell[]
    availableLocations: Location[]
    scheduleLocations: PractitionerScheduleLocation[]
}

export const LocationTagsWithStaticState = ({ selectedCells, availableLocations, scheduleLocations }: Props) => {
    const [initialLocationsState] = useState(() => availableLocations)

    return (
        <>
            {initialLocationsState.map(location => (
                <LocationTag key={location.id} selectedCells={selectedCells} scheduleLocations={scheduleLocations} location={location} />
            ))}
        </>
    )
}
