import { Button, HeroDialog } from '~/components'
import { deletePlanningPeriod } from '~/store/di-entity.api'
import { selectPlanningPeriods } from '~/store/selectors'
import { useStore } from '~/store/store'
import { trackEvent } from '~/utils/analytics'

type Props = {
    id: number
    open: boolean
    onClose: () => void
}

export function DeleteDialog({ id, open, onClose }: Props) {
    const addToast = useStore(state => state.toaster.actions.addToast)
    const planningPeriod = useStore(selectPlanningPeriods).byId(id)

    async function submit() {
        await deletePlanningPeriod(id)
        void trackEvent('PP_PLANNING_PERIOD_REMOVED')
        addToast({ type: 'success', message: 'Periode slettet' })
        onClose()
    }

    return (
        <HeroDialog isOpen={open} onCloseDialog={onClose} title={`Fjern ${planningPeriod?.name || 'periode'}`}>
            <div className="flex flex-col gap-4">
                <p className="text-md max-w-sm">
                    Er du sikker på at du vil slette planleggingsperioden? Dette vil skjule periodens planer fra planleggingssiden. Planene vil ikke slettes fra
                    kapasitet og kan legges til en annen periode senere.
                </p>
                <div className="flex items-center justify-end gap-4">
                    <Button color="white" onClick={onClose} data-test="cancel-deletion-button">
                        Avbryt
                    </Button>
                    <Button color="delete" onClick={submit} data-test="confirm-deletion-button">
                        Slett
                    </Button>
                </div>
            </div>
        </HeroDialog>
    )
}
