import { Dayjs } from 'dayjs'
import groupBy from 'lodash/groupBy'
import { createSelector } from 'reselect'

import { groupByMany } from '~/utils/array'

import { Practitioner, PractitionerScheduleLocation, selectEntities } from './entities'
import { LocationId } from './locations'
import { getDateKey } from './utils'

function getKey(locationId: LocationId, practitionerId?: number, date?: Dayjs | string | undefined | null) {
    return `${locationId}__${practitionerId}__${getDateKey(date)}`
}

export const selectGetPractitionerScheduleLocations = createSelector(selectEntities, ({ practitionerScheduleLocations }) => {
    const byDate = groupByMany(practitionerScheduleLocations, schedule => getDateKey(schedule.schedule?.scheduled_date))
    const byDateLocationIdAndPractitionerId = groupByMany(practitionerScheduleLocations, assignment => {
        return getKey(assignment.location_id, assignment.schedule?.practitioner_id, assignment.schedule?.scheduled_date)
    })

    const byPractitionerScheduleId = groupBy(practitionerScheduleLocations, 'practitioner_schedule_id')

    return {
        byCellAndPractitioner: (cell: { locationId: LocationId; date: Dayjs }, practitioner: Practitioner): PractitionerScheduleLocation[] => {
            const key = getKey(cell.locationId, practitioner.id, cell.date)
            return byDateLocationIdAndPractitionerId[key] ?? []
        },
        byPractitionerScheduleId: (practitionerScheduleId: number): PractitionerScheduleLocation[] => byPractitionerScheduleId[practitionerScheduleId] ?? [],
        byDate: (date: Dayjs): PractitionerScheduleLocation[] => byDate[getDateKey(date)] ?? [],
    }
})
