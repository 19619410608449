import { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Outlet } from 'react-router-dom'

import { HeroSidebarProvider } from '~/hero-ui'
import { useStore } from '~/store/store'

import { Navigation } from './Navigation'

export const MainLayout = () => {
    const isNavSideBarOpen = useStore(state => state.app.isNavSidebarOpen)
    const { setIsNavSidebarOpen } = useStore(state => state.app.actions)

    const isSmallDesktop = useMediaQuery({ query: '(max-width: 1224px)' })

    useEffect(() => {
        isSmallDesktop && setIsNavSidebarOpen(false)
    }, [isSmallDesktop, setIsNavSidebarOpen])

    return (
        <div className="flex h-screen flex-col overflow-hidden">
            <HeroSidebarProvider open={isNavSideBarOpen} onOpenChange={setIsNavSidebarOpen}>
                <Navigation />
                <div className="grow overflow-auto bg-white">
                    <Outlet />
                </div>
            </HeroSidebarProvider>
        </div>
    )
}
