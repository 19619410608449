import { AlertTriangleIcon } from '~/icons'

export const MissingWardWarning = () => (
    <div className="flex flex-row gap-2 rounded bg-fill-critical-subtle px-3 py-2">
        <AlertTriangleIcon className="size-6 text-critical-rest" />
        <span>
            For å sette opp denne pasienten til operasjon i Hero, må du fylle ut feltet <em>Post</em> i operasjonsbestillingen i DIPS.
        </span>
    </div>
)
