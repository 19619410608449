import { DipsSchemas } from '~/clients/dips-client'
import { CapacityRuleType, ResolvedPatientGroup, RuleEvaluation } from '~/store/selectors'
import { getToday } from '~/utils/extendedDayjs'
import { isNullish } from '~/utils/guards'

import { AgeGroup } from '../selectors/entities'

export const getPatientAgeGroup = (patient: DipsSchemas['Patient'], ageGroups: AgeGroup[]): AgeGroup | undefined => {
    const age = patient?.birthDate ? getToday().diff(patient.birthDate, 'year') : 0
    const ageGroup = ageGroups.find(group => {
        if (group.minimum_age && age >= group.minimum_age) return true
        if (group.maximum_age && age < group.maximum_age) return true
        else return false
    })
    // fallback to unspecified if no group is found
    return ageGroup ?? ageGroups.find(group => group.age_group_code === 'unspecified')
}

export function formatSurgeryTypeGroup(patientGroup?: ResolvedPatientGroup | null): string {
    return patientGroup?.surgeryTypeGroup?.display_name ?? ''
}

export function compareSurgeryTypeGroups(a?: ResolvedPatientGroup | null, b?: ResolvedPatientGroup | null): number {
    if (isNullish(a) && isNullish(b)) {
        return 0
    }
    if (isNullish(a)) {
        return -1
    }
    if (isNullish(b)) {
        return 1
    }
    return formatSurgeryTypeGroup(a).localeCompare(formatSurgeryTypeGroup(b))
}

export function compareByRuleTypeAndSurgeryTypeGroup(a: RuleEvaluation, b: RuleEvaluation): number {
    if (a.type !== b.type) {
        const ruleTypeOrder: CapacityRuleType[] = ['CountBased', 'RoomDurationBased', 'KnifeTimeBased']
        return ruleTypeOrder.indexOf(a.type) - ruleTypeOrder.indexOf(b.type)
    }

    return compareSurgeryTypeGroups(a.filteredByPatientGroup, b.filteredByPatientGroup)
}
