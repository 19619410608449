import capitalize from 'lodash/capitalize'
import { ReactNode } from 'react'

import { HeroBadge } from '~/hero-ui'
import { AsteriskIcon, BarChartIcon, BarChartOneIcon, BarChartTwoIcon } from '~/icons'

const priorities = ['required', 'high', 'medium', 'low', 'negative', 'super_negative'] as const
type Priority = (typeof priorities)[number]

function isPriority(priority: string): priority is Priority {
    return priorities.includes(priority as Priority)
}

const priorityTextMap: Record<Priority, string> = {
    required: 'Må innfris',
    high: 'Høy',
    medium: 'Medium',
    low: 'Lav',
    negative: 'Lav',
    super_negative: 'Lav',
} as const

const priorityIconMap: Record<Priority, ReactNode> = {
    required: <AsteriskIcon className="min-h-4 min-w-4 text-magic-rest" />,
    high: <BarChartIcon className="min-h-4 min-w-4 text-magic-rest" />,
    medium: <BarChartTwoIcon className="min-h-4 min-w-4 text-magic-rest" />,
    low: <BarChartOneIcon className="min-h-4 min-w-4 text-magic-rest" />,
    negative: <BarChartIcon className="min-h-4 min-w-4 text-medium-rest" />,
    super_negative: <BarChartIcon className="min-h-4 min-w-4 text-medium-rest" />,
}

export const PriorityBadge = ({ priority }: { priority: string }) => {
    if (!isPriority(priority)) {
        return null
    }

    return <HeroBadge icon={priorityIconMap[priority]} variant="outlined" text={capitalize(priorityTextMap[priority])} />
}
