import { Button } from '~/components'

type Props = {
    onClick: () => void
}

export const SearchButton = ({ onClick }: Props) => (
    <Button className="border border-gray-200 bg-gray-100 hover:border-gray-300 hover:bg-gray-200" color="custom" onClick={onClick}>
        Søk etter pasient
    </Button>
)
