import { Dayjs } from 'dayjs'

import { Practitioner, selectGetPractitionerSchedules } from '~/store/selectors'
import { useStore } from '~/store/store'
import { isNotNullish } from '~/utils/guards'

export const useGetCommentStyles = () => {
    const getPractitionerSchedules = useStore(selectGetPractitionerSchedules)

    function getCommentStyles(practitioner: Practitioner, date: Dayjs) {
        const schedule = getPractitionerSchedules.byDateAndPractitionerId(date, practitioner.id)
        if (!schedule) return ''

        const condition = isNotNullish(schedule.comment) && schedule.comment !== ''

        return condition ? 'bg-emerald-300/10  small-triangle-bookmark' : ''
    }

    return { getCommentStyles }
}
