import * as Sentry from '@sentry/browser'
import stringify from 'fast-safe-stringify'

import { DipsSchemas, getClient } from '~/clients/dips-client'
import env from '~/env'
import { isNullish } from '~/utils/guards'

import { getErrorMessageByStatusCodeGroup, networkErrorMessage } from './errorMessages'
import { dispatchErrorToast } from './slices/toasterSlice'
import { useStore } from './store'
import { transformScheduledSurgeries, UnifiedScheduledSurgeryWithId } from './transformers/scheduledSurgeryTransformer'
import { withFetchObserver } from './utils/fetchObserver'

const scheduledSurgeryOngoingFetches = new Set<string>()
export const importFullScheduledSurgeries = withFetchObserver(
    async function (startTime: string, endTime: string, treatmentLocationId: number): Promise<UnifiedScheduledSurgeryWithId[]> {
        const key = `${startTime}-${endTime}-${treatmentLocationId}`

        if (scheduledSurgeryOngoingFetches.has(key)) {
            return []
        }

        scheduledSurgeryOngoingFetches.add(key)

        const apiVersion = env.VITE_SCHEDULED_SURGERY_API_VERSION
        const client = getClient()

        let response
        try {
            response = await Sentry.startSpan({ name: 'GET /api/v1/surgery/scheduled' }, () => {
                const params = {
                    params: {
                        query: {
                            TreatmentLocationId: treatmentLocationId,
                            StartTime: startTime,
                            EndTime: endTime,
                            View: 'full',
                        },
                    },
                }

                if (apiVersion === 'v2') {
                    return client.GET('/api/v2/surgery/scheduled', params)
                }

                return client.GET('/api/v1/surgery/scheduled', params)
            })
        } catch (error) {
            dispatchErrorToast(networkErrorMessage)
            Sentry.captureException(error)
            return []
        } finally {
            scheduledSurgeryOngoingFetches.delete(key)
        }

        if (String(response.response.status).startsWith('4')) {
            dispatchErrorToast(getErrorMessageByStatusCodeGroup(4))
            return []
        }

        if (String(response.response.status).startsWith('5')) {
            dispatchErrorToast(getErrorMessageByStatusCodeGroup(5))
            return []
        }

        if (!response.data) {
            console.warn(`No data in /surgery/scheduled (${apiVersion}) response using params: ${stringify({ startTime, endTime, treatmentLocationId })}`)
            return []
        }

        const entities = transformScheduledSurgeries(response.data)

        useStore.getState().dips.actions.addFullScheduledSurgeries(entities)

        scheduledSurgeryOngoingFetches.delete(key)

        return entities
    },
    'id',
    {
        abandoned: ids => {
            useStore.getState().dips.actions.removeFullScheduledSurgeries(ids)
        },
    }
)

export type UnScheduledSurgeryWithBookingId = DipsSchemas['UnScheduledSurgery'] & { bookingId: string }

function isUnScheduledSurgeryWithBookingId(entity: DipsSchemas['UnScheduledSurgery']): entity is UnScheduledSurgeryWithBookingId {
    return typeof entity.bookingId === 'string'
}

const unscheduledSurgeryOngoingFetches = new Set<string>()
export const importUnScheduledSurgeries = withFetchObserver(
    async () => {
        const key = 'unscheduled'

        if (unscheduledSurgeryOngoingFetches.has(key)) {
            return []
        }

        unscheduledSurgeryOngoingFetches.add(key)

        let response
        try {
            response = await getClient().GET('/api/v1/surgery/waiting', {
                params: { query: { TreatmentLocationId: Number(env.VITE_DIPS_TREATMENT_LOCATION_ID) } },
            })
        } catch (error) {
            dispatchErrorToast(networkErrorMessage)
            Sentry.captureException(error)
            return []
        } finally {
            unscheduledSurgeryOngoingFetches.delete(key)
        }

        if (String(response.response.status).startsWith('4')) {
            dispatchErrorToast(getErrorMessageByStatusCodeGroup(4))
            return []
        }

        if (String(response.response.status).startsWith('5')) {
            dispatchErrorToast(getErrorMessageByStatusCodeGroup(5))
            return []
        }

        // Number of items where access is denied
        const numberOfItemsWithNoAccess = response.data?.numberOfItemsWithNoAccess
        // Number of items where access is permitted but is blocked because of missing access to department, ward, section or location and also all blocked patients for the user.
        const numberOfItemsBlockedForUser = response.data?.numberOfItemsBlockedForUser

        if (
            (!isNullish(numberOfItemsBlockedForUser) && numberOfItemsBlockedForUser > 0) ||
            (!isNullish(numberOfItemsWithNoAccess) && numberOfItemsWithNoAccess > 0)
        ) {
            useStore.getState().waitingList.actions.setHasDeniedAccessItems(true)
        } else {
            useStore.getState().waitingList.actions.setHasDeniedAccessItems(false)
        }

        const entities = response.data?.surgeryWaitingList ?? []
        const validEntities = entities.filter(isUnScheduledSurgeryWithBookingId)

        if (validEntities.length !== entities.length) {
            console.warn('Some unscheduled surgeries are missing bookingIds')
        }

        useStore.getState().dips.actions.addUnScheduledSurgeries(validEntities)

        unscheduledSurgeryOngoingFetches.delete(key)

        return validEntities
    },
    'bookingId',
    {
        abandoned: ids => {
            useStore.getState().dips.actions.removeUnScheduledSurgeries(ids)
        },
    }
)
