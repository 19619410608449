import { Dayjs } from 'dayjs'
import { useState } from 'react'

import { createBlockSchedule, deleteBlockSchedule, updateBlockSchedule } from '~/store/di-entity.api'
import { Location, selectGetBlockSchedules, selectRuleDefinitionOptions, selectSurgeryTypeGroup } from '~/store/selectors'
import { useStore } from '~/store/store'
import { createRuleInstance, RuleDefinitionId, RuleInstanceParameters } from '~/store/utils/blockEvaluation'
import { getUpdateBlockScheduleEventData, trackUserEvent } from '~/utils/analytics'
import { getOnlyDate } from '~/utils/extendedDayjs'

export function useChangeBlockSchedule(date: Dayjs, location: Location, ruleId: RuleDefinitionId, parameters: RuleInstanceParameters) {
    const [isLoading, setIsLoading] = useState(false)
    const addToast = useStore(state => state.toaster.actions.addToast)
    const blockSchedule = useStore(selectGetBlockSchedules).byDateAndLocationId(date, location.id)
    const getSurgeryTypeGroup = useStore(selectSurgeryTypeGroup)
    const getRuleDefinitions = useStore(selectRuleDefinitionOptions)

    async function removeBlockSchedule() {
        if (!blockSchedule) return

        setIsLoading(true)
        try {
            const ruleInstance = blockSchedule.rule_instance
            await deleteBlockSchedule(Number(blockSchedule.id))
            void trackUserEvent('SS_BLOCK_REMOVED', { numberOfEdits: 1, ruleInstance })
        } catch (error) {
            addToast({ type: 'error', message: 'Det oppstod en feil' })
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    async function saveBlockSchedule() {
        if (!parameters) return

        setIsLoading(true)
        try {
            const ruleInstance = createRuleInstance(ruleId, parameters)
            if (!ruleInstance) return

            if (!blockSchedule) {
                await createBlockSchedule({
                    scheduled_date: getOnlyDate(date),
                    location_id: location.id,
                    rule_instance: ruleInstance,
                })
                void trackUserEvent('SS_BLOCK_ADDED', { numberOfEdits: 1, ruleInstance })
            } else {
                await updateBlockSchedule(Number(blockSchedule.id), {
                    scheduled_date: blockSchedule.scheduled_date,
                    location_id: Number(blockSchedule.location?.id),
                    rule_instance: ruleInstance,
                })

                void trackUserEvent('SS_BLOCK_UPDATED', getUpdateBlockScheduleEventData(blockSchedule, ruleInstance, getSurgeryTypeGroup, getRuleDefinitions))
            }
        } catch (error) {
            addToast({ type: 'error', message: 'Det oppstod en feil' })
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    return { saveBlockSchedule, removeBlockSchedule, isLoading }
}
