import keyBy from 'lodash/keyBy'
import { createSelector } from 'reselect'

import { PractitionerStatusDefinition, selectEntities } from '~/store/selectors'

export const selectGetPractitionerStatusDefinitions = createSelector(selectEntities, ({ practitionerStatusDefinitions }) => {
    const byCode = keyBy(practitionerStatusDefinitions, 'code')
    const byId = keyBy(practitionerStatusDefinitions, 'id')
    const byLabel = keyBy(practitionerStatusDefinitions, 'label')

    return {
        byCode: (code: string): PractitionerStatusDefinition | null => byCode[code] ?? null,
        byLabel: (label: string): PractitionerStatusDefinition | null => byLabel[label] ?? null,
        byId: (id: number): PractitionerStatusDefinition | null => byId[id] ?? null,
    }
})
