import { useQueryClient } from '@tanstack/react-query'
import useWebSocket, { ReadyState } from 'react-use-websocket'

import env from '~/env'

import { addEvents } from './handleWebSocketUpdates'

const socketUrl = env.VITE_API_BASE_URL.replace('https', 'wss') + '/ws'

const connectionStatusMap = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
} as const

export function useWebSocketConnection() {
    const queryClient = useQueryClient()
    const { sendMessage, readyState } = useWebSocket(socketUrl, {
        share: true, // The same websocket instance will be shared between all components that use this hook
        onMessage: event => {
            if (event.data === 'pong') {
                return
            }
            addEvents(event.data, queryClient)
        },
        shouldReconnect: () => true,
        heartbeat: {
            interval: 5000,
            message: 'ping',
            returnMessage: 'pong',
            timeout: 10000,
        },
    })

    return { sendMessage, readyState, connectionStatus: connectionStatusMap[readyState] }
}
