import { useQuery } from '@tanstack/react-query'

import { components } from '~/clients/generated-optimus-api-types'
import optimusClient from '~/clients/optimus-client'
import { queryKeys } from '~/hooks/useWebSocketUpdates/handleWebSocketUpdates'

import { handleApiRequest } from './shared/apiWrapper'

export type OptimusSchemas = components['schemas']

type Request = { planningPeriodId: number }

export const serializeKeysForCache = (request: Request) => [queryKeys.planningPeriodMetrics, ...Object.values(request)]
export const usePlanningPeriodMetrics = ({ request, isEnabled }: { request: Request; isEnabled?: boolean }) =>
    useQuery({
        queryKey: serializeKeysForCache(request),
        queryFn: async () => {
            return handleApiRequest(() => optimusClient.getPlanningPeriodMetrics(request.planningPeriodId))
        },
        enabled: isEnabled,
    })
