import { useCallback, useMemo } from 'react'

import { importSurgeryMetadata } from '~/store/di-entity.api'
import { importUnScheduledSurgeries } from '~/store/dips-entity.api'
import { OccupancyData, selectGetUnScheduledSurgeries, UnScheduledSurgery } from '~/store/selectors'
import { useStore } from '~/store/store'
import { useImportEntities } from '~/store/useImportEntities'
import { getSurgeonsAndAssistants, MinimalPractitioner } from '~/utils/dips'
import { hasMatchingPractitioner } from '~/utils/hasMatchingPractitioner'
import { hasMatchingSurgeryType } from '~/utils/matchesSurgeryType'

import { smartSort } from './smartSorting'

type UnScheduledSuggestionsProps = {
    scheduledPractitioners: MinimalPractitioner[]
    occupancyData: OccupancyData | null
}

/**
 * Suggests unscheduled surgeries that can fit a surgery slot in an available block
 * @param scheduledPractitioners practitioners that are scheduled for the given date and location
 * @param occupancyData occupancy data for the given date and location
 * @returns list of unscheduled surgeries that can be scheduled for the given date and location
 */
export const useUnScheduledSuggestions = ({ scheduledPractitioners, occupancyData }: UnScheduledSuggestionsProps) => {
    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const getUnScheduleSurgeries = useStore(selectGetUnScheduledSurgeries)
    const unScheduledSurgeries = getUnScheduleSurgeries.byDepartmentKey(departmentKey)

    const { isError, isLoading } = useImportEntities(() => [importUnScheduledSurgeries(), importSurgeryMetadata({})], [])

    const filterByUnassignedOrScheduledPractitioners = useCallback(
        (surgery: UnScheduledSurgery) => {
            const surgeryPractitioners = getSurgeonsAndAssistants(surgery.surgeryResources)
            return surgeryPractitioners.length === 0 || hasMatchingPractitioner(surgeryPractitioners, scheduledPractitioners)
        },
        [scheduledPractitioners]
    )

    const filterByMatchingSurgeryType = useCallback(
        (surgery: UnScheduledSurgery): boolean => {
            if (!occupancyData) return false
            const surgeryType = surgery.surgeryType
            if (surgeryType === undefined) return true

            return occupancyData.evaluations.some(rule => hasMatchingSurgeryType(rule, surgeryType))
        },
        [occupancyData]
    )

    const unScheduledSuggestions = useMemo(() => {
        const date = occupancyData?.date

        if (!date) return [] satisfies typeof unScheduledSurgeries

        return smartSort({
            bookingDate: date,
            surgeries: unScheduledSurgeries.filter(filterByUnassignedOrScheduledPractitioners).filter(filterByMatchingSurgeryType),
        })
    }, [occupancyData?.date, unScheduledSurgeries, filterByUnassignedOrScheduledPractitioners, filterByMatchingSurgeryType])

    const suggestionCount = unScheduledSuggestions.length

    return { unScheduledSuggestions, suggestionCount, isError, isLoading }
}
