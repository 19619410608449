import { Dayjs } from 'dayjs'
import { useState } from 'react'

import { Show, Spinner } from '~/components'
import { EvaluationOutput } from '~/hooks/tanstack-query/optimus/useEvaluationQuery'

import { AboutView } from './About/AboutView'
import { ViewLayout } from './shared/ViewLayout'
import { WishesView } from './Wishes/WishesView'

type Props = {
    evaluation: EvaluationOutput
    departmentName: string
    date: Dayjs
    isSidebarLoading: boolean
}

const views = [
    { label: 'Ønsker', value: 'wishes' },
    { label: 'Om Hero', value: 'about' },
] as const
type View = (typeof views)[number]['value']

export const InsightsDrawer = ({ date, departmentName, evaluation, isSidebarLoading }: Props) => {
    const [view, setView] = useState<View>('wishes')

    function onValueChange(value: View) {
        setView(value)
    }

    return (
        <div className="mb-4 ml-2 mt-1 flex h-full w-[30rem] flex-col gap-2 rounded border bg-gray-50/80 pl-4 pr-2">
            <div className="-ml-4 -mr-2 flex border-b-2 px-4 pb-2 pt-4">
                <div className="flex w-full gap-x-2 py-1">
                    {views.map(({ label, value }) => (
                        <button
                            key={value}
                            className={`rounded px-2 py-0.5 ${view === value ? 'bg-fill-secondary-active' : 'text-medium-rest hover:bg-fill-secondary-hover'}`}
                            onClick={() => onValueChange(value)}
                        >
                            {label}
                        </button>
                    ))}
                </div>
            </div>

            <Show
                condition={!isSidebarLoading}
                fallback={
                    <div className="flex h-full items-center justify-center overflow-auto">
                        <Spinner size="lg" />
                    </div>
                }
            >
                <Show condition={view === 'wishes'}>
                    {departmentName !== 'Alle' ? (
                        <WishesView date={date} departmentName={departmentName} evaluation={evaluation} />
                    ) : (
                        <ViewLayout title="Ønsker (beta)">
                            For å se alle ønsker må du først velge avdeling. Vi jobber med å få alle ønsker tilgjengelig på tvers av avdelinger.
                        </ViewLayout>
                    )}
                </Show>
                <Show condition={view === 'about'}>
                    <AboutView />
                </Show>
            </Show>
        </div>
    )
}
