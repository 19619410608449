import { Dayjs } from 'dayjs'
import { createSelector } from 'reselect'

import { RuleEvaluation, selectGetLocations } from '~/store/selectors'
import { getSurgeonsAndAssistants, MinimalPractitioner } from '~/utils/dips'
import { format } from '~/utils/extendedDayjs'
import { getOpeningHours, OpeningHours } from '~/utils/openingHours'

import { DepartmentKey } from '../slices/filterSlice'
import { selectGetBlockSchedules } from './blockSchedules'
import { BlockSchedule, Location, ScheduledSurgery } from './entities'
import { evaluateBlock, findMismatchedSurgeries } from './internal/blockEvaluations/blockEvaluation'
import { selectGetResolvedPatientGroups } from './resolvedPatientGroups'
import { selectGetScheduledSurgeries } from './scheduledSurgeries'

export type OccupancyData = {
    date: Dayjs
    location: Location
    blockSchedule: BlockSchedule | null
    evaluations: RuleEvaluation[] // sorted by rule type and then by surgery group type name
    bookedSurgeries: ScheduledSurgery[]
    bookedPractitioners: MinimalPractitioner[]
    mismatchedSurgeries: ScheduledSurgery[]
    openingHours: OpeningHours
}

function getCacheKey(date: Dayjs, location: Location) {
    return `${format(date, 'YYYY-MM-DD')}-${location.id}`
}

export const selectGetOccupancies = createSelector(
    selectGetResolvedPatientGroups,
    selectGetBlockSchedules,
    selectGetScheduledSurgeries,
    selectGetLocations,
    (getResolvedPatientGroups, getBlockSchedules, getScheduledSurgeries, getLocations) => {
        const cache: Record<string, OccupancyData> = {}

        function byDateAndLocation(date: Dayjs, location: Location): OccupancyData {
            const key = getCacheKey(date, location)
            const cached = cache[key]
            if (cached) {
                return cached
            }

            const locationId = location.id
            const blockSchedule = getBlockSchedules.byDateAndLocationId(date, locationId)
            const bookedSurgeries = getScheduledSurgeries.byDateAndRoomCode(date, location.room_code)
            const allSurgeryResources = bookedSurgeries.flatMap(surgery => surgery.surgeryResources)

            const bookedPractitioners = getSurgeonsAndAssistants(allSurgeryResources)

            const evaluations = evaluateBlock(blockSchedule, bookedSurgeries, getResolvedPatientGroups)
            const mismatchedSurgeries = findMismatchedSurgeries(bookedSurgeries, blockSchedule, getResolvedPatientGroups)
            const openingHours = getOpeningHours(date, location)

            const occupancyData = {
                date,
                location,
                blockSchedule,
                evaluations,
                bookedSurgeries,
                bookedPractitioners,
                mismatchedSurgeries,
                openingHours,
            }

            cache[key] = occupancyData

            return occupancyData
        }

        function byDate(date: Dayjs, departmentKey: DepartmentKey): OccupancyData[] {
            return getLocations.byDepartment(departmentKey).map(location => byDateAndLocation(date, location))
        }

        return {
            byDate,
            byDateAndLocation,
        }
    }
)
