import { useMemo } from 'react'

import { Remarks } from '~/app/pages/WaitingList/shared/Remarks'
import { Statuses } from '~/app/pages/WaitingList/shared/Statuses'
import { formatRemarks, formatStatuses } from '~/app/pages/WaitingList/shared/utils'
import { CopyToClipboard, Show, Tag } from '~/components'
import { ChildIcon, CopyIcon } from '~/icons'
import { selectGetAgeGroups } from '~/store/selectors'
import { useStore } from '~/store/store'
import { getPatientAgeGroup } from '~/store/utils/patientHelpers'
import { AnyScheduledSurgery, formatAgeAndNationalId, formatFullNameAndMunicipality, formatNationalIdCopyText } from '~/utils/dips'
import { getFormattedSurgery } from '~/utils/transformations/surgeryInfo'

type Props = {
    surgery: AnyScheduledSurgery
}

export function PatientCard({ surgery }: Props) {
    const ageGroups = useStore(state => state.di.entities.ageGroups)
    const getAgeGroup = useStore(selectGetAgeGroups)
    const patientAgeGroup = surgery.patient && ageGroups ? getPatientAgeGroup(surgery.patient, Object.values(ageGroups.byId)) : undefined
    const ageGroup = getAgeGroup.byCode(patientAgeGroup?.age_group_code)

    const { asa, dayOvernight } = useMemo(() => getFormattedSurgery(surgery), [surgery])
    const statuses = formatStatuses(surgery)
    const remarks = formatRemarks(surgery)

    const fullNameAndMunicipality = formatFullNameAndMunicipality(surgery.patient)
    const ageAndNationalId = formatAgeAndNationalId(surgery.patient)
    const { hasNationalId, nationalIdCopyText } = formatNationalIdCopyText(surgery.patient)

    // This is a booking draft and we need to render scheduled statuses
    const isScheduled = true

    return (
        <div className="flex flex-col gap-2 rounded border p-3 pt-2">
            <div className="flex items-center justify-between">
                <div className="font-semibold">{fullNameAndMunicipality}</div>
                <div className="flex items-center gap-1">
                    <Statuses statuses={statuses} isScheduledItem={isScheduled} />
                    <Remarks remarks={remarks} />
                </div>
            </div>
            <div className="flex items-center gap-1">
                {ageAndNationalId}
                <Show condition={hasNationalId}>
                    <CopyToClipboard copyText={nationalIdCopyText}>
                        <CopyIcon className="h-4 w-4" />
                    </CopyToClipboard>
                </Show>
            </div>

            <div className="flex gap-2">
                <div className="align-center flex grow gap-1">
                    {asa && (
                        <Tag color="surgeryInfo" textSize="sm">
                            {asa}
                        </Tag>
                    )}
                    {patientAgeGroup && patientAgeGroup.age_group_code === 'child' && (
                        <Tag color="surgeryInfo" textSize="sm">
                            <ChildIcon />
                            {ageGroup?.display_name || 'Barn'}
                        </Tag>
                    )}
                    <Tag color="surgeryInfo" textSize="sm">
                        {dayOvernight?.icon} {dayOvernight?.label}
                    </Tag>
                </div>
                <div />
            </div>

            <div className="font-normal text-gray-700">{surgery.contact?.coordinationComment ?? ''}</div>
        </div>
    )
}
