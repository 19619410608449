import { createBatchPractitionerSchedule } from '~/store/di-entity.api'
import { selectGetPractitionerSchedules } from '~/store/selectors'
import { useStore } from '~/store/store'
import { getOnlyDate } from '~/utils/extendedDayjs'

import { DatePractitionerCell } from '../../shared'

export function useCreateMissingSchedules(selectedCells: DatePractitionerCell[]) {
    const getPractitionerSchedules = useStore(selectGetPractitionerSchedules)

    const cellsWithoutPractitionerSchedule = selectedCells.filter(
        ({ date, practitionerId }) => !getPractitionerSchedules.byDateAndPractitionerId(date, practitionerId)
    )

    return () =>
        createBatchPractitionerSchedule(
            cellsWithoutPractitionerSchedule.map(({ date, practitionerId }) => ({
                practitioner_id: practitionerId,
                scheduled_date: getOnlyDate(date),
            }))
        )
}
