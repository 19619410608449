import { Slot } from '@radix-ui/react-slot'
import { forwardRef, HTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

import { BodyVariant, FontWeight } from '../../../types/types'

// Default classes for typography variants from the Astra design system
const variantClassName: Record<BodyVariant, string> = {
    'body-large': 'text-body-large leading-body-large tracking-body-large',
    body: 'text-body leading-body tracking-body',
    'body-small': 'text-body-small leading-body-small tracking-body-small',
    'body-extra-small': 'text-body-extra-small leading-body-extra-small tracking-body-extra-small',
}

// Font weights from the Astra design system
const fontWeight: Record<FontWeight, string> = {
    regular: 'font-hero-regular',
    medium: 'font-hero-medium',
    strong: 'font-hero-strong',
}

type Props = HTMLAttributes<HTMLParagraphElement> & {
    asChild?: boolean
    variant?: BodyVariant
    weight?: FontWeight
}

export const HeroParagraph = forwardRef<HTMLParagraphElement, Props>(
    ({ children, variant = 'body', weight = 'regular', className, asChild, ...props }, ref) => {
        const Comp = asChild ? Slot : 'p'

        return (
            <Comp ref={ref} className={`${twMerge(variantClassName[variant], fontWeight[weight])} ${className ?? ''}`} {...props}>
                {children}
            </Comp>
        )
    }
)

HeroParagraph.displayName = 'HeroParagraph' // Necessary for better debugging when using forwardRef
