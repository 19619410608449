import { Dayjs } from 'dayjs'

import { getClient } from '~/clients/dips-client'

import { errorResponse } from './pollSurgeryBookingStatus'

const refetchErrorMessage = 'Operasjonsbestillingen var vellykket, men klarte ikke å hente oppdaterte data fra DIPS.'

// should not throw
export async function findBookedSurgery(bookingId: string | undefined, surgeryDate: Dayjs): Promise<{ isError: boolean; errorMessage: string }> {
    let response
    try {
        response = await getClient().GET('/api/v1/surgery/scheduled', {
            params: {
                query: {
                    BookingId: bookingId,
                    StartTime: surgeryDate.startOf('day').format(),
                    EndTime: surgeryDate.add(1, 'day').startOf('day').format(),
                    View: 'Minimal',
                },
            },
        })
    } catch (error) {
        console.error('Error refetching the booked surgery', error)
        return errorResponse(refetchErrorMessage)
    }

    if (!response.data) {
        console.error('Error refetching the booked surgery (no data in response)')
        return errorResponse(refetchErrorMessage)
    }

    if (response.data.length === 0) {
        console.error(`Error refetching the booked surgery: booking ${bookingId} not found on ${surgeryDate.format('YYYY-MM-DD')}`)
        return errorResponse(refetchErrorMessage)
    }

    return { isError: false, errorMessage: '' }
}
