import cloneDeep from 'lodash/cloneDeep'
import { ChangeEvent, useState } from 'react'

import { SidebarSwitch } from '~/app/pages/WaitingList/Drawer/shared/SidebarSwitch'
import { Payload, saveSurgeryMetadata } from '~/app/pages/WaitingList/shared/saveSurgeryMetadata'
import { getCompletedStatus, Status } from '~/app/pages/WaitingList/shared/Statuses'
import { Button, DISelect, Show, Spinner } from '~/components'
import env from '~/env'
import { AlertSquareRoundedIcon, CircleCheckIcon } from '~/icons'
import { Location, OccupancyData, ScheduledSurgery, selectEntities, UnScheduledSurgery } from '~/store/selectors'
import { useStore } from '~/store/store'
import { getBookingIdOrId, isPractitionerSlot, isScheduledSurgery, isTheaterRoom } from '~/utils/dips'
import { day, format, getFormattedDuration, getNewDateWithTime } from '~/utils/extendedDayjs'
import { zeroDurationToken } from '~/utils/transformations/surgeryInfo'

import { Field, Fieldset } from './Fieldset'
import { FormInput } from './FormInput'
import { FormOverview } from './FormOverview'
import { MissingWardWarning } from './MissingWardWarning'
import { PatientCard } from './PatientCard'

interface Props {
    occupancyData: OccupancyData
    defaultError?: string
    isLoading: boolean
    isSuccess?: boolean
    location: Location
    onClose: () => void
    onSubmit: (checkInTime: string, startTime: string, slots: UnScheduledSurgery['surgeryResources']) => void
    surgery: ScheduledSurgery | UnScheduledSurgery
}

export const BookingForm = ({ occupancyData, defaultError, isLoading, isSuccess, location, onClose, onSubmit, surgery }: Props) => {
    const [checkInTime, setCheckInTime] = useState<string>(format(day(surgery.contact?.checkInTime), 'HH:mm'))
    const [checkInTimeError, setCheckInTimeError] = useState<string | undefined>()
    const [startTime, setStartTime] = useState<string | undefined>(
        'plannedTheatreInTime' in surgery ? format(day(surgery.plannedTheatreInTime), 'HH:mm') : undefined
    )
    const [startTimeError, setStartTimeError] = useState<string | undefined>()
    const [multiInputError, setMultiInputError] = useState<string | undefined>(defaultError)
    const entities = useStore(selectEntities)
    const [slots, setSlots] = useState(
        cloneDeep(surgery.surgeryResources)?.map(slot => {
            if (isTheaterRoom(slot)) {
                return {
                    ...slot,
                    resourceId: location.dipsResource?.id,
                    name: location.dipsResource?.name,
                    shortName: location.dipsResource?.shortName,
                }
            }

            return slot
        }) ?? []
    )

    const bookingDate = occupancyData.date
    const showWardWarning = surgery.contact?.levelOfCareNpr?.nprCodeName === 'Heldøgn' && !Boolean(surgery.contact?.ward)
    const isScheduled = isScheduledSurgery(surgery)
    const disabled = isLoading || isSuccess || showWardWarning || isScheduled

    const isConfirmed = Boolean(surgery.surgeryMetadata?.patient_confirmed)

    function saveMetadata(metadata: Omit<Payload, 'booking_id'>) {
        const bookingId = getBookingIdOrId(surgery)
        saveSurgeryMetadata(surgery.surgeryMetadata, { booking_id: bookingId, ...metadata })
    }

    const resetErrors = () => {
        setCheckInTimeError(undefined)
        setStartTimeError(undefined)
        setMultiInputError(undefined)
    }

    const handleSubmit = () => {
        if (isScheduled) {
            console.error('Cannot schedule surgery for an already scheduled item')
            return
        }

        if (multiInputError) {
            return
        }

        if (!checkInTime || !startTime) {
            setMultiInputError('Stue inn og Oppmøtetid må fylles ut')
            return
        }

        if (!slots) {
            setMultiInputError('Ressurs må velges')
            return
        }

        onSubmit(checkInTime, startTime, slots)
    }

    const handleStartTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
        setStartTime(e.target.value)

        const checkin = getNewDateWithTime(bookingDate, checkInTime)
        const start = getNewDateWithTime(bookingDate, e.target.value)

        if (start.isBefore(checkin)) {
            setStartTimeError('Stue inn kan ikke være før oppmøtetid')
            return
        }
        resetErrors()
    }

    const handleCheckinTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCheckInTime(e.target.value)

        if (!startTime) {
            return
        }

        const start = getNewDateWithTime(bookingDate, startTime ?? '00:00')

        const checkin = getNewDateWithTime(bookingDate, e.target.value)

        if (start.isBefore(checkin)) {
            setCheckInTimeError('Stue inn kan ikke være før oppmøtetid')
            return
        }
        resetErrors()
    }

    function setSlotResource(id?: string | null, resourceId?: number | null) {
        if (!id) {
            return
        }

        const resource = entities.dipsResources.find(resource => resource.id === resourceId)

        if (!resource) {
            return
        }

        setSlots(prevSlots =>
            prevSlots?.map(slot => {
                if ('id' in slot) {
                    if (slot.id === id) {
                        return {
                            ...slot,
                            resourceId: resource.id,
                            shortName: resource.shortName,
                            name: resource.name,
                        }
                    }
                } else if ('bookingId' in slot) {
                    if (slot.bookingId === id) {
                        return {
                            ...slot,
                            resourceId: resource.id,
                            shortName: resource.shortName,
                            name: resource.name,
                        }
                    }
                }
                return slot
            })
        )
    }

    return (
        <>
            <div className="flex flex-col gap-5">
                <Show condition={showWardWarning}>
                    <MissingWardWarning />
                </Show>
                <FormOverview
                    operation={surgery.surgeryType?.name ?? ''}
                    bookingDate={format(bookingDate, 'dddd D. MMM YYYY')}
                    room={location.room_code}
                    plannedProcedureDuration={getFormattedDuration(surgery.plannedProcedureDuration ?? zeroDurationToken, true)}
                />

                <Fieldset label="Pasient">
                    <PatientCard surgery={surgery} />
                    <SidebarSwitch label="Bekreftet oppmøte" checked={isConfirmed} onCheckedChange={checked => saveMetadata({ patient_confirmed: checked })}>
                        <Status status={{ type: 'confirmed', value: getCompletedStatus(isConfirmed) }} isSelected={isConfirmed} />
                    </SidebarSwitch>
                </Fieldset>

                <Fieldset label="Tildel roller på operasjonsstuen">
                    <div className="grid grid-cols-4 gap-2">
                        {slots.filter(isPractitionerSlot).map(slot => {
                            const slotId = 'id' in slot ? slot.id : 'bookingId' in slot ? slot.bookingId : undefined
                            const resourceTypeId = 'typeId' in slot ? slot.typeId : 'resourceTypeId' in slot ? slot.resourceTypeId : undefined
                            const resourceName = slot.assignedResourceTypeName ?? resourceTypeId ?? 'Ressurstype'

                            return (
                                <div key={slotId}>
                                    <Field label={resourceName}>
                                        <DISelect
                                            isMulti={false}
                                            options={entities.dipsResources
                                                .filter(resource => resource.resourceType === 'MainSurgeon')
                                                .toSorted((a, b) => a.shortName?.localeCompare(b.shortName ?? '') ?? 0)
                                                .map(resource => ({
                                                    label: resource.shortName ?? '',
                                                    value: resource.id,
                                                }))}
                                            defaultValue={{ label: slot.shortName ?? '', value: slot.resourceId }}
                                            onChange={newValue => setSlotResource(slotId, newValue?.value)}
                                            isDisabled={disabled}
                                        />
                                    </Field>
                                </div>
                            )
                        })}
                    </div>
                </Fieldset>

                <Fieldset label="Tider">
                    <div className="grid grid-cols-4 gap-2">
                        <Field label="Oppmøtetid">
                            <FormInput
                                label="Oppmøtetid"
                                defaultValue={checkInTime}
                                onChange={handleCheckinTimeChange}
                                error={checkInTimeError}
                                id="check-in-time"
                                disabled={disabled}
                            />
                        </Field>

                        <Field label="Stue inn">
                            <FormInput
                                label="Stue inn"
                                defaultValue={startTime}
                                onChange={handleStartTimeChange}
                                error={startTimeError}
                                id="start-time"
                                disabled={disabled}
                            />
                        </Field>
                    </div>
                </Fieldset>

                {multiInputError && (
                    <div className="relative grid justify-between gap-6">
                        <p className="flex w-fit items-center gap-0.5 justify-self-end rounded-sm bg-red-500 py-0.5 pl-0.5 pr-1 align-middle text-xs text-white">
                            <AlertSquareRoundedIcon height={20} />
                            {multiInputError}
                        </p>
                    </div>
                )}

                <div className="flex justify-end gap-2">
                    {isScheduled ? (
                        <Button color="white" onClick={onClose} data-test="close">
                            Lukk
                        </Button>
                    ) : isSuccess ? (
                        <>
                            <div className="flex items-center gap-2 text-green-700">
                                Operasjon planlagt. <CircleCheckIcon />
                            </div>
                            <Button color="white" onClick={onClose} data-test="success-close">
                                Lukk
                            </Button>
                        </>
                    ) : (
                        <>
                            {isLoading ? (
                                <>
                                    <Button disabled={true} className="flex gap-2">
                                        <Spinner size="sm" /> Venter på DIPS
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button color="delete" onClick={onClose}>
                                        Avbryt
                                    </Button>
                                    {env.VITE_FF_OPERATIONAL_PLANNER_WAITINGLIST_BOOKING_BUTTON && (
                                        <Button onClick={handleSubmit} data-test="confirm-button" disabled={disabled}>
                                            Planlegg operasjon
                                        </Button>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    )
}
