import { useMemo } from 'react'
import { daysBetween } from 'rrule/dist/esm/dateutil'

import { Button } from '~/components'
import { UnScheduledSurgery } from '~/store/selectors'
import { AnyScheduledSurgery, isUnScheduledSurgery } from '~/utils/dips'
import { day, getToday } from '~/utils/extendedDayjs'
import { isNotNullish } from '~/utils/guards'
import { getFormattedSurgery } from '~/utils/transformations/surgeryInfo'
import { formatDateToNorskReadableIdDate } from '~/utils/utils'

export const zeroDurationToken = 'P0DT0H00M0.000000S'

type Props = {
    surgery: AnyScheduledSurgery
    hideAction?: boolean
    onClick?: (selectedSurgery: UnScheduledSurgery) => void
}

export const SearchResult = ({ surgery, onClick }: Props) => {
    const today = getToday()
    const isUnscheduled = isUnScheduledSurgery(surgery)
    const { patientNameDetailed, dayOvernight, practitioners } = useMemo(() => getFormattedSurgery(surgery), [surgery])
    const checkInTime = surgery.contact?.checkInTime

    return (
        <div className="flex items-center justify-between gap-4 border-b pb-3">
            <div className="flex flex-col gap-1">
                <p className="font-bold">{patientNameDetailed}</p>
                <p className="w-fit rounded bg-slate-100 px-1 text-sm">
                    {isUnscheduled
                        ? `Ventet ${daysBetween(today.toDate(), day(surgery.creationTime).toDate())} dager`
                        : `Operasjon: ${isNotNullish(checkInTime) ? formatDateToNorskReadableIdDate(day(checkInTime)).label : '—'}`}
                </p>
                <p>
                    {surgery.surgeryType?.name} - {dayOvernight?.label} -{' '}
                    {practitioners
                        .filter(prac => prac.short_name)
                        .map(p => p.short_name)
                        .join(', ')}
                </p>
            </div>
            {isUnscheduled && onClick && (
                <div className="w-fit">
                    <Button color="white" size="sm" onClick={() => onClick(surgery)} className="text-nowrap" disabled={!isUnscheduled}>
                        Bestill operasjon
                    </Button>
                </div>
            )}
        </div>
    )
}
