import { Dayjs } from 'dayjs'
import { immer } from 'zustand/middleware/immer'

import { getAvailableRoomDuration } from '~/app/pages/OperationalPlanner/shared/utils'
import { AnyScheduledSurgery, isUnScheduledSurgery } from '~/utils/dips'
import { formatDuration } from '~/utils/extendedDayjs'

import { Location, OccupancyData } from '../selectors'
import { Slice } from '../store'
import { formatCapacity, formatTotalKnifeTime, getUsedRoomDuration } from '../utils/blockEvaluation'

type DrawerProps = {
    unixDate: number
    locationId: number
    roomCode: string
    showAvailability: boolean
    totalKnifeTime: string
    roomOpeningHours: string
    usedRoomDuration: string
    availableRoomDuration: string
    capacity?: string
}

type SelectedSurgery = { id: string; type: 'unscheduled' | 'scheduled' } | null

type OpenDrawerArgs = {
    occupancyData?: OccupancyData
    date?: Dayjs
    location?: Location
    hasAvailability: boolean
}

type State = {
    isDrawerOpen: boolean
    drawerProps: DrawerProps | null
    needle: string
    selectedSurgery: SelectedSurgery
}

const initialState: State = {
    isDrawerOpen: false,
    drawerProps: null,
    needle: '',
    selectedSurgery: null,
}

type Actions = {
    actions: {
        set: (args: Partial<State>) => void
        openDrawer: (args: OpenDrawerArgs) => void
        closeDrawer: () => void
        setSelectedSurgery: (surgery: AnyScheduledSurgery | null) => void
    }
}

export type OperationalPlannerSlice = {
    operationalPlanner: State & Actions
}

export const createOperationalPlannerSlice: Slice<OperationalPlannerSlice> = immer(set => ({
    operationalPlanner: {
        ...initialState,
        actions: {
            set: args => {
                set(state => {
                    Object.assign(state.operationalPlanner, args)
                })
            },
            openDrawer: ({ occupancyData, location, date, hasAvailability }: OpenDrawerArgs) => {
                if (!occupancyData || !location || !date) return

                // we're formatting here instead of in the BookingDrawerHeader because RuleEvaluation/Duration is unserializable
                const totalKnifeTime = formatTotalKnifeTime(occupancyData.bookedSurgeries)
                const usedRoomDuration = formatDuration(getUsedRoomDuration(occupancyData))
                const roomOpeningHours = formatDuration(occupancyData.openingHours.duration)
                const availableRoomDuration = formatDuration(getAvailableRoomDuration(occupancyData))
                const firstEvaluation = occupancyData.evaluations[0] // warning for >1 issued already in BookingsList
                const capacity = firstEvaluation ? formatCapacity(firstEvaluation) : undefined

                set(state => {
                    state.operationalPlanner.drawerProps = {
                        unixDate: date.unix(),
                        locationId: location.id,
                        roomCode: location.room_code,
                        showAvailability: hasAvailability,
                        totalKnifeTime,
                        usedRoomDuration,
                        roomOpeningHours,
                        availableRoomDuration,
                        capacity,
                    }
                    state.operationalPlanner.isDrawerOpen = true
                })
            },
            closeDrawer: () => {
                set(state => {
                    state.operationalPlanner.isDrawerOpen = false
                    state.operationalPlanner.drawerProps = null
                })
            },
            setSelectedSurgery(surgery: AnyScheduledSurgery | null) {
                let selectedSurgery: SelectedSurgery = null

                if (surgery) {
                    const isUnScheduled = isUnScheduledSurgery(surgery)
                    const id = isUnScheduled ? surgery.bookingId : surgery.id
                    const type = isUnScheduled ? 'unscheduled' : 'scheduled'
                    selectedSurgery = { id, type }
                }

                set(state => {
                    state.operationalPlanner.selectedSurgery = selectedSurgery
                })
            },
        },
    },
}))
