import { AnimatePresence, motion } from 'framer-motion'
import { useCallback } from 'react'

import { HeroDialog } from '~/components'
import { HeroBadge } from '~/hero-ui'
import { AlertSquareRoundedIcon } from '~/icons'
import { OccupancyData } from '~/store/selectors'
import { AnyScheduledSurgery, isScheduledSurgery } from '~/utils/dips'
import { day } from '~/utils/extendedDayjs'

import { BookingForm } from './BookingForm/BookingForm'
import { useScheduleSurgerySaga } from './hooks/useScheduleSurgerySaga/useScheduleSurgerySaga'

type Props = {
    surgery: AnyScheduledSurgery
    occupancyData: OccupancyData
    isOpen: boolean
    onCloseDialog: () => void
}

export const BookingDialog = ({ surgery, isOpen, onCloseDialog, occupancyData }: Props) => {
    const { scheduleSurgery, isLoading, isError, errorMessage, isSuccess } = useScheduleSurgerySaga()
    const location = occupancyData.location
    const isScheduled = isScheduledSurgery(surgery)

    /** Replaces the time in the occupancyData date, leaving the day itself the same */
    const getUpdatedTime = useCallback(
        (time: string) => {
            const [hours, minutes] = time.split(':')
            return day(occupancyData.date).set('hour', Number(hours)).set('minute', Number(minutes))
        },
        [occupancyData.date]
    )

    return (
        <HeroDialog
            isOpen={isOpen}
            onCloseDialog={onCloseDialog}
            title="Operasjonsbestilling"
            afterTitle={<HeroBadge text={isScheduled ? 'Bestilt' : 'Utkast'} color={isScheduled ? 'green' : 'blue'} />}
        >
            <div className="relative w-[35rem]">
                <AnimatePresence>
                    {isError && (
                        <motion.div
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            transition={{ type: 'linear' }}
                            data-test="booking-request-error"
                            className="absolute right-0 top-0 flex w-fit items-center gap-0.5 justify-self-end rounded-sm bg-red-500 py-0.5 pl-0.5 pr-1 align-middle text-xs text-white"
                        >
                            <AlertSquareRoundedIcon height={20} />
                            {errorMessage}
                        </motion.div>
                    )}
                </AnimatePresence>

                <BookingForm
                    occupancyData={occupancyData}
                    isLoading={isLoading}
                    isSuccess={isSuccess}
                    location={location}
                    onClose={onCloseDialog}
                    onSubmit={async (checkInTime, plannedTheatreInTime, slots) => {
                        if (isScheduled) {
                            console.error('Cannot schedule surgery for an already scheduled item')
                            return
                        }

                        if (!slots) {
                            console.error('No resource slots found')
                            return
                        }

                        slots = slots
                            // Map to the correct format
                            .map(slot => ({ id: slot.id, resourceId: slot.resourceId }))

                        await scheduleSurgery({
                            waitingListItem: surgery,
                            resourceSlots: slots,
                            checkInTime: getUpdatedTime(checkInTime),
                            plannedTheatreInTime: getUpdatedTime(plannedTheatreInTime),
                        })
                    }}
                    surgery={surgery}
                />
            </div>
        </HeroDialog>
    )
}
