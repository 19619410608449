import stringify from 'fast-safe-stringify'
import isEqual from 'lodash/isEqual'
import isObject from 'lodash/isObject'
import { useEffect, useState } from 'react'

import { BlockLockFingerprint } from '~/clients/derived-api-types'
import { deleteBlockLock } from '~/store/di-entity.api'
import { CapacityRuleType, RuleEvaluation, selectEntities, selectGetLocations, selectGetOccupancies } from '~/store/selectors'
import { useStore } from '~/store/store'

import { day, getOnlyDate } from './extendedDayjs'

type CapacityRuleFingerprintV1 = Partial<Record<CapacityRuleType, number | null>>

type FingerprintV1 = {
    v: 1
    data: CapacityRuleFingerprintV1[]
}

/**
 * The fingerprint is used to determine if relevant information has changed in order
 * to decide if a BlockLock should be deleted, which will show availability in the calendar.
 *
 * Right now we're going with a simple approach where we just do a deep equality check between
 * the saved fingerprint and the actual fingerprint.
 *
 * Version 1 fingerprint is just availability data.
 */
function createFingerprintV1(evaluations: RuleEvaluation[]): FingerprintV1 {
    const data = evaluations
        .filter(evaluation => evaluation.status === 'Available' && evaluation.remaining !== null)
        .map<CapacityRuleFingerprintV1>(evaluation => {
            if (evaluation.remaining === null || typeof evaluation.remaining === 'number') {
                return { [evaluation.type]: evaluation.remaining }
            }

            return { [evaluation.type]: evaluation.remaining.asHours() }
        })

    const fingerprint: FingerprintV1 = { v: 1, data }
    return fingerprint
}

export function createFingerprint(evaluations: RuleEvaluation[]): string {
    // the point of this function is to ensure that we only have to update the version number in this file;
    // if we exposed createFingerprintVN, then we'd have to update everywhere in the codebase
    return stringify(createFingerprintV1(evaluations))
}

function parseFingerprint(fingerprint: BlockLockFingerprint): FingerprintV1 | null {
    if (!fingerprint) {
        return null
    }

    let parsed

    try {
        parsed = JSON.parse(fingerprint)
    } catch (e) {
        console.error('Failed to parse fingerprint', e)
        return null
    }

    if (!isObject(parsed)) {
        console.error('Fingerprint is not an object', parsed)
        return null
    }

    if (!('v' in parsed) || typeof parsed.v !== 'number') {
        console.error('Fingerprint is missing version', parsed)
        return null
    }

    if (parsed.v === 1) {
        return parsed as unknown as FingerprintV1
    }

    console.error('Unsupported fingerprint version', parsed.v)
    return null
}

type ComparisonResult = {
    isEqual: boolean
    prevFingerprint: FingerprintV1 | null
    newFingerprint: FingerprintV1 | null
}
function compareFingerprints(evaluations: RuleEvaluation[], fingerprintString: BlockLockFingerprint): ComparisonResult {
    const prevFingerprint = parseFingerprint(fingerprintString)

    if (!prevFingerprint) {
        return { isEqual: false, prevFingerprint, newFingerprint: null }
    }

    const newFingerprint = createFingerprintV1(evaluations)
    const isFingerprintEqual = isEqual(prevFingerprint, newFingerprint)

    return { isEqual: isFingerprintEqual, prevFingerprint, newFingerprint }
}

export function useAutomaticBlockLockRelease(options: { skip: boolean }) {
    const [isOpeningBlockLock, setIsOpeningBlockLock] = useState(false)

    useEffect(() => {
        if (options.skip || isOpeningBlockLock) {
            return
        }

        const state = useStore.getState()
        const entities = selectEntities(state)
        const getLocations = selectGetLocations(state)
        const getOccupancies = selectGetOccupancies(state)

        const blockLocksToRelease = entities.blockLocks
            .map(blockLock => {
                const date = day(getOnlyDate(blockLock.start_time))
                const location = getLocations.byId(blockLock.location_id)

                if (!location || !date) {
                    return false
                }

                const occupancyData = getOccupancies.byDateAndLocation(date, location)

                return {
                    comparison: compareFingerprints(occupancyData.evaluations, blockLock.fingerprint),
                    blockLock,
                }
            })
            .filter(Boolean)
            .filter(comparison => comparison.comparison.isEqual === false)

        if (blockLocksToRelease.length === 0) {
            return
        }

        async function releaseLocks() {
            setIsOpeningBlockLock(true)
            blockLocksToRelease.forEach(comparison => {
                console.warn(
                    `Releasing BlockLock ID: ${comparison.blockLock.id}, updated at: ${comparison.blockLock.updated_at}, created_at: ${comparison.blockLock.created_at}, value: ${stringify(comparison.comparison)}`
                )
            })
            await Promise.all(blockLocksToRelease.map(comparison => deleteBlockLock(comparison.blockLock.id)))
            setIsOpeningBlockLock(false)
        }

        const timeout = setTimeout(releaseLocks, 100)

        return () => {
            clearTimeout(timeout)
        }
    }, [options.skip, isOpeningBlockLock])
}
