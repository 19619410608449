import { Dayjs } from 'dayjs'

import { HolidayTag, Show } from '~/components'
import { format } from '~/utils/extendedDayjs'
import { isHoliday } from '~/utils/holidays'

type Props = {
    date: Dayjs
}

export const DayFormatForColumn = ({ date }: Props) => {
    const label = format(date, 'dd DD')

    const dayDateSplit = label.split(' ')
    return (
        <div className="flex items-center justify-center gap-1 whitespace-nowrap capitalize">
            <span className="day text-base text-medium-rest">{dayDateSplit[0]}</span>
            <span className="date flex items-center justify-center rounded text-sm text-medium-rest">{dayDateSplit[1]}</span>
        </div>
    )
}

export const DayColumn = ({ date }: Props) => {
    return (
        <div className="flex flex-col gap-1">
            <DayFormatForColumn date={date} />
            <Show condition={isHoliday(date)} fallback={<></>}>
                <HolidayTag />
            </Show>
        </div>
    )
}
