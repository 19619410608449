import clsx from 'clsx'
import { useCallback, useState } from 'react'

import { AgeGroup, Show } from '~/components'
import {
    ResolvedSurgeryTypeGroup,
    selectHospitalSurgeryTypes,
    selectSurgeryTypeGroup,
    selectSurgeryTypeGroupHierarchies,
    SurgeryTypeGroup,
    SurgeryTypeMetadata,
} from '~/store/selectors'
import { useStore } from '~/store/store'
import { isCompositeSurgeryTypeGroup, isSpecialitySurgeryTypeGroup } from '~/store/utils/surgeryTypeGroupHierarchy'

import { HospitalCode, OverriddenWeightCode, SurgeryTypeGroupCode, WeightCode } from '../components/code'
import { List, ListItem } from '../components/list'

type Props = {
    surgeryTypeGroup: SurgeryTypeGroup
    itemCount: number
    overriddenWeight?: number
    onClick: () => void
    isExpanded: boolean
}

const SurgeryTypeGroupListItemContent = ({ surgeryTypeGroup, itemCount, overriddenWeight, onClick, isExpanded }: Props) => {
    const isExpandable = Boolean(surgeryTypeGroup.subgroups.length) || Boolean(surgeryTypeGroup.surgeryTypes.length)

    return (
        <div
            className={clsx(
                {
                    'cursor-pointer hover:text-diBlue-600': isExpandable,
                },
                'flex flex-row items-center gap-2 border-l-2 pl-2'
            )}
            onClick={onClick}
        >
            {isExpandable && <div className="text-xs">{isExpanded ? '▼' : '▶'}</div>}
            <div>({itemCount}) </div>
            <div>{surgeryTypeGroup.display_name}</div>
            {surgeryTypeGroup.ageRestrictions.map(ageRestriction => {
                return <AgeGroup key={ageRestriction.age_group_code} ageGroupCode={ageRestriction.age_group_code} />
            })}
            <div>
                <SurgeryTypeGroupCode code={surgeryTypeGroup.code} />
            </div>
            <div>({surgeryTypeGroup.group_type})</div>
            <Show condition={Boolean(overriddenWeight)}>
                <div>
                    <OverriddenWeightCode code={overriddenWeight} />
                </div>
            </Show>
        </div>
    )
}

type SurgeryTypeGroupListItemProps = {
    surgeryTypeGroup: SurgeryTypeGroup
    parentSurgeryTypeGroup?: SurgeryTypeGroup
}

const SurgeryTypeGroupListItem = ({ surgeryTypeGroup, parentSurgeryTypeGroup }: SurgeryTypeGroupListItemProps) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const getSurgeryTypeGroupHierarchies = useStore(selectSurgeryTypeGroupHierarchies)
    const getHospitalSurgeryTypes = useStore(selectHospitalSurgeryTypes)

    const handleContentClick = useCallback(() => {
        setIsExpanded(!isExpanded)
    }, [isExpanded])

    const hasChildren = Boolean(surgeryTypeGroup.subgroups.length)
    const hasSurgeryTypes = Boolean(surgeryTypeGroup.surgeryTypes.length)
    const overriddenWeight = getSurgeryTypeGroupHierarchies.byParentAndChildRelationship(parentSurgeryTypeGroup, surgeryTypeGroup)?.weight
    return (
        <ListItem>
            <SurgeryTypeGroupListItemContent
                surgeryTypeGroup={surgeryTypeGroup}
                itemCount={surgeryTypeGroup.subgroups.length + surgeryTypeGroup.surgeryTypes.length}
                overriddenWeight={overriddenWeight ?? undefined}
                onClick={handleContentClick}
                isExpanded={isExpanded}
            />
            <Show condition={hasChildren && isExpanded}>
                <List>
                    {surgeryTypeGroup.subgroups.map(child => {
                        return <SurgeryTypeGroupListItem key={child.id} surgeryTypeGroup={child} parentSurgeryTypeGroup={surgeryTypeGroup} />
                    })}
                </List>
            </Show>
            <Show condition={hasSurgeryTypes && isExpanded}>
                <List>
                    {surgeryTypeGroup.surgeryTypes.map(surgeryType => {
                        const hospital_surgery_type = getHospitalSurgeryTypes.byHospitalId(surgeryType.external_surgery_type_id)
                        const isOverriddenWeight = Boolean(overriddenWeight && overriddenWeight !== surgeryType.weight)

                        return (
                            <div key={surgeryType.id}>
                                <HospitalCode code={hospital_surgery_type?.external_id} />
                                <Show condition={Boolean(surgeryType.weight) && !isOverriddenWeight}>
                                    <WeightCode code={surgeryType.weight} />
                                </Show>
                                <Show condition={isOverriddenWeight}>
                                    <s>
                                        <WeightCode code={surgeryType.weight} />
                                    </s>
                                    <OverriddenWeightCode code={overriddenWeight} />
                                </Show>
                                {` ${hospital_surgery_type?.name}`}
                            </div>
                        )
                    })}
                </List>
            </Show>
        </ListItem>
    )
}

type SurgeryTypeListItemProps = {
    resolvedSurgeryTypeGroup: ResolvedSurgeryTypeGroup
}

function keyToNumber<T>([key, value]: [string, T]): [number, T] {
    return [Number(key), value]
}

const SurgeryTypeListItem = ({ resolvedSurgeryTypeGroup }: SurgeryTypeListItemProps) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const getHospitalSurgeryTypes = useStore(selectHospitalSurgeryTypes)

    const handleContentClick = useCallback(() => {
        setIsExpanded(!isExpanded)
    }, [isExpanded])

    const surgeryTypeMetadatas: [number, SurgeryTypeMetadata][] = Object.entries(resolvedSurgeryTypeGroup.metadata.resolvedSurgeryTypes).map(keyToNumber)

    return (
        <ListItem>
            <SurgeryTypeGroupListItemContent
                surgeryTypeGroup={resolvedSurgeryTypeGroup}
                itemCount={surgeryTypeMetadatas.length}
                onClick={handleContentClick}
                isExpanded={isExpanded}
            />
            <Show condition={surgeryTypeMetadatas && isExpanded}>
                <List>
                    {surgeryTypeMetadatas.map(([hospitalId, surgeryType]) => {
                        const hospitalSurgeryType = getHospitalSurgeryTypes.byHospitalId(hospitalId)

                        return (
                            <div key={hospitalId}>
                                <HospitalCode code={hospitalSurgeryType?.external_id} /> <WeightCode code={surgeryType.weight} />
                                {` ${hospitalSurgeryType?.name}`}
                            </div>
                        )
                    })}
                </List>
            </Show>
        </ListItem>
    )
}

const SurgeryTypeGroups = () => {
    const { all: resolvedSurgeryTypeGroups } = useStore(selectSurgeryTypeGroup)

    return (
        <div className="flex flex-col gap-2">
            {
                <div key="surgery-type-groups" className="rounded border p-2 shadow">
                    <div className="font-semibold">Surgery type groups by specialities</div>
                    <List>
                        {resolvedSurgeryTypeGroups.filter(isSpecialitySurgeryTypeGroup).map(surgeryTypeGroup => (
                            <div key={surgeryTypeGroup.id} className="border p-2 shadow">
                                <SurgeryTypeGroupListItem surgeryTypeGroup={surgeryTypeGroup} />
                            </div>
                        ))}
                    </List>
                </div>
            }
            {
                <div key="composite-surgery-type-groups" className="rounded border p-2 shadow">
                    <div className="font-semibold">Composite surgery type groups</div>
                    <List>
                        {resolvedSurgeryTypeGroups.filter(isCompositeSurgeryTypeGroup).map(surgeryTypeGroup => (
                            <div key={surgeryTypeGroup.id} className="border p-2 shadow">
                                <SurgeryTypeGroupListItem surgeryTypeGroup={surgeryTypeGroup} />
                            </div>
                        ))}
                    </List>
                </div>
            }
            {
                <div key="resolved-surgery-type-groups" className="rounded border p-2 shadow">
                    <div className="font-semibold">Resolved Surgery type groups</div>
                    <List>
                        {resolvedSurgeryTypeGroups.map(resolvedSurgeryTypeGroup => (
                            <div key={resolvedSurgeryTypeGroup.id} className="border p-2 shadow">
                                <SurgeryTypeListItem resolvedSurgeryTypeGroup={resolvedSurgeryTypeGroup} />
                            </div>
                        ))}
                    </List>
                </div>
            }
        </div>
    )
}

export default SurgeryTypeGroups
