import { Dayjs, duration } from 'dayjs'
import { Duration } from 'dayjs/plugin/duration'

import env from '~/env'
import { Location } from '~/store/selectors/entities'

// Temporary hardcoded implementation suffices.
// We can create an entity for it later when it's more apparent what kind of solution we need
const isoMonday = 1
const isoTuesday = isoMonday + 1
const isoWednesday = isoTuesday + 1
const isoThursday = isoWednesday + 1
const isoFriday = isoThursday + 1

function getOpeningHoursLDS(date: Dayjs, location: Location) {
    // from https://www.notion.so/deepinsighio/Opening-hours-at-LDS-1378ab88fe0d80e58b7de1564f531788

    if (location.room_code === 'OPR11' || location.room_code === 'OPR12') {
        return {
            [isoMonday]: { open: '8:00', close: '15:00' },
            [isoTuesday]: { open: '8:00', close: '15:00' },
            [isoWednesday]: { open: '8:00', close: '15:00' },
            [isoThursday]: { open: '8:30', close: '15:00' },
            [isoFriday]: { open: '9:00', close: '15:00' },
        }[date.isoWeekday()]
    } else {
        return {
            [isoMonday]: { open: '8:00', close: '15:30' },
            [isoTuesday]: { open: '8:00', close: '15:30' },
            [isoWednesday]: { open: '8:00', close: '15:30' },
            [isoThursday]: { open: '8:30', close: '15:30' },
            [isoFriday]: { open: '9:00', close: '15:30' },
        }[date.isoWeekday()]
    }
}

function getOpeningHoursNLSH(date: Dayjs, location: Location) {
    // from https://deepinsight-no.slack.com/archives/C06412J927N/p1736519908503399?thread_ts=1736515728.618859&cid=C06412J927N
    if (location.room_code.startsWith('K2')) {
        if (date.isoWeekday() === isoMonday || date.isoWeekday() === isoWednesday || date.isoWeekday() === isoThursday) {
            return { open: '8:00', close: '15:45' }
        } else if (date.isoWeek() === isoTuesday) {
            return { open: '8:30', close: '15:45' }
        } else if (date.isoWeek() === isoFriday) {
            if (location.room_code === 'K2 Stue 1') {
                return { open: '8:30', close: '12:30' }
            } else if (location.room_code === 'K2 Stue 2') {
                return { open: '12:30', close: '15:45' }
            } else {
                return { open: '8:30', close: '15:45' }
            }
        }
    } else if (location.room_code.startsWith('K8')) {
        if (date.isoWeekday() === isoMonday || date.isoWeekday() === isoWednesday || date.isoWeekday() === isoThursday) {
            return { open: '7:45', close: '15:45' }
        } else if (date.isoWeek() === isoTuesday) {
            return { open: '8:15', close: '15:45' }
        } else if (date.isoWeek() === isoFriday) {
            return { open: '8:30', close: '15:45' }
        }
    }

    return undefined
}

function getDuration(openingHours: { open: string; close: string }) {
    const [openingHour, openingMinute] = openingHours.open.split(':')
    const [closingHour, closingMinute] = openingHours.close.split(':')

    const duration_h = Number(closingHour) - Number(openingHour) + (Number(closingMinute) - Number(openingMinute)) / 60
    return duration({ hours: duration_h })
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getMockOpeningHours(date: Dayjs, _location: Location) {
    if (
        date.isoWeekday() === isoMonday ||
        date.isoWeekday() === isoTuesday ||
        date.isoWeekday() === isoWednesday ||
        date.isoWeekday() === isoThursday ||
        date.isoWeekday() === isoFriday
    ) {
        return { open: '8:00', close: '15:30' }
    }
}
export type OpeningHours = { open: string; close: string; duration: Duration }
export function getOpeningHours(date: Dayjs, location: Location, tenant = env.VITE_SEGMENT_TENANT_NAME): OpeningHours {
    let openingHours: { open: string; close: string } | undefined
    if (tenant && tenant.toLocaleLowerCase() === 'nlsh') {
        openingHours = getOpeningHoursNLSH(date, location)
    } else if (tenant && tenant.toLocaleLowerCase() === 'lds') {
        openingHours = getOpeningHoursLDS(date, location)
    } else {
        openingHours = getMockOpeningHours(date, location)
    }

    if (openingHours === undefined) {
        console.error(`Opening hours not known for location '${location.room_code}'`)
        return { open: '??', close: '??', duration: duration({ hours: 0 }) }
    }
    return { ...openingHours, duration: getDuration(openingHours) }
}
