import { motion } from 'framer-motion'

const variants = {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 },
}

type Props = {
    fullHeight?: boolean
    isAnimated?: boolean
}

export const LoadingCard = ({ fullHeight, isAnimated = true }: Props) => (
    <motion.div
        initial={isAnimated ? 'hidden' : 'visible'}
        animate={'visible'}
        variants={variants}
        transition={{ delay: 0.2, duration: 0.3, type: 'linear' }}
        className={`box-border animate-pulse-fast rounded bg-gradient-to-l from-slate-300 via-slate-100 to-slate-300 bg-large ${
            fullHeight ? 'h-full' : 'h-[240px]'
        }`}
    />
)
