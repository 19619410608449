import clsx from 'clsx'

import { useStore } from '~/store/store'

import { FormattedWaitingListItem } from '../../shared/columns'
import { TableRowCells } from './TableRowCells'

type Props = {
    item: FormattedWaitingListItem
    className?: string
    'data-test'?: string
}

export const TableRow = ({ item, className, 'data-test': dataTest }: Props) => {
    const setDrawerProps = useStore(state => state.waitingList.actions.setDrawerProps)
    const setNavigatedByKeyboard = useStore(state => state.waitingList.actions.setNavigatedByKeyboard)
    const drawerProps = useStore(state => state.waitingList.drawerProps)
    const selectedItemByKeyboard = useStore(state => state.waitingList.selectedItemByKeyboard)
    const isSelectedRow = drawerProps?.item.BookingId === item.BookingId

    function toggleDrawer() {
        if (isSelectedRow) {
            setDrawerProps(null)
            return
        }

        setDrawerProps({ item, isDrawerOpen: true })
    }

    return (
        <tr
            className={clsx(className, 'cursor-pointer truncate', {
                'bg-indigo-100': isSelectedRow,
                'hover:bg-indigo-50': !selectedItemByKeyboard && !isSelectedRow,
            })}
            onClick={() => toggleDrawer()}
            onMouseMove={() => setNavigatedByKeyboard(false)}
            id={item.BookingId} // used for scrolling into view
            data-test={dataTest}
        >
            <TableRowCells item={item} />
        </tr>
    )
}
