import clsx from 'clsx'

import { Table } from '~/components'
import { IdDate, Location, selectActiveFilteredLocations, selectWeekIdDates } from '~/store/selectors'
import { useStore } from '~/store/store'
import { getToday, isSameDate } from '~/utils/extendedDayjs'
import { getHolidayStyles } from '~/utils/utils'

import { DayColumn } from '../../shared/table/DayColumn/DayColumn'
import { Cell } from './Cell'
import { PatientSumCell } from './PatientSumCell'

export const WeeklyTable = ({ isLoading }: { isLoading: boolean }) => {
    const weekIdDates = useStore(selectWeekIdDates)
    const activeFilteredLocations = useStore(selectActiveFilteredLocations)
    const { openDrawer } = useStore(state => state.surgeryScheduler.actions)
    const featureFlags = useStore(state => state.featureFlags)

    const cellAction = (row: Location, cell: IdDate) => {
        featureFlags.comments && openDrawer({ location: row, date: cell.date })
    }

    return (
        <Table
            isLoading={isLoading}
            data-test={isLoading ? 'MSS-weekly-table-loading' : 'MSS-weekly-table-loaded'}
            header={{
                row: weekIdDates,
                rowClassName: ({ date }) =>
                    clsx('w-[calc((100vw-116px)/5)]', getHolidayStyles(date), {
                        'calendar-today': isSameDate(date, getToday()),
                    }),
                rowRender: ({ date }) => <DayColumn date={date} />,
            }}
            body={{
                rows: activeFilteredLocations,
                rowRender: ({ room_code }) => (
                    <span className="capitalize" data-test={`room-code-${room_code}`}>
                        {room_code}
                    </span>
                ),
                cellClassName: (_, { id: date }) => clsx(getHolidayStyles(date), '!p-0'),
                cellRender: (location, { date }) => <Cell date={date} location={location} />,
                cellAction: cellAction,
            }}
            footers={[
                {
                    header: 'Pasienter',
                    cellRender: ({ date }) => <PatientSumCell date={date} />,
                },
            ]}
        />
    )
}
