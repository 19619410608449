import { CalendarMonthIcon, EmpathizeIcon, LockIcon, PolyclinicIcon, ScalesIcon } from '~/icons'
import { ChartBubbleIcon } from '~/icons/ChartBubbleIcon'

import { ViewLayout } from '../shared/ViewLayout'
import { AboutCard } from './AboutCard'

export const AboutView = () => (
    <ViewLayout title="Slik foreslår Hero bemanningsplaner">
        <div className="flex h-full flex-col gap-y-3 overflow-auto">
            <AboutCard
                icon={<LockIcon width={24} height={24} />}
                title="Ferie og annet fravær er låst"
                description={
                    <p>
                        Algoritmen foreslår stuefordeling (O,D) og poliklinikkdager kun på dagene som står tomme. Dager som har blitt merket med ferie og annen
                        aktivitet vil forbli uendret.
                    </p>
                }
            />
            <AboutCard
                icon={<ScalesIcon width={24} height={24} />}
                title="Balansering av ventelister mellom kirurger"
                description={
                    <>
                        <p>
                            For å oppnå mer balanserte ventelister over tid prioriterer algoritmen kirurger med lange ventelister på stuene og kirurger med
                            korte ventelister på poliklinikk.
                        </p>
                        <p>
                            Kirurger med lange ventelister må også bidra litt på poliklinikk men i mindre grad. Kirurger med korte ventelister skal også skal få
                            noen dager på stue.
                        </p>
                    </>
                }
            />
            <AboutCard
                icon={<CalendarMonthIcon width={24} height={24} />}
                title="Fokus på å fylle stuene"
                description={
                    <>
                        <p>1. prioritet: Alle stuene skal fylles til normal drift hver dag. </p>
                        <p>2. prioritet: Poliklinikk fylles hvis det er ledige kirurger.</p>
                    </>
                }
            />
            <AboutCard
                icon={<EmpathizeIcon width={24} height={24} />}
                title="Forutsigbarhet i hverdagen"
                description={
                    <p>For å gi mer forutsigbarhet, forsøker algoritmen å prioritere planer der poliklinikk- og operasjonsdager faller på samme ukedag.</p>
                }
            />
            <AboutCard
                icon={<PolyclinicIcon width={24} height={24} />}
                title="Spredning av poliklinikk-dager"
                description={<p>Algoritmen prioriterer bemanningsplaner som har de tildelte poliklinikk-dagene spredt utover planleggingsperioden.</p>}
            />
            <AboutCard
                icon={<ChartBubbleIcon width={24} height={24} />}
                title="Unike månedlige ønsker og regler"
                description={
                    <p>
                        Algoritmen baserer seg også på spesifikke ønsker fra avdelingene. For eksempel er det gitt instrukser på at spesifikke LIS-kirurger er
                        satt opp med erfarne kirurger et visst antall ganger i måneden for å få fullført en spesialisering.
                    </p>
                }
            />
        </div>
    </ViewLayout>
)
