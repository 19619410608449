import { isDurationRuleEvaluation, OccupancyData } from '~/store/selectors'

import { AvailabilityByPatientGroup } from '../../shared/AvailabilityByPatientGroup'
import { AvailabilityByTime } from '../../shared/AvailabilityByTime'
import { UsableTime } from '../../shared/UsableTime'
import { getUsableRoomTime, shouldDisplay } from '../../shared/utils'

type Props = {
    occupancyData: OccupancyData
    'data-test'?: string // of format "<room_code>-<yyyy-MM-dd>"
}

export const AvailabilitiesList = ({ occupancyData, 'data-test': dataTest }: Props) => {
    const usableRoomTime = getUsableRoomTime(occupancyData)
    if (usableRoomTime !== null) {
        return <UsableTime usableAvailableRoomTime={usableRoomTime} occupancyData={occupancyData} />
    }

    return (
        <div className="flex flex-col gap-2">
            {occupancyData.evaluations.filter(shouldDisplay).map((evaluation, index) => {
                if (isDurationRuleEvaluation(evaluation)) {
                    return <AvailabilityByTime key={index} asTag={true} data-test={dataTest} evaluation={evaluation} />
                }
                return <AvailabilityByPatientGroup key={index} data-test={dataTest} evaluation={evaluation} />
            })}
        </div>
    )
}
