import clsx from 'clsx'
import orderBy from 'lodash/orderBy'
import sortBy from 'lodash/sortBy'
import { useEffect, useMemo, useRef, useState } from 'react'

import { SomeEntity } from '~/clients/api-guards'
import { Show, Spinner } from '~/components'
import { useSessionLikeSearchParam } from '~/hooks/useSessionLikeSearchParam'
import { booleanFromURLParam } from '~/utils/guards'
import { highlightNeedlesDebounced } from '~/utils/highlightNeedlesDebounced'

import { Action } from './Action'
import { entities, EntityName, entityNames, isEntityName } from './config'
import { createObjectTransformer, getType } from './utils'

const ITEMS_PER_PAGE = 100

const EntityEditor = () => {
    const [entity, setEntity] = useSessionLikeSearchParam('entity', value => (isEntityName(value) ? value : entityNames[0]))
    const [rawNeedle, setNeedle] = useSessionLikeSearchParam('needle', value => value ?? '')
    const [formatDates, setFormatDates] = useSessionLikeSearchParam('formatDates', value => booleanFromURLParam(value, true))
    const [showMeta, setShowMeta] = useSessionLikeSearchParam('showMeta', value => booleanFromURLParam(value, false))
    const [pageIndex, setPageIndex] = useSessionLikeSearchParam('pageIndex', value => Number(value) ?? 0)

    const [headers, setHeaders] = useState<string[]>([])
    const { fetchEntity, deleteEntity } = entities[entity]
    const [rawItems, setRawItems] = useState<SomeEntity[]>()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true)

            const response = await fetchEntity()
            setRawItems(response.data)

            setIsLoading(false)
        }

        void fetchData()
    }, [fetchEntity])

    const [serializedItems, setSerializedItems] = useState<[string, number][]>([])
    const [filteredIds, setFilteredIds] = useState<string[] | null>(null)
    const tableRef = useRef<HTMLTableElement>(null)

    const transform = useMemo(() => createObjectTransformer(!showMeta, formatDates), [showMeta, formatDates])

    const items = useMemo(() => orderBy(rawItems?.map(transform), 'id', 'desc'), [rawItems, transform])

    const needle = useMemo(() => rawNeedle.trim().toLowerCase(), [rawNeedle])

    // Update the headers
    useEffect(() => {
        if (!items) {
            return
        }

        const headers = items.flatMap((item: Record<string, unknown>) => Object.keys(item))
        const uniqueHeaders = sortBy([...new Set(headers)])
        const withoutId = uniqueHeaders.filter(header => header !== 'id')

        setHeaders(['id', ...withoutId])

        const sItems = items.map(item => {
            return [JSON.stringify(Object.values(item)), Number(item.id)]
        }) as [string, number][]
        setSerializedItems(sItems)
    }, [items, transform])

    // Update filtered ids for full text search
    useEffect(() => {
        if (needle === '') {
            setFilteredIds(null)
            return
        }

        const filtered = serializedItems
            .filter(([serializedItem]) => {
                return serializedItem.toLowerCase().includes(needle)
            })
            .map(([, id]) => String(id))

        setFilteredIds(filtered)
    }, [needle, serializedItems])

    // Reset page index to 0
    useEffect(() => {
        setPageIndex(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entity, rawNeedle, formatDates, showMeta])

    highlightNeedlesDebounced([needle], tableRef)

    const filteredItems = needle !== '' && filteredIds !== null ? items?.filter(item => filteredIds.includes(String(item.id))) : items
    const numHiddenItems = (items?.length ?? 0) - (filteredItems?.length ?? 0)

    const start = pageIndex * ITEMS_PER_PAGE
    const end = start + ITEMS_PER_PAGE
    const paginatedItems = filteredItems?.slice(start, end)
    const numPages = Math.ceil((filteredItems?.length ?? 0) / ITEMS_PER_PAGE)

    async function handleDelete(id: unknown) {
        if (!window.confirm('Are you sure you want to delete this item?')) {
            return
        }

        await deleteEntity(Number(id)).catch(console.error)
    }

    return (
        <>
            <div className="flex h-full w-full flex-col gap-4 divide-y p-4">
                <div className="flex grow items-center gap-4">
                    <Action label="Entity" tooltip="">
                        <select id="entity" value={entity} onChange={e => setEntity(e.target.value as EntityName)}>
                            {entityNames.map(entityName => (
                                <option key={entityName} value={entityName}>
                                    {entityName}
                                </option>
                            ))}
                        </select>
                    </Action>
                    <Action
                        label="Search"
                        tooltip="Full-text search based on the values visible. If Format Dates is selected, only the formatted date will match. If 'Show Meta Fields' is selected, you can search through meta fields."
                    >
                        <input id="search" type="text" placeholder="" value={rawNeedle} onChange={e => setNeedle(e.target.value)} />
                    </Action>
                    <Action label="Format dates" tooltip="More readable dates that are always in the timezone of the Hospital (Europe/Oslo).">
                        <input id="format_dates" type="checkbox" checked={formatDates} onChange={e => setFormatDates(e.target.checked)} />
                    </Action>
                    <Action label="Use meta fields" tooltip="Less interesting fields such as created_at, updated_at, is_active and possibly others.">
                        <input id="show_meta" type="checkbox" checked={showMeta} onChange={e => setShowMeta(e.target.checked)} />
                    </Action>
                    <div>
                        Items: {filteredItems?.length ?? 0} / {items?.length ?? 0}
                    </div>
                    {isLoading && <Spinner className="inline" size="sm" />}
                </div>
                <div className="h-full overflow-auto py-3">
                    <table ref={tableRef}>
                        <thead>
                            <tr>
                                {headers.map(header => (
                                    <th key={header} className="px-2 py-1 text-left">
                                        {header}
                                    </th>
                                ))}
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedItems?.map((item: Record<string, unknown>) => (
                                <tr key={String(item.id)} className="border-b">
                                    {headers.map(header => {
                                        const value = item[header]
                                        const type = getType(value, header)

                                        return (
                                            <td key={header} className="px-2 py-1">
                                                {type === 'array' && Array.isArray(value) && (
                                                    <pre className="m-0 max-h-32 overflow-auto rounded border bg-gray-100 p-1 text-xs">
                                                        [
                                                        {value
                                                            .map(transform)
                                                            .map(obj => JSON.stringify(obj))
                                                            .join(',\n')}
                                                        ]
                                                    </pre>
                                                )}
                                                {type === 'object' && (
                                                    <pre className="m-0 max-h-32 overflow-auto rounded border bg-gray-100 p-1 text-xs">
                                                        {JSON.stringify(transform(value), null, 2)}
                                                    </pre>
                                                )}
                                                {type === 'date-string' && <span className="whitespace-nowrap">{String(value)}</span>}
                                                {type === 'other' && <span>{JSON.stringify(value)}</span>}
                                            </td>
                                        )
                                    })}
                                    <td>
                                        <Show condition={deleteEntity}>
                                            <button className="p-1" onClick={() => handleDelete(item.id)}>
                                                Delete
                                            </button>
                                        </Show>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {numPages > 1 && (
                        <div className="flex flex-row gap-2 p-3">
                            {Array.from({ length: numPages }).map((_, i) => (
                                <a key={i} onClick={() => setPageIndex(i)} className={clsx('cursor-pointer', { underline: pageIndex === i })}>
                                    {i + 1}
                                </a>
                            ))}
                        </div>
                    )}
                    {numHiddenItems > 0 && (
                        <div className="flex flex-row gap-2 p-3 text-gray-500">
                            + {numHiddenItems} hidden items
                            <a onClick={() => setNeedle('')}>Clear search</a>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default EntityEditor
