import dayjs from 'dayjs'
import { Duration } from 'dayjs/plugin/duration'

import { isCountBasedRuleEvaluation, isDurationRuleEvaluation, OccupancyData, OccupancyStatus, RuleEvaluation } from '~/store/selectors'
import { getUsedRoomDuration, summarizeCapacityEvaluations } from '~/store/utils/blockEvaluation'
import { subtractDuration } from '~/utils/extendedDayjs'

export function getAvailableRoomDuration(data: OccupancyData): Duration {
    const usedRoomDuration = getUsedRoomDuration(data)
    const openingHours = data.openingHours.duration

    return subtractDuration(openingHours, usedRoomDuration)
}

const minimumAvailableRoomTime_ms = dayjs.duration({ hours: 1, minutes: 30 }).asMilliseconds()
/**
 * Gets the remaining available time in an OR if it is greater than a fixed threshold; otherwise we return `null`.
 */
export function getUsableRoomDuration(data: OccupancyData): Duration | null {
    const availableDuration = getAvailableRoomDuration(data)

    if (availableDuration.asMilliseconds() >= minimumAvailableRoomTime_ms) {
        return availableDuration
    }

    return null
}

export function getUsableRoomTime(data: OccupancyData): Duration | null {
    // "Usable" room time is available room time that is larger than a fixed minimum.
    // We want to display available time even if we have fully-booked count-based rules.
    // Also when we have non-fully booked count-based rules and mismatched surgeries we want to display the remaining time instead of the remaining counts (assuming it's >minimum).
    // but if there is no plan, we don't show availabilities

    if (data.evaluations.length === 0) {
        return null
    }

    const availableRoomDuration = getUsableRoomDuration(data)
    const isCountBased = data.evaluations.every(isCountBasedRuleEvaluation) // mixes of count- and duration-based currently are not supported
    const isFullyBooked = data.evaluations.filter(isCountBasedRuleEvaluation).every(rule => rule.remaining === 0 || rule.remaining === null)
    const hasMismatches = data.mismatchedSurgeries.length !== 0

    if (isCountBased && isFullyBooked) {
        return availableRoomDuration
    } else if (isCountBased && hasMismatches) {
        return availableRoomDuration
    }
    return null
}

/** Gets whether the block evaluations have any booking. */
export function hasAnyBooking(data: OccupancyData): boolean {
    return data.mismatchedSurgeries.length !== 0 || data.evaluations.some(evaluation => evaluation && evaluation.bookingIds.length !== 0)
}

/** Gets whether the block evaluations have any availabilities. */
export function hasAnyAvailability(data: OccupancyData): boolean {
    const hasUsableRoomTime = getUsableRoomTime(data) !== null
    if (hasUsableRoomTime) {
        return true
    }
    const isCountBased = data.evaluations.every(isCountBasedRuleEvaluation) // mixes of count- and duration-based currently are not supported
    const hasMismatches = data.mismatchedSurgeries.length !== 0
    if (hasMismatches && isCountBased /* and not hasUsableRoomTime */) {
        return false
    }

    return summarizeCapacityEvaluations(data.evaluations) === OccupancyStatus.Available
}

export function shouldDisplay(evaluation: RuleEvaluation) {
    if (isDurationRuleEvaluation(evaluation) && evaluation.maxValue === null) {
        return false
    }

    // Show only when there is some availability
    return evaluation.status === OccupancyStatus.Available
}
