import { Button, Show } from '~/components'
import { useAutoHide } from '~/hooks/useAutoHide'

type Props = {
    note: string
    setNote: (note: string) => void
    updateNote: () => void
    deleteNote: () => void
    isLoading: boolean
    isSuccess: boolean
}

export const Note = ({ deleteNote, note, setNote, updateNote, isSuccess, isLoading }: Props) => {
    const [showSavedMessage, setShowSavedMessage] = useAutoHide(1000)

    function handleSaveNote() {
        updateNote()
        setShowSavedMessage()
    }

    return (
        <div>
            <p className="!mb-1 font-medium">Notat</p>

            <textarea
                className="h-20 w-full rounded-lg border border-gray-300 p-2"
                placeholder="Skriv her"
                value={note}
                onChange={e => setNote(e.target.value)}
            />

            <div className="flex items-center justify-end space-x-2">
                <Show condition={isSuccess && showSavedMessage}>
                    <span className="ml-2 font-medium text-green-500">Lagret</span>
                </Show>
                <Button disabled={isLoading} size="sm" color="delete" onClick={deleteNote}>
                    Fjerne
                </Button>
                <Button disabled={isLoading} size="sm" onClick={handleSaveNote}>
                    Lagre
                </Button>
            </div>
        </div>
    )
}
