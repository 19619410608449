import dayjs from 'dayjs'
import { useEffect, useMemo, useRef } from 'react'

import { Show } from '~/components'
import env from '~/env'
import { HeroDrawer } from '~/hero-ui'
import { selectGetLocations, selectGetOccupancies } from '~/store/selectors'
import { useStore } from '~/store/store'
import { getBookingIdOrId } from '~/utils/dips'

import { BookedSurgeries } from './BookedSurgeries/BookedSurgeries'
import { BookingDialog } from './BookingDialog/BookingDialog'
import { Header } from './Header/Header'
import { PatientSuggestions } from './PatientSuggestions/PatientSuggestions'
import { selectSelectedSurgery } from './selectSelectedSurgery'

export const BookingDrawer = () => {
    const getOccupancies = useStore(selectGetOccupancies)
    const drawerProps = useStore(state => state.operationalPlanner.drawerProps)
    const selectedSurgery = useStore(selectSelectedSurgery)
    const { setSelectedSurgery } = useStore(state => state.operationalPlanner.actions)

    // This key makes sure that the BookingDrawer component state is reset when the user clicks on a different date/location
    const key = useMemo(() => JSON.stringify(drawerProps), [drawerProps])

    const scrollRef = useRef<HTMLDivElement>(null)
    // Reset the scroll position when the user clicks on a different date/location
    // This makes sure we always trigger the IntersectionObserver in the PatientSuggestions component
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTo(0, 0)
        }
    }, [drawerProps])

    const { locationId, unixDate } = drawerProps ?? {}
    const getLocations = useStore(selectGetLocations)
    const location = locationId !== undefined ? getLocations.byId(locationId) : null
    const date = dayjs.unix(unixDate ?? 0).startOf('day')

    if (!location || !unixDate) return null

    const occupancyData = getOccupancies.byDateAndLocation(date, location)

    return (
        <HeroDrawer dataTest="BookingDrawer" key={key}>
            <div className="flex w-drawer-large flex-col gap-6">
                <Header roomCode={location.room_code} date={date} />
                <div className="flex flex-col gap-6 overflow-auto pb-2" ref={scrollRef}>
                    <BookedSurgeries location={location} date={date} />
                    <Show condition={env.VITE_FF_OPERATIONAL_PLANNER_WAITINGLIST_IN_DRAWER}>
                        <PatientSuggestions location={location} date={date} occupancyData={occupancyData} />
                    </Show>
                </div>
                {selectedSurgery && (
                    <BookingDialog
                        key={getBookingIdOrId(selectedSurgery)}
                        isOpen={selectedSurgery !== null}
                        onCloseDialog={() => setSelectedSurgery(null)}
                        surgery={selectedSurgery}
                        occupancyData={occupancyData}
                    />
                )}
            </div>
        </HeroDrawer>
    )
}
