import { Dayjs } from 'dayjs'
import moize from 'moize'

import { Id, TableCell } from '~/components'
import { Practitioner, PractitionerSchedule } from '~/store/selectors'
import { DepartmentKey } from '~/store/slices/filterSlice'
import { dayOfWeek } from '~/utils/extendedDayjs'

export function keyFromTableCells(tableCells: TableCell<{ id: Id }, { id: Id }>[]) {
    return tableCells.map(cell => `${cell.row},${cell.column}`).join(';')
}

export function getFridayStyles(date: Dayjs) {
    const fridayStyles = ' border-r-emphasis-active'
    return dayOfWeek(date) === 5 ? fridayStyles : ''
}

export function isDepartmentIncluded(departmentKey: DepartmentKey, departmentIds: number[]) {
    // Checking if the department is in the list of departments
    // Or if the list is empty, then we ignore the check
    return departmentKey === 'all' || departmentIds.length === 0 || departmentIds.includes(Number(departmentKey))
}

export const comparePractitionersOrder = moize(
    (departmentKey: DepartmentKey, a?: Practitioner, b?: Practitioner) => {
        // We allow here for undefined because the data might still be loading
        if (!a || !b) return 0

        if (departmentKey === 'all') {
            return a.short_name.localeCompare(b.short_name)
        } else {
            const orderA = a.departmentAssignments.find(assignment => assignment.department?.id === departmentKey)?.order ?? 0
            const orderB = b.departmentAssignments.find(assignment => assignment.department?.id === departmentKey)?.order ?? 0
            return orderA - orderB
        }
    },
    { maxSize: 1000 }
)

export type DatePractitionerCell = {
    date: Dayjs
    practitionerId: number
}

export const isActivePractitioner = (filteredPractitioner: Practitioner[], schedule?: PractitionerSchedule) =>
    filteredPractitioner.map(practitioner => practitioner.id).includes(Number(schedule?.practitioner?.id))
