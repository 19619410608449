import dayjs, { Dayjs } from 'dayjs'
import moize from 'moize'
import { createSelector } from 'reselect'

import { StoreState } from '~/store/store'
import { day, format, getCalendarDates, getOnlyDate, getWeekdaysBetween, getWorkingDaysInWeek } from '~/utils/extendedDayjs'
import { getEntityDateArgs } from '~/utils/utils'

export type IdDate = {
    id: string
    date: Dayjs
}

function formatDateToIdDate(date: Dayjs): IdDate {
    return { id: getOnlyDate(date), date }
}

const getEntityDateArgsByMonth = moize(
    (date: Dayjs) => {
        const start = date.startOf('month')
        const end = start.add(1, 'month').startOf('month')

        return getEntityDateArgs(format(start), format(end))
    },
    { maxSize: 100 }
)

export const selectDateArgs = createSelector((state: StoreState) => state.appFilters.selectedDate, getEntityDateArgsByMonth)

export const selectMonthIdDates = createSelector(
    (state: StoreState) => state.appFilters.selectedDate,
    selectedDate => {
        const daysOfMonth = getCalendarDates(selectedDate)
        return daysOfMonth.map(formatDateToIdDate)
    }
)

export const selectWeekIdDates = createSelector(
    (state: StoreState) => state.appFilters.selectedDate,
    selectedDate => {
        const daysOfWeek = getWorkingDaysInWeek(selectedDate)
        return daysOfWeek.map(formatDateToIdDate)
    }
)

export const selectWeek = createSelector(
    (state: StoreState) => state.appFilters.selectedDate,
    selectedDate => ({
        startTime: format(selectedDate.startOf('isoWeek')),
        endTime: format(selectedDate.endOf('isoWeek')),
    })
)

export const selectWeekDates = createSelector(selectWeek, ({ startTime, endTime }) => {
    return getWeekdaysBetween(day(startTime), day(endTime)).map(date => ({ id: format(date), date }))
})

export const selectDrawerDates = createSelector(
    (state: StoreState) => state.operationalPlanner.drawerProps,
    drawerProps => {
        const unixDate = drawerProps?.unixDate
        const date = dayjs.unix(unixDate ?? 0).startOf('day')
        const startTime = format(date)
        const endTime = format(date.endOf('day').add(1, 'day'))

        return { date, startTime, endTime }
    }
)
