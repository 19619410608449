import { ReactNode } from 'react'

type Props = {
    icon: ReactNode
    title: ReactNode
    description: ReactNode
}

export const AboutCard = ({ icon, title, description }: Props) => (
    <div className="flex flex-col rounded-lg border border-gray-200 bg-white px-4 pb-3 pt-2">
        <div className="flex items-center gap-x-2 pb-3 pt-2">
            {icon}
            <p className="text-base">{title}</p>
        </div>

        <div className="flex flex-col gap-y-2 text-medium-rest">{description}</div>
    </div>
)
