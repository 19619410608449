import { Slot } from '@radix-ui/react-slot'
import { ElementType, forwardRef, HTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

import { FontWeight } from '../../../types/types'

// Font weights from the Astra design system
const fontWeight: Record<FontWeight, string> = {
    regular: 'font-hero-regular',
    medium: 'font-hero-medium',
    strong: 'font-hero-strong',
}

const fontSize: Record<string, string> = {
    h1: 'text-h1 leading-h1 tracking-h1',
    h2: 'text-h2 leading-h2 tracking-h2',
    h3: 'text-h3 leading-h3 tracking-h3',
}

type Props = HTMLAttributes<HTMLHeadingElement> & {
    as?: ElementType
    asChild?: boolean
    weight?: FontWeight
}

export const HeroHeading = forwardRef<HTMLHeadingElement, Props>(({ children, as = 'h1', weight = 'strong', asChild, className, ...props }, ref) => {
    const Comp = asChild ? Slot : as

    return (
        <Comp ref={ref} className={`${twMerge(fontSize[as as string] ?? '', weight ? fontWeight[weight] : '')} ${className ?? ''}`} {...props}>
            {children}
        </Comp>
    )
})

HeroHeading.displayName = 'HeroHeading' // Necessary for better debugging when using forwardRef
