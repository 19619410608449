import * as Accordion from '@radix-ui/react-accordion'

import { ChevronDownIcon } from '~/icons'

import { Button } from '../../../components/Button'
import { HeroParagraph } from '../Typography/HeroParagraph/HeroParagraph'

export type Item<T extends string> = {
    title: T
    content: React.ReactNode
}

type Props<T extends string> = {
    items: Item<T>[]
    defaultOpen?: Array<T>
}

export const HeroAccordion = <T extends string>({ items, defaultOpen }: Props<T>) => (
    <Accordion.Root type="multiple" defaultValue={defaultOpen} data-test="hero-accordion">
        {items.map((item, index) => (
            <Accordion.Item key={index} value={item.title} className="pb-6">
                <Accordion.Trigger asChild className="group">
                    <Button color="custom" className="flex items-center bg-white">
                        <HeroParagraph>
                            <span>{item.title}</span>
                        </HeroParagraph>
                        <ChevronDownIcon className="transition-transform duration-300 ease-in-out group-data-[state=open]:rotate-180" aria-hidden />
                    </Button>
                </Accordion.Trigger>

                <Accordion.Content asChild>
                    <div className="data-[state=closed]:animate-slideHide data-[state=open]:animate-slideShow">{item.content}</div>
                </Accordion.Content>
            </Accordion.Item>
        ))}
    </Accordion.Root>
)
