import { Dayjs } from 'dayjs'
import { useState } from 'react'

import { BlockScheduleEditor } from '~/components'
import { HeroPopover, HeroTooltip } from '~/hero-ui'
import { EditIcon } from '~/icons'
import { Location } from '~/store/selectors'
import { useStore } from '~/store/store'

import { ResolvedRule } from './ResolvedRule'

type Props = {
    date: Dayjs
    location: Location
}

export const Cell = ({ date, location }: Props) => {
    const featureFlags = useStore(state => state.featureFlags)

    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    const { openDrawer } = useStore(state => state.surgeryScheduler.actions)
    const selectedLocationDay = useStore(state => state.surgeryScheduler.selectedLocationDay)

    const isSelected = selectedLocationDay?.locationId === location.id && selectedLocationDay?.unixDate === date.unix()

    const closePopover = () => setIsPopoverOpen(false)

    return (
        <div
            className={`group relative flex h-full min-h-table-cell ${featureFlags.comments ? 'cursor-pointer' : ''} flex-col p-1.5 text-left ${isSelected ? 'fake-border bg-fill-hover' : ''}`}
            onClick={() => {
                featureFlags.comments && openDrawer({ location, date })
            }}
        >
            <ResolvedRule date={date} location={location} />
            <HeroPopover
                noPadding
                onEscapeKeyDown={closePopover}
                isOpen={isPopoverOpen}
                onPointerDownOutside={closePopover}
                popoverContent={<BlockScheduleEditor date={date} location={location} onRemove={closePopover} onSubmit={closePopover} />}
            >
                <HeroTooltip tooltipContent="Legg til kapasitet" delayDuration={6000}>
                    <button
                        onClick={() => setIsPopoverOpen(true)}
                        tabIndex={0}
                        className="absolute right-1 top-1 rounded-lg bg-fill-rest p-1 opacity-0 shadow transition-opacity hover:opacity-100 focus:opacity-100 group-hover:opacity-100 group-focus/cell:opacity-100"
                    >
                        <EditIcon />
                    </button>
                </HeroTooltip>
            </HeroPopover>
        </div>
    )
}
