import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { pages } from '~/app/routes'
import { HeroListItem } from '~/hero-ui'
import { ArrowBackUpIcon, ArrowUpIcon, CheckIcon, EditIcon, TrashIcon } from '~/icons'
import { updatePlanningPeriod } from '~/store/di-entity.api'
import { PlanningPeriod } from '~/store/selectors'
import { useStore } from '~/store/store'
import { day } from '~/utils/extendedDayjs'

import { CreatePeriodDialog } from '../../../shared/CreatePeriodDialog'
import { DeleteDialog } from '../../../shared/DeleteDialog'
import { ProgressIcon } from '../../../shared/ProgressIcon'
import { UnpublishDialog } from '../../../shared/UnpublishDialog'
import { PlanningPeriodMetrics } from './PlanningPeriodMetrics'

type Props = {
    planningPeriod: PlanningPeriod
    status: 'future' | 'current' | 'completed'
}

const iconStyles = 'w-4 h-4'

export default function PlanningPeriodListItem({ planningPeriod, status }: Props) {
    const [editOpen, setEditOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [unpublishOpen, setUnpublishOpen] = useState(false)

    const addToast = useStore(state => state.toaster.actions.addToast)
    const { setSelectedDate } = useStore(state => state.appFilters.actions)
    const navigate = useNavigate()

    async function onPublish() {
        await updatePlanningPeriod(planningPeriod.id, { ...planningPeriod, is_published: true })
        addToast({ type: 'success', message: 'Perioden er publisert' })
    }

    const isPublished = !!planningPeriod.is_published

    const menuOptions =
        status !== 'completed'
            ? [
                  {
                      label: 'Rediger',
                      action: () => setEditOpen(true),
                      icon: <EditIcon className={iconStyles} />,
                  },
                  {
                      label: 'Slett',
                      action: () => setDeleteOpen(true),
                      icon: <TrashIcon className={iconStyles} />,
                      variant: 'delete',
                  },
              ]
            : [
                  {
                      label: 'Slett',
                      action: () => setDeleteOpen(true),
                      icon: <TrashIcon className={iconStyles} />,
                      variant: 'delete',
                  },
              ]

    if (isPublished && status !== 'completed') {
        menuOptions.unshift({
            label: 'Avpubliser',
            action: () => setUnpublishOpen(true),
            icon: <ArrowBackUpIcon className={iconStyles} />,
        })
    }

    return (
        <>
            <HeroListItem
                mainAction={{
                    label: isPublished ? 'Publisert' : 'Publiser',
                    action: () => onPublish(),
                    disabled: isPublished,
                    icon: isPublished ? <CheckIcon className={iconStyles} /> : <ArrowUpIcon className={iconStyles} />,
                }}
                cardAction={{
                    tooltip: 'Kalender for perioden',
                    action: () => {
                        setSelectedDate(day(planningPeriod.start_date)), navigate(pages.SURGERY_SCHEDULER.views['surgery-scheduler'].path)
                    },
                }}
                menuOptions={menuOptions}
                titleSlot={
                    <div className="flex items-center gap-2 text-ellipsis">
                        <ProgressIcon planningPeriod={planningPeriod} />
                        <p className="text-ellipsis text-nowrap text-base" data-test="planning-period-name">
                            {planningPeriod.name}
                        </p>
                        <hr className="h-4 w-[1px] bg-slate-300" />
                        <p className="text-nowrap text-xs text-slate-500">{`Uke ${day(planningPeriod.start_date).isoWeek()} - ${day(planningPeriod.end_date).isoWeek()}`}</p>
                    </div>
                }
                contentSlot={<PlanningPeriodMetrics planningPeriodId={planningPeriod.id} />}
            />
            <CreatePeriodDialog id={planningPeriod.id} open={editOpen} onClose={() => setEditOpen(false)} />
            <DeleteDialog id={planningPeriod.id} open={deleteOpen} onClose={() => setDeleteOpen(false)} />
            <UnpublishDialog id={planningPeriod.id} open={unpublishOpen} onClose={() => setUnpublishOpen(false)} />
        </>
    )
}
