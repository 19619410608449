import { EmptyItem } from '~/app/pages/OperationalPlanner/BookingDrawer/shared/EmptyItem'
import { Spinner } from '~/components'
import { OccupancyData, selectGetScheduledPractitioners } from '~/store/selectors'
import { useStore } from '~/store/store'

import { BookableCard } from './BookableCard'

type Props = {
    bookableOccupancies: OccupancyData[] | undefined
    onClick: (selectedOccupancy: OccupancyData) => void
}

export const AvailableLocations = ({ bookableOccupancies, onClick }: Props) => {
    const getScheduledPractitioners = useStore(selectGetScheduledPractitioners)

    if (bookableOccupancies === undefined) {
        // `selection === undefined` means we are loading; if nothing is found then `selection === []` holds.
        return <Spinner />
    }
    if (bookableOccupancies.length !== 0) {
        const scheduledPractitioners = getScheduledPractitioners.byDateAndLocationsIds(
            bookableOccupancies[0]!.date,
            bookableOccupancies.map(occ => occ.location.id)
        )

        return bookableOccupancies.map(occ => (
            <BookableCard key={occ.location.id} scheduledPractitioners={scheduledPractitioners[occ.location.id] ?? []} occupancyData={occ} onClick={onClick} />
        ))
    }
    return (
        <EmptyItem>
            <p>Ingen ledig tid funnet. Bruk datovelgeren for å lete lengre frem i tid.</p>
        </EmptyItem>
    )
}
