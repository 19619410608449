import { Dayjs } from 'dayjs'

import { PractitionerTag, RuleValuesTags, Show } from '~/components'
import { HeroTooltip } from '~/hero-ui'
import { Location, selectGetBlockSchedules, selectGetPractitionerSchedules, selectSurgeryTypeGroup } from '~/store/selectors'
import { useStore } from '~/store/store'
import { rulesResolver } from '~/utils/blockEvaluation'
import { getPractitionerFullname } from '~/utils/dips/practitioner'
import { day, humanizeDate } from '~/utils/extendedDayjs'
import { isNotNullish } from '~/utils/guards'

import { Duration } from './Duration'
import { Utilization } from './Utilization'

type Props = {
    date: Dayjs
    location: Location
}

export const ResolvedRule = ({ date, location }: Props) => {
    const getSurgeryTypeGroup = useStore(selectSurgeryTypeGroup)
    const getBlockSchedules = useStore(selectGetBlockSchedules)
    const getPractitionerSchedules = useStore(selectGetPractitionerSchedules)

    const blockSchedule = getBlockSchedules.byDateAndLocationId(date, location.id)
    const resolvedRule = rulesResolver({ rules: blockSchedule?.rule_instance, getSurgeryTypeGroup })
    const editInfo = blockSchedule && `Sist endret av ${blockSchedule.updated_by} ${humanizeDate(day(blockSchedule.updated_at))}`
    const practitioners = getPractitionerSchedules
        .byDateAndLocationId(day(date), location.id)
        .map(ps => ps.practitioner)
        .filter(Boolean)

    const showBlockMetrics = !!blockSchedule?.id && blockSchedule.rule_instance?.rule_definition_id === 'max_count_of_surgeries_per_patient_group'

    return (
        <>
            <div className="flex w-full gap-1">
                {showBlockMetrics && (
                    <>
                        <Utilization blockSchedule={blockSchedule} />
                        <Duration blockSchedule={blockSchedule} />
                    </>
                )}
            </div>

            <HeroTooltip tooltipContent={editInfo}>
                <div data-test={`block-schedule-${blockSchedule?.id}`} className="flex w-full grow flex-col justify-between gap-1">
                    <div className="flex flex-col gap-2">
                        <Show condition={isNotNullish(blockSchedule)}>
                            <RuleValuesTags fullWidth size="md" resolvedConstraints={resolvedRule} />
                        </Show>
                    </div>

                    <div className="flex gap-2 px-0 pt-2">
                        {practitioners.map(practitioner => (
                            <PractitionerTag
                                key={practitioner.short_name}
                                shortName={practitioner.short_name}
                                fullName={getPractitionerFullname(practitioner)}
                            />
                        ))}
                    </div>
                </div>
            </HeroTooltip>
        </>
    )
}
