import { ComponentProps, FC } from 'react'

export const PolyclinicIcon: FC<ComponentProps<'svg'>> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="currentColor" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.14479 5.35105C6.14479 4.99206 6.4358 4.70105 6.79479 4.70105H8.34657C8.93051 4.70105 9.49055 4.93302 9.90346 5.34593C10.3164 5.75885 10.5483 6.31888 10.5483 6.90283C10.5483 7.48677 10.3164 8.0468 9.90346 8.45972C9.49055 8.87263 8.93051 9.1046 8.34657 9.1046H7.44479V10.9488C7.44479 11.3077 7.15377 11.5988 6.79479 11.5988C6.4358 11.5988 6.14479 11.3077 6.14479 10.9488V5.35105ZM7.44479 7.8046H8.34657C8.58573 7.8046 8.81511 7.70959 8.98422 7.54048C9.15334 7.37136 9.24835 7.14199 9.24835 6.90283C9.24835 6.66366 9.15334 6.43429 8.98422 6.26517C8.81511 6.09606 8.58573 6.00105 8.34657 6.00105H7.44479V7.8046Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.0312 12.9423V3.66095C13.0312 3.15932 12.8319 2.67823 12.4772 2.32352C12.1225 1.96881 11.6414 1.76953 11.1398 1.76953H4.93265C4.43102 1.76953 3.94992 1.96881 3.59521 2.32352C3.2405 2.67823 3.04123 3.15932 3.04123 3.66095V12.9423H2.4498C2.09082 12.9423 1.7998 13.2334 1.7998 13.5923C1.7998 13.9513 2.09082 14.2423 2.4498 14.2423H13.6226C13.9816 14.2423 14.2726 13.9513 14.2726 13.5923C14.2726 13.2334 13.9816 12.9423 13.6226 12.9423H13.0312ZM4.93265 3.06953C4.7758 3.06953 4.62537 3.13184 4.51445 3.24276C4.40354 3.35367 4.34123 3.5041 4.34123 3.66095V12.9423H11.7312V3.66095C11.7312 3.5041 11.6689 3.35367 11.558 3.24276C11.4471 3.13184 11.2966 3.06953 11.1398 3.06953H4.93265Z"
            fill="currentColor"
        />
    </svg>
)
