export const defaultErrorMessage = 'Det oppstod en feil. Vennligst prøv igjen.'

export const errorMessageByStatusCode: Record<number, string> = {
    401: 'Du er ikke autentisert. Vennligst logg inn igjen.',
    403: 'Du mangler rettigheter til å utføre denne handlingen.',
    404: 'Ressursen ble ikke funnet.',
    409: 'Konflikt med eksisterende ressurs.',
    500: 'Det oppstod en serverfeil. Vennligst prøv igjen.',
}

export function getErrorMessageByStatusCode(statusCode?: number): string {
    return statusCode ? (errorMessageByStatusCode[statusCode] ?? defaultErrorMessage) : defaultErrorMessage
}

export const networkErrorMessage = 'Det oppstod en nettverksfeil. Vennligst prøv igjen.'

export const errorMessageByStatusCodeGroup: Record<number, string> = {
    4: 'Det oppstod en klientfeil. Vennligst prøv igjen.',
    5: 'Det oppstod en serverfeil. Vennligst prøv igjen.',
}

export function getErrorMessageByStatusCodeGroup(statusCodeGroup: 4 | 5): string {
    return errorMessageByStatusCodeGroup[statusCodeGroup] || defaultErrorMessage
}
