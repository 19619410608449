import { HeroBadge, HeroDonutChart, HeroSkeletonLoader, HeroTooltip } from '~/hero-ui'
import { useUtilizationQuery } from '~/hooks/tanstack-query/optimus/useUtilizationQuery'
import { BlockSchedule } from '~/store/selectors'

type Props = {
    blockSchedule: BlockSchedule | null
}

export const Utilization = ({ blockSchedule }: Props) => {
    // 'can' refers to the current limitation of Optimus API to only provide duration data for block schedules
    // with the rule_definition_id of 'max_count_of_surgeries_per_patient_group'
    const canGenerateData = !!blockSchedule?.id && blockSchedule.rule_instance?.rule_definition_id === 'max_count_of_surgeries_per_patient_group'

    const { data, isPending, isLoading, error } = useUtilizationQuery({
        request: { blockScheduleId: blockSchedule?.id ?? 0, updatedAt: blockSchedule?.updated_at ?? '' },
        isEnabled: canGenerateData,
    })

    return (
        <>
            {isPending && <div data-test="utilization-chart-isloading" hidden />}

            <HeroTooltip side="top" tooltipContent={<p>{data?.description ?? 'loading'}</p>} className="w-48">
                {!error && (
                    <div className="mb-2">
                        {isLoading ? (
                            <div className="h-6 w-14">
                                <HeroSkeletonLoader />
                            </div>
                        ) : (
                            <HeroBadge
                                text={`${data && (data?.utilization * 100).toPrecision(2)}%`}
                                data-test="utilization-chart-loaded"
                                variant="outlined"
                                icon={
                                    <HeroDonutChart
                                        showTooltip={false}
                                        className="h-4 w-4"
                                        data={[
                                            {
                                                name: 'Utilization',
                                                utilization: data?.utilization ?? 0,
                                            },
                                            {
                                                name: 'Available',
                                                utilization: 1 - (data?.utilization ?? 0),
                                            },
                                        ]}
                                        category="name"
                                        value="utilization"
                                        colors={['emerald', 'gray']}
                                    />
                                }
                            />
                        )}
                    </div>
                )}
            </HeroTooltip>
        </>
    )
}
