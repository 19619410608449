import { memo } from 'react'

import { InsightsDrawer } from '~/app/pages/SurgeonScheduler/shared/PractitionerTable/InsightsDrawer/InsightsDrawer'
import { useEvaluationQuery } from '~/hooks/tanstack-query/optimus/useEvaluationQuery'
import { selectGetPractitioners, selectMonthIdDates } from '~/store/selectors'
import { selectPractitionerValues } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'

import { PractitionerTable } from '../shared'
import { useCreateInteractiveRows } from './hooks'
import { KeyboardInteraction } from './KeyboardInteraction'
import { PractitionerScheduleModal } from './Modal/PractitionerScheduleModal'
import { useGetCommentStyles } from './selectors'
import { useGetSchedules } from './selectors/useGetSchedules'

type Props = {
    isLoading: boolean
}

const PractitionerViewComponent = ({ isLoading }: Props) => {
    const departments = useStore(state => state.di.entities.departments)
    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const selectedDate = useStore(state => state.appFilters.selectedDate)

    const monthIdDates = useStore(selectMonthIdDates)
    const filteredPractitioners = useStore(selectPractitionerValues)
    const getPractitioners = useStore(selectGetPractitioners)

    const department = departmentKey !== 'all' ? departments.byId[departmentKey]?.name : 'Alle'
    const practitionersByDepartment = getPractitioners.byDepartmentKey(departmentKey)
    const activePractitioners = practitionersByDepartment.filter(
        practitioner => filteredPractitioners.length === 0 || filteredPractitioners.includes(practitioner.short_name)
    )

    const { getCommentStyles } = useGetCommentStyles()
    const { getOrderedStatuses, getLocationSchedules } = useGetSchedules()
    const { createPractitionersSelect, createCountable } = useCreateInteractiveRows()

    const { data: evaluation, isLoading: isSidebarLoading } = useEvaluationQuery({
        departmentKey,
        year: selectedDate.year(),
        month: selectedDate.month() + 1,
    })

    return (
        <PractitionerTable
            isLoading={isLoading}
            selection="multiple"
            monthIdDates={monthIdDates}
            activePractitioners={activePractitioners}
            getOrderedStatuses={getOrderedStatuses}
            getLocationSchedules={getLocationSchedules}
            createEditableRow={createPractitionersSelect}
            createCountableRow={createCountable}
            getCommentStyles={getCommentStyles}
            modal={selectedCells => <PractitionerScheduleModal selectedCells={selectedCells} />}
            keyboardInteractionComponent={(selectedCells, isModalOpen) => <KeyboardInteraction selectedCells={selectedCells} isModalOpen={isModalOpen} />}
            sidebar={<InsightsDrawer departmentName={department ?? ''} date={selectedDate} isSidebarLoading={isSidebarLoading} evaluation={evaluation ?? []} />}
        />
    )
}

export const PractitionerView = memo(PractitionerViewComponent)
