import * as Tooltip from '@radix-ui/react-tooltip'
import { TooltipContentProps } from '@radix-ui/react-tooltip'
import { motion } from 'framer-motion'
import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = {
    children: React.ReactNode
    tooltipContent: React.ReactNode
    onOpenChange?: (open: boolean) => void
    delayDuration?: number
    showArrow?: boolean
} & TooltipContentProps

const tooltipInitialVariants = {
    top: { opacity: 0, y: 12 },
    right: { opacity: 0, x: -12 },
    bottom: { opacity: 0, y: -12 },
    left: { opacity: 0, x: 12 },
}

const tooltipAnimateVariants = {
    top: { opacity: 1, y: 0 },
    right: { opacity: 1, x: 0 },
    bottom: { opacity: 1, y: 0 },
    left: { opacity: 1, x: 0 },
}

export const HeroTooltip = forwardRef<HTMLButtonElement, Props>(
    ({ children, showArrow = true, tooltipContent, className, delayDuration, ...contentProps }, ref) => {
        return (
            <Tooltip.Provider delayDuration={delayDuration}>
                <Tooltip.Root>
                    <Tooltip.Trigger asChild ref={ref}>
                        {children}
                    </Tooltip.Trigger>
                    <Tooltip.Portal>
                        {tooltipContent && (
                            <Tooltip.Content
                                asChild
                                sideOffset={5}
                                {...contentProps}
                                className={twMerge('w-fit rounded border bg-surface-on-inverted px-2 py-1 text-xs text-white shadow-lg', className)}
                            >
                                <motion.div
                                    initial={tooltipInitialVariants[contentProps.side ?? 'top']}
                                    animate={tooltipAnimateVariants[contentProps.side ?? 'top']}
                                    transition={{ type: 'linear' }}
                                >
                                    {showArrow && <Tooltip.Arrow className="translate-y-[-1px] fill-slate-700" />}
                                    {tooltipContent}
                                </motion.div>
                            </Tooltip.Content>
                        )}
                    </Tooltip.Portal>
                </Tooltip.Root>
            </Tooltip.Provider>
        )
    }
)
