/**
 * This file is a generic fetch client that does not know about React or store life cycles.
 * It should be treated as simply a convenience wrapper around the generated API client.
 */
import createClient from 'openapi-fetch'

import env from '~/env'

import * as apiGuards from './api-guards'
import { components, operations, paths } from './generated-api-types'
import { apiRefreshingMiddleware } from './unauthorizedHandlingMiddleware'

export const baseClient = createClient<paths>({ baseUrl: env.VITE_API_BASE_URL, credentials: 'include' })
baseClient.use(apiRefreshingMiddleware)

export type Schemas = components['schemas']

/**
 * The client is a wrapper around .GET, .POST and so on methods that help bind the path and query parameters to the response objects.
 * This allows us to use to instantiate generic "import*" methods in the store.
 */
const client = {
    ...apiGuards,

    // GET Operations
    getHolidaysPerYear: async (year: number) => await baseClient.GET('/holidays/{year}', { params: { path: { year } } }),
    fetchBlockSchedule: async (id: number) => await baseClient.GET('/block_schedules/{id}', { params: { path: { id } } }),
    fetchBlockSchedules: async (query: operations['getBlockSchedules']['parameters']['query']) =>
        await baseClient.GET('/block_schedules/', { params: { query } }),
    fetchBlockLock: async (id: number) => await baseClient.GET('/block_locks/{id}', { params: { path: { id } } }),
    fetchBlockLocks: async (query: operations['getBlockLocks']['parameters']['query']) => await baseClient.GET('/block_locks/', { params: { query } }),
    fetchComments: async (query: operations['getComments']['parameters']['query']) => await baseClient.GET('/comments/', { params: { query } }),
    fetchDepartment: async (id: number) => await baseClient.GET('/departments/{id}', { params: { path: { id } } }),
    fetchDepartmentLocationAssignment: async (id: number) => await baseClient.GET('/department_location_assignments/{id}', { params: { path: { id } } }),
    fetchDepartmentLocationAssignments: async (query: operations['getDepartmentLocationAssignments']['parameters']['query']) =>
        await baseClient.GET('/department_location_assignments/', { params: { query } }),
    fetchDepartmentPractitionerAssignment: async (id: number) =>
        await baseClient.GET('/department_practitioner_assignments/{id}', { params: { path: { id } } }),
    fetchDepartmentPractitionerAssignments: async (query: operations['getDepartmentPractitionerAssignments']['parameters']['query']) =>
        await baseClient.GET('/department_practitioner_assignments/', { params: { query } }),
    fetchDepartments: async (query: operations['getDepartments']['parameters']['query']) => await baseClient.GET('/departments/', { params: { query } }),
    fetchLocation: async (id: number) => await baseClient.GET('/locations/{id}', { params: { path: { id } } }),
    fetchLocations: async (query: operations['getLocations']['parameters']['query']) => await baseClient.GET('/locations/', { params: { query } }),
    fetchLocationSchedule: async (id: number) => await baseClient.GET('/location_schedules/{id}', { params: { path: { id } } }),
    fetchLocationSchedules: async (query: operations['getLocationSchedules']['parameters']['query']) =>
        await baseClient.GET('/location_schedules/', { params: { query } }),
    fetchPlanningPeriod: async (id: number) => await baseClient.GET('/planning_periods/{id}', { params: { path: { id } } }),
    fetchPlanningPeriods: async (query: operations['getPlanningPeriods']['parameters']['query']) =>
        await baseClient.GET('/planning_periods/', { params: { query } }),
    fetchPractitioner: async (id: number) => await baseClient.GET('/practitioners/{id}', { params: { path: { id } } }),
    fetchPractitioners: async (query: operations['getPractitioners']['parameters']['query']) => await baseClient.GET('/practitioners/', { params: { query } }),
    fetchPractitionerSchedule: async (id: number) => await baseClient.GET('/practitioner_schedules/{id}', { params: { path: { id } } }),
    fetchPractitionerServicePeriods: async (query: operations['getPractitionerServicePeriods']['parameters']['query']) =>
        await baseClient.GET('/practitioner_service_periods/', { params: { query } }),
    fetchPractitionerScheduleLocation: async (id: number) => await baseClient.GET('/practitioner_schedule_locations/{id}', { params: { path: { id } } }),
    fetchPractitionerScheduleLocations: async (query: operations['getPractitionerScheduleLocations']['parameters']['query']) =>
        await baseClient.GET('/practitioner_schedule_locations/', { params: { query } }),
    fetchPractitionerSchedules: async (query: operations['getPractitionerSchedules']['parameters']['query']) =>
        await baseClient.GET('/practitioner_schedules/', { params: { query } }),
    fetchPractitionerScheduleStatus: async (id: number) => await baseClient.GET('/practitioner_schedule_statuses/{id}', { params: { path: { id } } }),
    fetchPractitionerScheduleStatuses: async (query: operations['getPractitionerScheduleStatuses']['parameters']['query']) =>
        await baseClient.GET('/practitioner_schedule_statuses/', { params: { query } }),
    fetchPractitionerStatusDefinition: async (id: number) => await baseClient.GET('/practitioner_status_definitions/{id}', { params: { path: { id } } }),
    fetchPractitionerStatusDefinitions: async (query: operations['getPractitionerStatusDefinitions']['parameters']['query']) =>
        await baseClient.GET('/practitioner_status_definitions/', { params: { query } }),
    fetchRuleDefinition: async (id: number) => await baseClient.GET('/rule_definitions/{id}', { params: { path: { id } } }),
    fetchRuleDefinitions: async (query: operations['getRuleDefinitions']['parameters']['query']) =>
        await baseClient.GET('/rule_definitions/', { params: { query } }),
    fetchSections: async (query: operations['getSections']['parameters']['query']) => await baseClient.GET('/sections/', { params: { query } }),
    fetchSurgeryMetadata: async (query: operations['getSurgeryMetadata']['parameters']['query']) =>
        await baseClient.GET('/surgery_metadata/', { params: { query } }),
    fetchSpecialities: async (query: operations['getSpecialities']['parameters']['query']) => await baseClient.GET('/specialities/', { params: { query } }),
    fetchAgeGroups: async (query: operations['getAgeGroups']['parameters']['query']) => await baseClient.GET('/age_groups/', { params: { query } }),
    fetchHospitalSurgeryTypes: async (query: operations['getHospitalSurgeryTypes']['parameters']['query']) =>
        await baseClient.GET('/hospital_surgery_types/', { params: { query } }),
    fetchSurgeryTypeGroups: async (query: operations['getSurgeryTypeGroups']['parameters']['query']) =>
        await baseClient.GET('/surgery_type_groups/', { params: { query } }),
    fetchSurgeryTypeGroupAgeRestrictions: async (query: operations['getSurgeryTypeGroupAgeRestrictions']['parameters']['query']) =>
        await baseClient.GET('/surgery_type_group_age_restrictions/', { params: { query } }),
    fetchSurgeryTypeGroupHierarchies: async (query: operations['getSurgeryTypeGroupHierarchies']['parameters']['query']) =>
        await baseClient.GET('/surgery_type_group_hierarchies/', { params: { query } }),
    fetchSurgeryTypeGroupSpecialities: async (query: operations['getSurgeryTypeGroupSpecialities']['parameters']['query']) =>
        await baseClient.GET('/surgery_type_group_specialities/', { params: { query } }),
    fetchHospitalSurgeryTypeGroupAssociations: async (query: operations['getHospitalSurgeryTypeGroupAssociations']['parameters']['query']) =>
        await baseClient.GET('/hospital_surgery_type_group_associations/', { params: { query } }),
    fetchWorkloadExecution: async (id: number) => await baseClient.GET('/workload_executions/{id}', { params: { path: { id } } }),
    fetchWorkloadExecutions: async (query: operations['getWorkloadExecutions']['parameters']['query']) =>
        await baseClient.GET('/workload_executions/', { params: { query } }),
    fetchStatusDefinitionDepartmentAssignments: async (query: operations['getStatusDefinitionDepartmentAssignments']['parameters']['query']) =>
        await baseClient.GET('/status_definition_department_assignments/', { params: { query } }),

    // POST Operations
    createBlockSchedule: async (data: Schemas['BlockScheduleCreate']) => await baseClient.POST('/block_schedules/', { body: data }),
    createBatchBlockSchedule: async (data: Schemas['BlockScheduleCreate'][]) => await baseClient.POST('/block_schedules/batch', { body: data }),
    createBlockLock: async (data: Schemas['BlockLockCreate']) => await baseClient.POST('/block_locks/', { body: data }),
    createComment: async (data: Schemas['CommentCreate']) => await baseClient.POST('/comments/', { body: data }),
    createDepartment: async (data: Schemas['DepartmentCreate']) => await baseClient.POST('/departments/', { body: data }),
    createDepartmentLocationAssignment: async (data: Schemas['DepartmentLocationAssignmentCreate']) =>
        await baseClient.POST('/department_location_assignments/', { body: data }),
    createDepartmentPractitionerAssignment: async (data: Schemas['DepartmentPractitionerAssignmentCreate']) =>
        await baseClient.POST('/department_practitioner_assignments/', { body: data }),
    createLocation: async (data: Schemas['LocationCreate']) => await baseClient.POST('/locations/', { body: data }),
    createLocationSchedule: async (data: Schemas['LocationScheduleCreate']) => await baseClient.POST('/location_schedules/', { body: data }),
    createPlanningPeriod: async (data: Schemas['PlanningPeriodCreate']) => await baseClient.POST('/planning_periods/', { body: data }),
    createPractitioner: async (data: Schemas['PractitionerCreate']) => await baseClient.POST('/practitioners/', { body: data }),
    createPractitionerSchedule: async (data: Schemas['PractitionerScheduleCreate']) => await baseClient.POST('/practitioner_schedules/', { body: data }),
    createBatchPractitionerSchedule: async (data: Schemas['PractitionerScheduleCreate'][]) =>
        await baseClient.POST('/practitioner_schedules/batch', { body: data }),
    createPractitionerScheduleLocation: async (data: Schemas['PractitionerScheduleLocationCreate']) =>
        await baseClient.POST('/practitioner_schedule_locations/', { body: data }),
    createBatchPractitionerScheduleLocation: async (data: Schemas['PractitionerScheduleLocationCreate'][]) =>
        await baseClient.POST('/practitioner_schedule_locations/batch', { body: data }),
    createPractitionerScheduleStatus: async (data: Schemas['PractitionerScheduleStatusCreate']) =>
        await baseClient.POST('/practitioner_schedule_statuses/', { body: data }),
    createBatchPractitionerScheduleStatus: async (data: Schemas['PractitionerScheduleStatusCreate'][]) =>
        await baseClient.POST('/practitioner_schedule_statuses/batch', { body: data }),
    createSection: async (data: Schemas['SectionCreate']) => await baseClient.POST('/sections/', { body: data }),
    createSurgeryMetadata: async (data: Schemas['SurgeryMetadatumCreate']) => await baseClient.POST('/surgery_metadata/', { body: data }),
    createSpeciality: async (data: Schemas['SpecialityCreate']) => await baseClient.POST('/specialities/', { body: data }),
    createAgeGroup: async (data: Schemas['AgeGroupCreate']) => await baseClient.POST('/age_groups/', { body: data }),
    createHospitalSurgeryType: async (data: Schemas['HospitalSurgeryTypeCreate']) => await baseClient.POST('/hospital_surgery_types/', { body: data }),
    createSurgeryTypeGroup: async (data: Schemas['SurgeryTypeGroupCreate']) => await baseClient.POST('/surgery_type_groups/', { body: data }),
    createSurgeryTypeGroupAgeRestriction: async (data: Schemas['SurgeryTypeGroupAgeRestrictionCreate']) =>
        await baseClient.POST('/surgery_type_group_age_restrictions/', { body: data }),
    createSurgeryTypeGroupHierarchy: async (data: Schemas['SurgeryTypeGroupHierarchyCreate']) =>
        await baseClient.POST('/surgery_type_group_hierarchies/', { body: data }),
    createSurgeryTypeGroupSpeciality: async (data: Schemas['SurgeryTypeGroupSpecialityCreate']) =>
        await baseClient.POST('/surgery_type_group_specialities/', { body: data }),

    // DELETE Operations
    deleteBlockSchedule: async (id: number) => await baseClient.DELETE('/block_schedules/{id}', { params: { path: { id } } }),
    deleteBlockLock: async (id: number) => await baseClient.DELETE('/block_locks/{id}', { params: { path: { id } } }),
    deleteComment: async (id: number) => await baseClient.DELETE('/comments/{id}', { params: { path: { id } } }),
    deleteDepartment: async (id: number) => await baseClient.DELETE('/departments/{id}', { params: { path: { id } } }),
    deleteDepartmentLocationAssignment: async (id: number) => await baseClient.DELETE('/department_location_assignments/{id}', { params: { path: { id } } }),
    deleteDepartmentPractitionerAssignment: async (id: number) =>
        await baseClient.DELETE('/department_practitioner_assignments/{id}', { params: { path: { id } } }),
    deleteLocation: async (id: number) => await baseClient.DELETE('/locations/{id}', { params: { path: { id } } }),
    deleteLocationSchedule: async (id: number) => await baseClient.DELETE('/location_schedules/{id}', { params: { path: { id } } }),
    deletePlanningPeriod: async (id: number) => await baseClient.DELETE('/planning_periods/{id}', { params: { path: { id } } }),
    deletePractitioner: async (id: number) => await baseClient.DELETE('/practitioners/{id}', { params: { path: { id } } }),
    deletePractitionerSchedule: async (id: number) => await baseClient.DELETE('/practitioner_schedules/{id}', { params: { path: { id } } }),
    deletePractitionerScheduleLocation: async (id: number) => await baseClient.DELETE('/practitioner_schedule_locations/{id}', { params: { path: { id } } }),
    deleteBatchPractitionerScheduleLocation: async (data: number[]) => await baseClient.POST('/practitioner_schedule_locations/delete-batch', { body: data }),
    deletePractitionerScheduleStatus: async (id: number) => await baseClient.DELETE('/practitioner_schedule_statuses/{id}', { params: { path: { id } } }),
    deleteBatchPractitionerScheduleStatus: async (data: number[]) => await baseClient.POST('/practitioner_schedule_statuses/delete-batch', { body: data }),
    deleteRuleDefinition: async (id: number) => await baseClient.GET('/rule_definitions/{id}', { params: { path: { id } } }),
    deleteSection: async (id: number) => await baseClient.DELETE('/sections/{id}', { params: { path: { id } } }),
    deleteSurgeryMetadata: async (id: number) => await baseClient.DELETE('/surgery_metadata/{id}', { params: { path: { id } } }),
    deleteSpeciality: async (id: number) => await baseClient.DELETE('/specialities/{id}', { params: { path: { id } } }),
    deleteAgeGroup: async (id: number) => await baseClient.DELETE('/age_groups/{id}', { params: { path: { id } } }),
    deleteHospitalSurgeryType: async (id: number) => await baseClient.DELETE('/hospital_surgery_types/{id}', { params: { path: { id } } }),
    deleteSurgeryTypeGroup: async (id: number) => await baseClient.DELETE('/surgery_type_groups/{id}', { params: { path: { id } } }),
    deleteSurgeryTypeGroupAgeRestriction: async (id: number) =>
        await baseClient.DELETE('/surgery_type_group_age_restrictions/{id}', { params: { path: { id } } }),
    deleteSurgeryTypeGroupHierarchy: async (id: number) => await baseClient.DELETE('/surgery_type_group_hierarchies/{id}', { params: { path: { id } } }),
    deleteSurgeryTypeGroupSpeciality: async (id: number) => await baseClient.DELETE('/surgery_type_group_specialities/{id}', { params: { path: { id } } }),
    deleteHospitalSurgeryTypeGroupAssociation: async (id: number) =>
        await baseClient.DELETE('/hospital_surgery_type_group_associations/{id}', { params: { path: { id } } }),

    // PUT Operations
    updateBlockSchedule: async (id: number, data: Schemas['BlockScheduleCreate']) =>
        await baseClient.PUT('/block_schedules/{id}', { params: { path: { id } }, body: data }),
    updateBatchBlockSchedule: async (data: Schemas['BaseUpdateEntitySchema_BlockScheduleCreate_'][]) =>
        await baseClient.POST('/block_schedules/update-batch', { body: data }),
    updateBlockLock: async (id: number, data: Schemas['BlockLockCreate']) =>
        await baseClient.PUT('/block_locks/{id}', { params: { path: { id } }, body: data }),
    updateComment: async (id: number, data: Schemas['CommentCreate']) => await baseClient.PUT('/comments/{id}', { params: { path: { id } }, body: data }),
    updateDepartment: async (id: number, data: Schemas['DepartmentCreate']) =>
        await baseClient.PUT('/departments/{id}', { params: { path: { id } }, body: data }),
    updateDepartmentLocationAssignment: async (id: number, data: Schemas['DepartmentLocationAssignmentCreate']) =>
        await baseClient.PUT('/department_location_assignments/{id}', { params: { path: { id } }, body: data }),
    updateDepartmentPractitionerAssignment: async (id: number, data: Schemas['DepartmentPractitionerAssignmentCreate']) =>
        await baseClient.PUT('/department_practitioner_assignments/{id}', { params: { path: { id } }, body: data }),
    updateLocation: async (id: number, data: Schemas['LocationCreate']) => await baseClient.PUT('/locations/{id}', { params: { path: { id } }, body: data }),
    updateLocationSchedule: async (id: number, data: Schemas['LocationScheduleCreate']) =>
        await baseClient.PUT('/location_schedules/{id}', { params: { path: { id } }, body: data }),
    updatePlanningPeriod: async (id: number, data: Schemas['PlanningPeriodCreate']) =>
        await baseClient.PUT('/planning_periods/{id}', { params: { path: { id } }, body: data }),
    updatePractitioner: async (id: number, data: Schemas['PractitionerCreate']) =>
        await baseClient.PUT('/practitioners/{id}', { params: { path: { id } }, body: data }),
    updatePractitionerSchedule: async (id: number, data: Schemas['PractitionerScheduleCreate']) =>
        await baseClient.PUT('/practitioner_schedules/{id}', { params: { path: { id } }, body: data }),
    updatePractitionerScheduleLocation: async (id: number, data: Schemas['PractitionerScheduleLocationCreate']) =>
        await baseClient.PUT('/practitioner_schedule_locations/{id}', { params: { path: { id } }, body: data }),
    updatePractitionerScheduleStatus: async (id: number, data: Schemas['PractitionerScheduleStatusCreate']) =>
        await baseClient.PUT('/practitioner_schedule_statuses/{id}', { params: { path: { id } }, body: data }),
    updateSection: async (id: number, data: Schemas['SectionCreate']) => await baseClient.PUT('/sections/{id}', { params: { path: { id } }, body: data }),
    updateSpeciality: async (id: number, data: Schemas['SpecialityCreate']) =>
        await baseClient.PUT('/specialities/{id}', { params: { path: { id } }, body: data }),
    updateAgeGroup: async (id: number, data: Schemas['AgeGroupCreate']) => await baseClient.PUT('/age_groups/{id}', { params: { path: { id } }, body: data }),
    updateHospitalSurgeryType: async (id: number, data: Schemas['HospitalSurgeryTypeCreate']) =>
        await baseClient.PUT('/hospital_surgery_types/{id}', { params: { path: { id } }, body: data }),
    updateSurgeryTypeGroup: async (id: number, data: Schemas['SurgeryTypeGroupCreate']) =>
        await baseClient.PUT('/surgery_type_groups/{id}', { params: { path: { id } }, body: data }),
    updateSurgeryTypeGroupAgeRestriction: async (id: number, data: Schemas['SurgeryTypeGroupAgeRestrictionCreate']) =>
        await baseClient.PUT('/surgery_type_group_age_restrictions/{id}', { params: { path: { id } }, body: data }),
    updateSurgeryTypeGroupHierarchy: async (id: number, data: Schemas['SurgeryTypeGroupHierarchyCreate']) =>
        await baseClient.PUT('/surgery_type_group_hierarchies/{id}', { params: { path: { id } }, body: data }),
    updateSurgeryTypeGroupSpeciality: async (id: number, data: Schemas['SurgeryTypeGroupSpecialityCreate']) =>
        await baseClient.PUT('/surgery_type_group_specialities/{id}', { params: { path: { id } }, body: data }),

    // PATCH operations
    partialUpdateSurgeryMetadata: async (id: number, data: Schemas['SurgeryMetadatumCreate']) =>
        await baseClient.PATCH('/surgery_metadata/{id}', { params: { path: { id } }, body: data }),
} as const

export default client
