import { Tag } from '~/components'
import { DurationRuleEvaluation, isRoomDurationBasedRuleEvaluation } from '~/store/selectors'
import { formatDuration } from '~/utils/extendedDayjs'

type Props = {
    evaluation: DurationRuleEvaluation
    asTag: boolean
}

export const AvailabilityByTime = ({ evaluation, asTag }: Props) => {
    const what = isRoomDurationBasedRuleEvaluation(evaluation) ? 'stuetid' : 'knivtid'
    const duration = evaluation.remaining ? formatDuration(evaluation.remaining) : '?'
    const text = `Ledig ${what}: ${duration}`

    if (asTag) {
        return <Tag color="no-bg">{text}</Tag>
    }

    return <span className="font-medium text-diBlue-700">{text}</span>
}
