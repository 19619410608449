import clsx from 'clsx'

import { AlertSquareRoundedIcon } from '~/icons'

type Props = {
    label: string
    error?: string
    defaultValue?: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    id: string
    disabled?: boolean
}

export const FormInput = ({ error, defaultValue, onChange, id, disabled }: Props) => (
    <div className="relative flex flex-col gap-2">
        <input
            type="time"
            id={id}
            name={id}
            defaultValue={defaultValue}
            data-test={id}
            className={clsx('w-full rounded-lg border p-1 font-normal outline-none', {
                'border-red-500': error,
                'bg-gray-200': disabled,
            })}
            onChange={onChange}
            disabled={disabled}
        />

        {error && (
            <div className="flex gap-0.5 text-xs text-red-500">
                <AlertSquareRoundedIcon height={20} />
                {error}
            </div>
        )}
    </div>
)
