import dayjs from 'dayjs'

import { CollapsibleCard } from '~/components'
import { HeroDrawer } from '~/hero-ui'
import { selectActiveFilteredLocations, selectGetBlockSchedules, selectGetLocations, selectSurgeryTypeGroup, selectWeekIdDates } from '~/store/selectors'
import { useStore } from '~/store/store'
import { format } from '~/utils/extendedDayjs'

import { resolveWeeklyCounts } from './resolveWeeklyCounts'
import { TagList } from './TagList'

function formatSpecialityCountValue(specialityCount: { minCount: number; maxCount: number; speciality: string; someUnresolved: boolean }) {
    const num = specialityCount.maxCount > specialityCount.minCount ? `${specialityCount.minCount}-${specialityCount.maxCount}` : `${specialityCount.maxCount}`
    return specialityCount.someUnresolved ? `${num}*` : num
}

export const SurgeryScheduleDrawer = () => {
    const selectedLocationDay = useStore(state => state.surgeryScheduler.selectedLocationDay)

    const isSelected = selectedLocationDay?.locationId !== undefined

    const weekIdDates = useStore(selectWeekIdDates)
    const getBlockSchedules = useStore(selectGetBlockSchedules)
    const getSurgeryTypeGroup = useStore(selectSurgeryTypeGroup)
    const activeFilteredLocationIds = useStore(selectActiveFilteredLocations).map(location => location.id)

    const weeklyCountData = resolveWeeklyCounts(weekIdDates, getBlockSchedules.byDate, activeFilteredLocationIds, getSurgeryTypeGroup)
    const specialityCounts = Object.values(weeklyCountData.specialityCounts)
    const date = dayjs.unix(selectedLocationDay?.unixDate ?? 0).startOf('day')

    const formattedDate = isSelected ? format(date, 'DD.MM.YYYY') : ''
    const getLocation = useStore(selectGetLocations)
    const location = getLocation.byId(selectedLocationDay?.locationId ?? 0)

    return (
        <HeroDrawer>
            {!isSelected ? (
                <>
                    <p className="mb-6 font-bold">Antall pasienter</p>
                    <div className="flex w-72 flex-col gap-y-3">
                        {specialityCounts.map((specialityCount, index) => {
                            const specialityConstraint = weeklyCountData?.specialityConstraints[specialityCount.speciality]

                            return (
                                <CollapsibleCard key={index} title={specialityCount.speciality} secondaryIcon={formatSpecialityCountValue(specialityCount)}>
                                    <div className="flex flex-col gap-1 pt-4">
                                        <TagList groupLevel={'speciality'} specialityConstraints={specialityConstraint} />
                                        <TagList groupLevel={'subspeciality'} specialityConstraints={specialityConstraint} />
                                        <TagList groupLevel={'composite'} specialityConstraints={specialityConstraint} />
                                        <TagList groupLevel={'category'} specialityConstraints={specialityConstraint} />
                                    </div>
                                </CollapsibleCard>
                            )
                        })}
                    </div>
                </>
            ) : (
                <p className="text-base font-semibold">
                    Stue {location?.room_code} - {formattedDate}
                </p>
            )}
        </HeroDrawer>
    )
}
