import { useQuery } from '@tanstack/react-query'

import optimusClient, { OptimusSchemas } from '~/clients/optimus-client'
import { DepartmentKey } from '~/store/slices/filterSlice'

import { queryKeys } from '../../useWebSocketUpdates/handleWebSocketUpdates'
import { handleApiRequest } from './shared/apiWrapper'

type EvaluationRequest = { departmentKey: DepartmentKey; year: number; month: number }

export type RequestResult = Partial<OptimusSchemas['ActivityRequestResult'] & OptimusSchemas['ResourceRequestResult']>
export type EvaluationOutput = RequestResult[]

const serializeKeysForCache = (request: EvaluationRequest) => [queryKeys.staffingPlanEvaluation, ...Object.values(request)]
export const useEvaluationQuery = (request: EvaluationRequest) =>
    useQuery({
        queryKey: serializeKeysForCache(request),
        queryFn: async () => {
            const { departmentKey, year, month } = request

            if (departmentKey === 'all') {
                throw new Error()
            }

            return handleApiRequest(() => optimusClient.staffingPlan.getEvaluation(departmentKey, year, month))
        },
        retry: 1,
        select: evaluations => {
            const allEvaluations: RequestResult[] = [...(evaluations?.activity_request_results ?? []), ...(evaluations?.resource_request_results ?? [])]

            const getPriority = ({ activity_request, resource_request }: RequestResult) => activity_request?.priority ?? resource_request?.priority

            return allEvaluations.toSorted((a, b) => Number(getPriority(a) === 'required') - Number(getPriority(b) === 'required'))
        },
    })
