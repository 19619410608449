import { useState } from 'react'

import { createBatchPractitionerScheduleLocation, deleteBatchPractitionerScheduleLocation } from '~/store/di-entity.api'
import { Location, PractitionerScheduleLocation, selectGetLocations, selectGetPractitionerSchedules } from '~/store/selectors'
import { useStore } from '~/store/store'
import { trackUserEvent } from '~/utils/analytics'

import { DatePractitionerCell } from '../../shared'
import { useCreateMissingSchedules } from './useCreateMissingSchedules'

export function createGetLocationStateFn(selectedCells: DatePractitionerCell[], scheduleLocations: PractitionerScheduleLocation[]) {
    return (locationId: Location['id']) => {
        const numCellsWithLocation = scheduleLocations.filter(location => location.location_id === locationId).length
        return numCellsWithLocation === 0 ? 'none' : numCellsWithLocation === selectedCells.length ? 'all' : 'some'
    }
}

export const useToggleLocationSchedules = (selectedCells: DatePractitionerCell[], scheduleLocations: PractitionerScheduleLocation[]) => {
    const [isLoading, setIsLoading] = useState(false)
    const getLocationState = createGetLocationStateFn(selectedCells, scheduleLocations)
    const createMissingSchedules = useCreateMissingSchedules(selectedCells)
    const getLocations = useStore(selectGetLocations)

    async function saveLocationSchedules(locationId: Location['id']) {
        await createMissingSchedules()

        const getSchedules = selectGetPractitionerSchedules(useStore.getState())
        const cellsWithoutLocation = selectedCells.filter(({ date, practitionerId }) => {
            const schedule = getSchedules.byDateAndPractitionerId(date, practitionerId)
            if (!schedule) return
            return schedule.locations.every(location => location.id !== locationId)
        })

        const schedulesToCreate = cellsWithoutLocation
            .map(({ date, practitionerId }) => {
                const schedule = getSchedules.byDateAndPractitionerId(date, practitionerId)
                if (!schedule) return
                return { practitioner_schedule_id: schedule.id, location_id: locationId }
            })
            .filter(Boolean)

        await createBatchPractitionerScheduleLocation(schedulesToCreate)
        return selectedCells.length
    }

    async function deleteLocationSchedules(locationId: Location['id']) {
        const locationSchedulesToDelete = scheduleLocations.filter(scheduleLocation => scheduleLocation.location_id === locationId).map(location => location.id)

        try {
            await deleteBatchPractitionerScheduleLocation(locationSchedulesToDelete)
        } catch (error) {
            console.error(error)
            return -1
        }
        return locationSchedulesToDelete.length
    }

    async function toggleLocationSchedules(locationId: Location['id']) {
        const state = getLocationState(locationId)
        const location = getLocations.byId(locationId)
        const rooms = [location?.room_code].filter(Boolean)

        setIsLoading(true)
        if (state === 'none' || state === 'some') {
            const numberOfEdits = await saveLocationSchedules(locationId)
            void trackUserEvent('SS_SURGEON_LOCATION_ADDED', { numberOfEdits, rooms })
        }

        if (state === 'all') {
            const numberOfEdits = await deleteLocationSchedules(locationId)
            void trackUserEvent('SS_SURGEON_LOCATION_REMOVED', { numberOfEdits, rooms })
        }
        setIsLoading(false)
    }

    return { isLoading, toggleLocationSchedules }
}
