import { forwardRef, ReactNode } from 'react'

import { BodyVariant, SizeOptions } from '../../types/types'
import { HeroParagraph } from '../Typography/HeroParagraph/HeroParagraph'

type Color = 'green' | 'blue' | 'yellow' | 'orange' | 'default'
type Props = {
    /**
     * Size of the badge
     */
    size?: SizeOptions
    /**
     * Text to display in the badge
     */
    text?: string | number
    /**
     * Icon to display in the badge
     */
    icon?: ReactNode
    /**
     * Variant of the badge, either filled or outlined
     */
    variant?: 'filled' | 'outlined'
    /**
     * Color of the badge, either green, blue, yellow, orange, or white. More can be added
     */
    color?: Color
    /**
     * Optional Data test id, used for testing
     */
    'data-test'?: string
}

const badgeBaseStyles = 'flex select-none items-center gap-1.5 '

const filledColorStyles: Record<Color, string> = {
    default: 'bg-fill-tertiary-rest text-rest',
    green: 'bg-fill-success-subtle text-success-rest',
    blue: 'bg-fill-emphasis-subtle text-info-rest',
    yellow: 'bg-fill-caution-subtle text-caution-rest',
    orange: 'bg-fill-warning-subtle text-warning-rest',
}

const outlinedColorStyles: Record<Color, string> = {
    default: 'border border-strong-rest text-rest bg-fill-rest',
    green: 'border border-success text-success-rest bg-fill-rest',
    blue: 'border border-info text-info-rest bg-fill-rest',
    yellow: 'border border-caution text-caution-rest bg-fill-rest',
    orange: 'border border-warning text-warning-rest bg-fill-rest',
}

const sizeStyles: Record<SizeOptions, string> = {
    small: 'h-5 min-w-5',
    medium: 'h-6 min-w-6',
    large: 'h-7 min-w-7',
}

const bodyVariant: Record<SizeOptions, BodyVariant> = {
    small: 'body-small',
    medium: 'body-small',
    large: 'body',
}

const iconSizeStyles: Record<SizeOptions, string> = {
    small: '[&>svg]:size-3',
    medium: '[&>svg]:size-3',
    large: '[&>svg]:size-3.5',
}

/**
 *
 * Badges are visual indicator and non-interactive component to either call attention to a related object, or for communicating non-actionable information.
 */
export const HeroBadge = forwardRef<HTMLDivElement, Props>(
    ({ size = 'medium', text, icon, variant = 'filled', color = 'default', 'data-test': dataTest, ...props }, ref) => (
        <div
            ref={ref}
            data-test={dataTest}
            {...props}
            className={`${badgeBaseStyles} ${text !== undefined ? 'w-fit rounded-lg px-2' : 'justify-center rounded-full'} ${sizeStyles[size]} ${variant === 'filled' ? filledColorStyles[color] : outlinedColorStyles[color]} ${iconSizeStyles[size]}`}
        >
            {icon && <>{icon}</>}
            {text && (
                <HeroParagraph variant={bodyVariant[size]} className="text-nowrap">
                    <span>{text}</span>
                </HeroParagraph>
            )}
        </div>
    )
)

HeroBadge.displayName = 'HeroBadge' // Necessary for better debugging when using forwardRef
