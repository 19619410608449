import clsx from 'clsx'
import { Dayjs } from 'dayjs'

import { Show } from '~/components'
import { Location, selectGetBlockLocks, selectGetOccupancies, selectGetScheduledPractitioners, selectPlanningPeriods } from '~/store/selectors'
import { useStore } from '~/store/store'
import { getPractitionerFullname } from '~/utils/dips/practitioner'
import { isHoliday } from '~/utils/holidays'

import { BlockIcons } from '../../shared/BlockIcons'
import { hasAnyAvailability, hasAnyBooking } from '../../shared/utils'
import { BlockCard, styles } from './BlockCard'

type Props = {
    date: Dayjs
    location: Location
}

export const CellBlock = ({ date, location }: Props) => {
    const drawerProps = useStore(state => state.operationalPlanner.drawerProps)
    const { openDrawer, closeDrawer } = useStore(state => state.operationalPlanner.actions)
    const getPlanningPeriods = useStore(selectPlanningPeriods)

    // treat nonexisiting period as published
    const { is_published: periodIsPublished } = getPlanningPeriods.byDate(date) ?? { is_published: true }

    const getBlockLocks = useStore(selectGetBlockLocks)
    const getOccupancies = useStore(selectGetOccupancies)
    const getScheduledPractitioners = useStore(selectGetScheduledPractitioners)
    const blockLock = getBlockLocks.byDateAndLocationId(date, location.id)
    const scheduledPractitioners = getScheduledPractitioners.byDateAndLocation(date, location)
    const occupancyData = getOccupancies.byDateAndLocation(date, location)

    if (!occupancyData || !date || !location || isHoliday(date)) {
        return null
    }

    const isSelected = drawerProps?.locationId === location.id && drawerProps?.unixDate === date.unix()

    const isBlockLocked = Boolean(blockLock)

    const hideAvailability = !periodIsPublished

    const hasBookingList = hasAnyBooking(occupancyData)
    const hasAvailabilities = hasAnyAvailability(occupancyData) && !hideAvailability
    const hasOnlyOneList = (hasBookingList && !hasAvailabilities) || (!hasBookingList && hasAvailabilities)
    const lockedBlockHasOnlyAvailable = isBlockLocked && !hasBookingList && hasAvailabilities

    const showAvailableBlockCard = !isBlockLocked && hasAvailabilities

    // show icons in cell if there is no booking list and availbility is hidden
    const showIconsInCell = (hasBookingList && hasAvailabilities && !isBlockLocked) || (hideAvailability && !hasBookingList)
    // show icons in card if there are bookings and availability is not hidden
    const showIconsInCard =
        (hasOnlyOneList && !hideAvailability) || (hasBookingList && hasAvailabilities && isBlockLocked) || (!!hideAvailability && hasBookingList)

    const commonProps = { date, location, occupancyData, showIcons: showIconsInCard, showKnifeTime: false }
    return (
        <div
            className={clsx('layer-stack flex h-full cursor-pointer flex-col gap-2')}
            onClick={() => {
                if (isSelected) {
                    closeDrawer()
                } else {
                    openDrawer({ date, location, occupancyData, hasAvailability: showAvailableBlockCard })
                }
            }}
        >
            <Show
                condition={!lockedBlockHasOnlyAvailable}
                fallback={
                    <div className={clsx('flex h-full flex-col justify-between rounded p-2', styles['booked'])}>
                        <span className="mr-7 font-semibold text-blue-700">Ingenting booket</span>
                        <BlockIcons date={date} location={location} />
                    </div>
                }
            >
                <Show condition={hasBookingList}>
                    <BlockCard
                        {...commonProps}
                        className="cursor-pointer"
                        style="booked"
                        practitioners={occupancyData.bookedPractitioners}
                        data-test="block-booked"
                    />
                </Show>
                <Show condition={showAvailableBlockCard}>
                    <BlockCard
                        {...commonProps}
                        className="cursor-pointer"
                        style="available"
                        practitioners={scheduledPractitioners.map(p => ({
                            name: getPractitionerFullname(p),
                            short_name: p.short_name,
                        }))}
                        data-test="block-available"
                    />
                </Show>
                <Show condition={showIconsInCell}>
                    <BlockIcons date={date} location={location} />
                </Show>
            </Show>
        </div>
    )
}
