import { createSelector } from 'reselect'

import { selectGetScheduledSurgeries, selectGetUnScheduledSurgeries } from '~/store/selectors'
import { StoreState } from '~/store/store'

export const selectSelectedSurgery = createSelector(
    (state: StoreState) => state.operationalPlanner.selectedSurgery,
    selectGetUnScheduledSurgeries,
    selectGetScheduledSurgeries,
    (selectedSurgery, getUnScheduledSurgeries, getScheduledSurgeries) => {
        if (!selectedSurgery) return null

        const { id, type } = selectedSurgery

        return type === 'unscheduled' ? getUnScheduledSurgeries.byBookingId(id) : getScheduledSurgeries.byBookingId(id)
    }
)
