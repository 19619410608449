import { ReactNode } from 'react'

import { OptimusSchemas } from '~/clients/optimus-client'
import { HeroBadge, HeroTooltip } from '~/hero-ui'
import { BranchIcon } from '~/icons'
import { selectGetPractitionerStatusDefinitions } from '~/store/selectors'
import { useStore } from '~/store/store'
import { day, format } from '~/utils/extendedDayjs'
import { isNullish } from '~/utils/guards'

import { PriorityBadge } from './PriorityBadge'

type RequestInput = Partial<OptimusSchemas['ResourceRequestInput'] & OptimusSchemas['ActivityRequestInput']>
const weekdays = ['Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag']

const RequestGrid = ({ title, children, hidden }: { title: string; children: ReactNode; hidden: boolean }) => (
    <div className={`grid grid-cols-[58px_1fr] ${hidden ? 'hidden' : ''} last:pb-2`}>
        <div className="w-fit rounded-lg px-2.5 py-1 text-xs">{title}</div>
        <div className="flex flex-wrap items-center gap-x-1.5 gap-y-1 px-2">{children}</div>
    </div>
)

export const RequestParserTooltip = ({ wish }: { wish: RequestInput | null }) => {
    const getPractitionerStatusDefinitions = useStore(selectGetPractitionerStatusDefinitions)

    return (
        <HeroTooltip
            showArrow={false}
            className="bg-fill-rest"
            alignOffset={-10}
            sideOffset={20}
            delayDuration={50}
            align="start"
            side="left"
            tooltipContent={
                <div className="flex w-72 flex-col gap-y-2 text-sm text-black">
                    <div className="-mx-2 flex items-center gap-x-2 border-b px-4 pb-3 pt-2">
                        <BranchIcon height={24} width={24} className="min-w-max text-gray-400" />
                        <p>Betingelser</p>
                    </div>
                    <RequestGrid title="Kirurg" hidden={isNullish(wish?.surgeon)}>
                        <HeroBadge color="blue" text={wish?.surgeon} />
                    </RequestGrid>
                    <RequestGrid title="Kirurger" hidden={Number(wish?.surgeon_resources?.length ?? 0) === 0}>
                        {wish?.surgeon_resources?.at(0)?.map(surgeon => <HeroBadge key={surgeon} color="blue" text={surgeon} />)}
                        <span className="text-nowrap">
                            {Number(wish?.surgeon_resources?.at(1)?.length ?? 0) === 1 && 'med'}
                            {Number(wish?.surgeon_resources?.at(1)?.length ?? 0) > 1 && 'med en av'}
                        </span>
                        <div className="flex flex-wrap gap-x-1.5 gap-y-1">
                            {wish?.surgeon_resources?.at(1)?.map(surgeon => <HeroBadge key={surgeon} variant="outlined" text={surgeon} />)}
                        </div>
                    </RequestGrid>
                    <RequestGrid title="Stuer" hidden={Number(wish?.rooms?.length ?? 0) === 0}>
                        {wish?.rooms?.map(room => <HeroBadge key={room} variant="outlined" text={room} />)}
                    </RequestGrid>
                    <RequestGrid title="Aktivitet" hidden={isNullish(wish?.activity)}>
                        <HeroBadge variant="outlined" text={getPractitionerStatusDefinitions.byLabel(wish?.activity ?? '')?.title ?? ''} />
                    </RequestGrid>
                    <RequestGrid title="Prioritet" hidden={isNullish(wish?.priority)}>
                        <PriorityBadge priority={wish?.priority ?? ''} />
                    </RequestGrid>
                    <RequestGrid title="Dato" hidden={Number(wish?.days?.length ?? 0) === 0}>
                        {wish?.days?.map(date => <HeroBadge key={date} variant="outlined" text={format(day(date), 'D/M')} />)}
                    </RequestGrid>
                    <RequestGrid title="Ukedag" hidden={Number(wish?.weekdays?.length ?? 0) === 0}>
                        {wish?.weekdays?.map(weekday => <HeroBadge key={weekday} variant="outlined" text={weekdays[weekday] ?? ''} />)}
                    </RequestGrid>
                    <RequestGrid title="Antall" hidden={isNullish(wish?.reward_activation)}>
                        {wish?.reward_activation?.min && (
                            <div className="flex items-center gap-x-1">
                                <span className="text-xs">min:</span>
                                <HeroBadge variant="outlined" text={String(wish.reward_activation.min)} />
                            </div>
                        )}
                        {wish?.reward_activation?.max && (
                            <div className="ml-1 flex items-center gap-x-1">
                                <span className="text-xs">max:</span>
                                <HeroBadge variant="outlined" text={String(wish.reward_activation.max)} />
                            </div>
                        )}
                    </RequestGrid>
                </div>
            }
        >
            <div className="pointer-events-auto flex w-full cursor-pointer items-center gap-2">
                <BranchIcon className="text-gray-400" />
                <div className="flex w-full items-center overflow-hidden rounded-lg px-2 py-1 transition-colors hover:bg-fill-hover">
                    <p className="truncate text-xs font-normal">{Object.values(wish ?? {}).join(', ')}</p>
                </div>
            </div>
        </HeroTooltip>
    )
}
