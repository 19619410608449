import { RefObject, useRef } from 'react'

import { Show } from '~/components'
import { usePagination } from '~/hooks/usePagination/usePagination'
import { InfoCircleIcon } from '~/icons'
import { useStore } from '~/store/store'
import { highlightNeedlesDebounced, needleToTokens } from '~/utils/highlightNeedlesDebounced'

import { useColumnsToShow } from '../hooks/useColumnsToShow'
import { useKeyboardNavigation } from '../hooks/useKeyboardNavigation'
import { useWaitingListResultsMessage } from '../hooks/useWaitingListResultsMessage'
import { selectSortedWaitingListItems } from '../selectors/waitingListItems'
import { LoadingRows } from './Body/LoadingRows'
import { NoResultsTableRow } from './Body/NoResultsTableRow'
import { TableRow } from './Body/TableRow'
import { TableHeaderRow } from './Header/TableHeaderRow'
import { Pagination } from './Pagination/Pagination'

type Props = {
    isLoading: boolean
    containerRef: RefObject<HTMLDivElement>
}

export function Table({ isLoading, containerRef }: Props) {
    const selectedPage = useStore(state => state.waitingList.selectedPage)
    const { setSelectedPage } = useStore(state => state.waitingList.actions)
    const itemsPerPage = useStore(state => state.waitingList.itemsPerPage)
    const pageRawNeedle = useStore(state => state.waitingList.pageRawNeedle)
    const needle = pageRawNeedle.toLowerCase()
    const tableRef = useRef<HTMLTableSectionElement>(null)
    const headerRef = useRef<HTMLTableSectionElement>(null)
    const paginationRef = useRef<HTMLDivElement>(null)

    highlightNeedlesDebounced(needleToTokens(needle), tableRef)

    const tableItems = useStore(selectSortedWaitingListItems) // filtered, transformed and sorted items

    const { pageItems, ...paginationProps } = usePagination(tableItems, selectedPage, itemsPerPage)
    useKeyboardNavigation(pageItems, containerRef, paginationRef, headerRef)

    const columnCount = Object.keys(useColumnsToShow()).length

    const isNoResultsFound = !isLoading && pageItems.length === 0
    const { tableMessage, tooltipMessage } = useWaitingListResultsMessage(isNoResultsFound, needle)

    return (
        <div className="flex h-full w-full flex-col gap-2">
            <div className="grow overflow-scroll border">
                <table className="min-w-full" data-test={isLoading ? '' : 'waiting-list-loaded'}>
                    <thead className="border-b" ref={headerRef}>
                        <TableHeaderRow />
                    </thead>
                    <tbody ref={tableRef} className={`w-full text-left text-sm font-normal text-gray-700`} data-test="waiting-list-table-body">
                        <Show condition={!isLoading} fallback={<LoadingRows columnCount={columnCount} rowsToShow={itemsPerPage} />}>
                            <Show condition={pageItems.length !== 0} fallback={<NoResultsTableRow columnCount={columnCount} message={tableMessage} />}>
                                {pageItems.map((item, index) => (
                                    <TableRow key={item.BookingId} item={item} className="border-b last:border-0" data-test={`waiting-list-item-${index}`} />
                                ))}
                            </Show>
                        </Show>
                    </tbody>
                </table>
            </div>
            <div ref={paginationRef} className="flex w-full shrink items-center justify-center gap-1 bg-white">
                <Pagination setSelectedPage={setSelectedPage} {...paginationProps} />
                {tooltipMessage && <InfoCircleIcon data-tooltip={tooltipMessage} />}
            </div>
        </div>
    )
}
